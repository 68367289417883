.questionnaire {
  &-container {
    //todo?
  }
  &__title {

  }
  &__body {

  }
  &__footer {
    &--left-align {
      text-align: left;
    }
    &--right-align {
      text-align: right;
    }
    &--center-align {
      text-align: center;
    }
  }
  &__save-button {
    background-color: $flinders-gold;
    padding: 0.8rem 1rem;
    border: none;
    position: relative;
    width: 220px;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @extend .base_animation_buttons;
    &:after {
      @extend .base_animation_buttons__after;
      background: $dusty-yellow;
      border: none;
    }
    &:hover {
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
  }
  &__cancel-link {
    margin-bottom: 1rem;
  }
  &__privacy-link {
    margin-bottom: 1rem;
    font-size: 0.75rem;
  }
}
