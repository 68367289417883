$min_width: 320px;

$font_family: 'Open Sans', sans-serif;
$font_family-titles: 'Roboto', sans-serif;
$font_family-Roboto-Condensed: 'Roboto Condensed', sans-serif;
$font_family-Oswald: 'Oswald', sans-serif;
$font_family-Anton: 'Anton', sans-serif;
$font_family-circular-thin: 'CircularXXWeb-Light', sans-serif;
$font_family-circular-regular: 'CircularXXWeb-regular', sans-serif;
$font_family-circular-book: 'CircularXXWeb-book', sans-serif;
$font_family-circular-medium: 'CircularXXWeb-Medium', sans-serif;
$font_family-circular-black: 'CircularXXWeb-Black', sans-serif;
$font_family-circe: 'circe', sans-serif;
$font_family-reckless-medium: 'RecklessNeue-Medium', serif;
$font_family-reckless-medium-italic: 'RecklessNeue-MediumItalic', serif;
$font_family-reckless-regular: 'RecklessNeue-Regular', serif;
$font_family-reckless-regular-italic: 'RecklessNeue-RegularItalic', serif;

$primary_color: #333;

//primary brand colours
$light_blue: #002D62; //aka "Flinders blue"
$blue: #011c2c; //aka "Flinders navy"
$yellow: #ffd300; //aka "Flinders gold"

//secondary brand colours
$ochre: #de9f46;
$eucalypt_green: #146858;
$midnight_blue: #002f60;
$summer_red: #ee5524;
$summer_red_hover: #d13605;
$sunset_pink: #f3b8cb;
$stone_grey: #b7b7b7;
$dusty_white: #f6eee1;
$ocean_blue: #21509f;

$dusty-yellow: #e6be00;
$dark-yellow: #ffba00;
$charcoal_black: #434343;
$charcoal_black-display1: #484848;
$charcoal_black-display2: #2a2a2a;
$breadcrumb_light_grey: #dddddd;
$gray-search: #777777;
$sajari-search-grey: #666666;
$search-icon-grey: #737373;
$gray-light-flinders: #eeeeee;
$gray-light-flinders-display1: #f5f5f5;
$gray-light-flinders-display2: #c7c7c7;
$gray-light-flinders-display3: #d6d7d9;
$gray-light-flinders-display4: #d8d8d8;
$gray-light-flinders-display5: #f4f4f4;
$gray-light-flinders-display6: #f7f7f7;
$gray-dom-tooltip: #4F4F4F;
$domestic_grey: #f2f2f2;
$international_grey: #d8d8d8; //AKA: $gray-light-flinders-display4
$gray-accordion-hover-over-header:#A6ACAF;
$gray-accordion-border:#b7b7b7;
$gray-accordion-link-for-dark-bg:#2C3E50;
$gray-flinders: #cccccc;
$gray-dark-flinders: #333333;
$table-hover-light-blue: #abd0e4;
$white: #ffffff;
$black: #000000;
$black-display1: #262626;
$header_first_line_height: 85px;
$link_default: #013554;
$black_conteiner__link_default: #337ab7;
$link_hover: #23527c;
$display-weight-normal: 400;
$global-line-height: 1.2;
$semibold: 600;
$header_height:70px;
$header_site_height:25px;
$sub_header_top: 60px;
$yellow_heading_section_mv_height: 45px;
$bottom_yellow_line_height: 80px;
$red-color-flinders: #fe4c42;
$blue-color-flinders: #63abdd;
$dom_int_toggle_bg_dom: #55595c;
$dom_int_toggle_bg_int: #818a91;
$midyear_icon: #ff7800;
$gray_hover: #E8E8E8;

$flinders-navy: $blue;
$flinders-gold: $yellow;
$rte-font-size-large: 1.525rem !default;
$rte-font-size-feature-heading: 2.275rem !default;
$rte-font-size-feature-heading-giant: 3.275rem !default;

$font-size-fln-h1: 2.25rem !default;
$font-size-fln-h2: 1.75rem !default;
$font-size-fln-h3: 1.375rem !default;
$font-size-fln-h4: 1rem !default;
$font-size-fln-h5: 0.875rem !default;
$font-size-fln-h6: 0.75rem !default;

$fadein-transition-time: 0.5s;

$viewport-50: 50vh;
$viewport-75: 75vh;
$viewport-100: 100vh;
$header-height-lg: 64px;
$max-height-viewports: 900px;

$default_column_padding: 15px;

$z-index-gdpr-notification: 2500;
$z-index-mobile-fixed-header-nav: 1075;
$z-index-fan-protected-tag: 9;
$z-index-quicksearch-popup: 10;
$z-index-new-header-site: 100;
$z-index-mobile-menu: 1;
$z-index-iframe-full-screen: 2499;


$transparency_colour_options: (
	'navy' : $flinders-navy,
	'black' : $black,
	'gold' : $flinders-gold,
	'white' : $white,
	);
$transparency_amount_options: (
	'20' : 0.2,
	'40' : 0.4,
	'60' : 0.6,
	'80' : 0.8,
	);
//Used for the margin-options-generator mixin
$margin_size_options: (
        "none" : 0,
        "10" : 10px,
        "15" : 15px,
        "25" : 25px,
        "50" : 50px,
        "70" : 70px,
        "100" : 100px
    );
//Used for the section styles generator mixin
$section_styles_map: (
	'domestic_grey' : (
		container-type: "light_container",
		background-colour: $domestic_grey,
		text-colour: $black),
	'international_grey' : (
		container-type: "light_container",
		background-colour: $international_grey,
		text-colour: $black),
	'gray_light' : (
		container-type: "light_container",
		background-colour: $gray-light-flinders,
		text-colour: $black),
	'white_color' : (
		container-type: "light_container",
		background-colour: $white,
		text-colour: $black),
	);
