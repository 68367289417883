.adaptive-image {
  .image-width-all {
    &.image_width_25 {
      max-width: 25%;
    }
    &.image_width_50 {
      max-width: 50%;
    }
    &.image_width_75 {
      max-width: 75%;
    }
  }
  .image-align-all {
    &.image_align_center {
      margin: 0 auto;
    }
    &.image_align_right {
      margin-right: 0;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(md) {
    .image_width_25 {
      max-width: 25%;
    }
    .image_width_50 {
      max-width: 50%;
    }
    .image_width_75 {
      max-width: 75%;
    }
    .image_align_center {
      margin: 0 auto;
    }
    .image_align_right {
      margin-right: 0;
      margin-left: auto;
    }
  }
}
