.find-an-expert {
	.top_container {
		background-color: $flinders-navy;
	}

	.find_expert {
		&_header {
			color: $flinders-gold;
		}
		&_sub_text {
			color: $gray-light-flinders-display2;
			font-size: 1.525rem;
			font-weight: 100;
			line-height: 1.2em;
		}
		&_search_container {
			border: 1px solid $flinders-gold;
			margin-bottom: 0.5rem;
			min-height: 48px; //TODO decide on better default
			background-color: $white;

			//SAJARI INPUT STYLE CLASSES
			/* input */
			.sj-input {
				&__input {
					background-color: $white;
					box-shadow: none;
					border-radius: 0;
					padding-left: 1rem;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 0;
          color: $black;
					&.sj-input__input--focused {
						outline-width: 1px;
					}
					input::placeholder {
						color: $sajari-search-grey;
					}
				}
				/* button */
				&__button {
					background-color: $flinders-gold;
					color: $black;
					font-size: 24px;
					padding: 0.75rem;
					:focus {
						outline-color: #00A0D1;
					}
				}

				/* typeahead */
				&__typeahead {
					color: $gray-search;
				}

				/* suggestions */
				&__suggestions {
					border: 1px solid $flinders-gold;
          color: $black;
					&__item {
					}
				}
			}


		}
		&_sub_header {
			color: $white;
		}

		&_filter {
			&.find_expert_filter--locked {
				label {
					cursor: not-allowed;
				}
			}
			border: 1px solid $flinders-gold;
			padding: 1rem;
			position: relative;
			margin-bottom: 4px;
			@include media-breakpoint-down(sm) {
				padding: 0.5rem;
			}
			label {
				color: $flinders-gold;
				margin: 0;
				font-size: 1rem;
				line-height: 2rem;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				padding: 1rem;
				@include media-breakpoint-down(sm) {
					padding: 0.5rem;
				}
				cursor: pointer;
			}
			.find-expert-filter-checkbox {
				appearance: none; //This is not supported in i.e. and the other options hurt accessibility, so the checkboxes will appear normal in IE
				position: relative;
				float: right;
				background-color: $flinders-navy;
				width: 2rem;
				height: 2rem;
				border: 1px solid $flinders-gold;
				&:before {
					content: "";
				}
				&:after {
					content: "";
				}
				&:checked:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					background-color: $flinders-gold;
					width: 2.8rem; //This value comes from pythagoras theorem: h^2 = a^2 + b^2 where a and b are 2rem and I have rounded down.
					height: 1px;
				}
				&:checked:after {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(-45deg);
					background-color: $flinders-gold;
					width: 2.8rem; //This value comes from pythagoras theorem: h^2 = a^2 + b^2 where a and b are 2rem and I have rounded down.
					height: 1px;
				}
			}
		}
	}

	.results_container {
		transition: all 1.5s;
		.sj-summary {
			&__results-text {
			}
			&__search-term {
			}
			&__query-time {
			}
			&__query-override {
				display: block;
			}
		}
		//Results styles
		.sj-pipeline-response {
			padding-top: 1rem;
			padding-bottom: 1rem;
			> ol {
				li {
					flex: 50%;
					max-width: 50%;
					margin-bottom: 0.5rem;
					@include media-breakpoint-down(md) {
						flex: 100%;
						max-width: 100%;
					}
				}
			}
		}
		.sj-results__result {
			margin: 0.5rem;
			flex-direction: column;
			border-bottom: 1px solid grey;
			display: none;
			&__title {
				margin: 0.5rem 0;
				> h3 {
					font-weight: 600;
					font-size: 1.2rem;
				}
			}
			&__description {
				height: 88px; //22 * 4
				overflow: hidden;
				@include media-breakpoint-down(md) {
					height: auto;
				}
			}
			&__link {
			}
			&__image {
				min-width: 90px;
				min-height: 90px;
				width: 90px;
				height: 90px;
				border-radius: 45px;
				margin: 0;
				background-size: cover;
			}
		}

		//Paginator
		.sj-paginator {
			display: none;
			&.show_paginator {
				display: flex;
			}
			&__page {
				&-button {

				}
				&-number {

					&.sj-paginator__page-number--current {
						background-color: $charcoal_black;
						color: $white;
					}
				}
			}
		}
	}
}
