.dark_container {
    a {
        color: $white;
    }

    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }

    }
}

.style_section_round_edges{
  &_top_left {
    border-top-left-radius: 25px;
  }
  &_top_right {
    border-top-right-radius: 25px;
  }
  &_bottom_right {
    border-bottom-right-radius: 25px;
  }
  &_bottom_left {
    border-bottom-left-radius: 25px;
  }
}

.container {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}
.section_container {
    padding-top: 75px;
    padding-bottom: 75px;
    .section_container {
        margin: 75px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
    &-full_width {
        @extend .pos_rel;
        width: 100%;
        overflow: hidden;
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            z-index: -2;
            top: 0;
            right: calc(100% - 15px);
            background-color: inherit;
            display: block;
        }
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            z-index: -2;
            top: 0;
            left: calc(100% - 15px);
            background-color: inherit;
            display: block;
        }
    }
}
.transparent_container {
    color: $black;
}
.inside__definition {
    color: $blue;
    i {
       color: $blue;
    }
}
.inside__definition__white {
    color: $white;
    i {
       color: $white;
    }
}
.readmore_details__yellow {
    @extend .base_animation_buttons;
    background: $yellow;
    border: none;
    color: $blue;
    white-space: inherit;
    padding-right: 2.5rem;
    display: block;
    span {
        @extend .button_position_right_icon;
        display: inline-block;
        padding-left: 0;
        padding-right: 1rem;
        i {
            font-size: $font-size-lg;
        }
    }
    i {
        @extend .icons_in_buttons;
        margin-left: 0;
        color: $blue;
    }

}
.readmore_details__navy {
    @extend .base_animation_buttons;
    background: none;
    color: $blue;
    border: 1px solid $blue;
    display: block;
    span {
        @extend .button_position_right_icon;
        display: inline-block;
        padding-left: 0;
        padding-right: 1rem;
        i {
            font-size: $font-size-lg;
        }
    }
    i {
         @extend .icons_in_buttons;
    }
}
.dark_blue_container {
   background: $blue;
   @include alpha-transparency-options($blue);
   color: $white;
   a {
       color:$gray-light-flinders-display3;
       &:hover, &:focus {
            color: $white;
        }
    }
    i {
        color: $white;
        &.fa-twitter {
            color:#3fbdee;
        }
    }
    h3 {
        a {
            text-decoration: none;
            color: $white;

        }
    }

    &.component_section_featured_component {
        h3 {
            a {
                text-decoration: none;
                color: $yellow;
            }
        }

    }

    .classic_yellow_button {
         @extend  .inside__definition;
         &:hover, &:focus {
            color: $blue;
        }
    }
     .classic_yellow_button_small {
        @extend  .inside__definition;
        &:hover, &:focus {
            color: $blue;
        }
    }
    .yellow_transparent_button {
        color: $yellow;
        &:focus {
            color: $yellow;
        }
        &:hover {
            color: $black;
        }
    }
    .classic_white_button_small,
    .classic_white_button  {
        @extend .inside__definition__white;
        &:hover {
            color: $black;
        }
    }
    .yellow_button {
        text-transform: uppercase;
        color: $blue;
        &:before {
            color: $blue;
        }
        &:hover {
            color: $blue;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        @extend  .inside__definition;
        &:hover {
            @extend .inside__definition__white;
        }
    }
    // For new cta white button with blue text.
    .classic_white_button_blue_text,
    .classic_white_button_blue_text__small {
      i {
        color: $light_blue;
      }
      &:hover {
        color: $light_blue;
        i {
          color: $light_blue;
        }
      }
    }
    // For new cta white button with navy text.
    .classic_white_button_navy_text,
    .classic_white_button_navy_text__small {
      i {
        color: $blue;
      }
      &:hover {
        color: $blue;
        i {
          color: $blue;
        }
      }
    }
    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }

    }

    .slick-dots{
        li {
            button::before {
                color: $white;
            }
            &.slick-active {
                color: $white;
            }
         }
    }
    blockquote {
        color: $white;
        &:after {
           color: $white;
        }
    }
    .hr_alignment_opening {
        color: $white;
    }
    .hr_alignment_content {
        > div {
            background: $blue;
            color: $white;
        }
        &:after {
           background: $white;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_transparent_button_white_border;
            &:hover {
                color: $blue;
            }
        }
    }
}

.black_container {
    background: $black;
    @include alpha-transparency-options($black);
    @extend .inside__definition__white;
    a {
       color:$gray-light-flinders-display3;
       &:hover, &:focus {
            color: $white;
        }
    }
    .yellow_transparent_button {
        color: $black;
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }

    .classic_white_button_small,
    .classic_white_button {
        color: $white;
        &:hover {
            color: $black;
        }

    }

    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }
    }
    h3 {
        a {
            text-decoration: none;
            color: $white;

        }
    }
    .classic_yellow_button {
        @extend  .inside__definition;
        &:hover {
            color: $black;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            @extend  .inside__definition;
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            @extend  .inside__definition;
        }
    }
    // For new cta white button with blue text.
    .classic_white_button_blue_text,
    .classic_white_button_blue_text__small {
      i {
        color: $light_blue;
      }
      &:hover {
        color: $light_blue;
        i {
          color: $light_blue;
        }
      }
    }
    // For new cta white button with navy text.
    .classic_white_button_navy_text,
    .classic_white_button_navy_text__small {
      i {
        color: $blue;
      }
      &:hover {
        color: $blue;
        i {
          color: $blue;
        }
      }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
       @extend  .inside__definition;
        &:hover {
            @extend .inside__definition__white;
        }
    }
    blockquote {
        color: $white;
        &:after {
           color: $white;
        }
    }
    .hr_alignment_opening {
        color: $white;
    }
    .hr_alignment_content {
        > div {
            background: $black;
            color: $white;
        }
        &:after {
           background: $white;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_transparent_button_white_border;
            &:hover {
                color: $blue;
            }
        }
    }
}

.gray_light_container-d1 {
    background: $gray-light-flinders-display1;
    @include alpha-transparency-options($gray-light-flinders-display1);
    color: $black;
    &.component_section_featured_component {
        h3 {
            a {
                text-decoration: none;
                color: $black;
            }
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
}

.gray_container {
    background: $gray-flinders;
    color: $black;
     h3 {
        a {
            text-decoration: none;
            color: $black;
        }
    }
    i {
        color: $blue;
        &.fa-twitter {
            color:$blue;
        }
    }
    .classic_yellow_button,
    .classic_yellow_button_small {
        @extend .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            @extend  .inside__definition;
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
           @extend .inside__definition;
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        @extend .inside__definition;
        &:hover {
            @extend .inside__definition__white;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    .hr_alignment_content {
        > div {
            background: $gray-flinders;
            color: $black;
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_yellow_button_transform;
            &:hover {
                color: $blue;
            }
        }
    }
}

.gray_darker_container {
    background: $gray-search;
    @include alpha-transparency-options($gray-search);
    color: $white;
    h3 {
        a {
            text-decoration: none;
            color: $white;
        }
    }
    a {
       color: $blue;
       &:hover, &:focus {
            color: $white;
        }
    }
    i {
        color: $blue;
        &.fa-twitter {
            color:$blue;
        }
    }
    .classic_yellow_button,
    .classic_yellow_button_small {
        @extend .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_yellow_button_transform {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border {
        color: $yellow;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_white_border {
        color: $white;
        &:hover {
            color: $blue;
        }
    }

    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }
    }

    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    blockquote {
        color: $white;
        &:after {
           color: $white;
        }
    }
    .hr_alignment_content {
        > div {
            background: $gray-search;
            color: $white;
        }
        &:after {
           background: $white;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            @extend  .inside__definition;
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            @extend  .inside__definition;
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        @extend  .inside__definition;
        &:hover {
            @extend .inside__definition__white;
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_yellow_button_transform;
            &:hover {
                color: $blue;
            }
        }
    }
}

.gold_container{
    background: $yellow;
    @include alpha-transparency-options($yellow);
    color: $black;
    a {
       color: $link_default;
       &:hover, &:focus {
           color: $flinders-navy;
        }
    }
    h3 {
        a {
            text-decoration: none;
            color: $black;

        }
    }
    .black_transparent_button {
        padding: 0 50px 0 1rem;

        &:before { color: $black; }
        &:hover {
            color: $yellow;

            &:before { color: $yellow; }
        }

        &.black_transparent_button--no-icon {
            padding: 0 16px;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__navy;
            &:hover {
                border: 1px solid $blue;
                color: $white;
                text-decoration: none;
                &:focus {
                    color: $black;
                    text-decoration: none;
                }
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                border: 1px solid $blue;
                background: $blue;
                color: $white;
            }
        }
    }
    .hub_featured_content_area {
        .hub_classic_button {
            &-d1 {
                @extend .base_animation_buttons;
                background: $white;
                border: 1px solid $white;
                color: $blue;
                white-space: nowrap;
                &:hover {
                     border: 1px solid  $gray-light-flinders;
                     text-decoration: none;
                     &:after {
                       @extend .base_animation_buttons__hover_after;
                    }
                }
                &:focus {
                    color: $blue;
                    text-decoration: none;
                }
                &:after {
                    @extend .base_animation_buttons__after;
                    background: $gray-light-flinders;
                    border: 1px solid  $gray-light-flinders;
                }
            }
            &-d2 {
                @extend .classic_white_button_navy_border;
                border: 1px solid $blue;
                background: none;
                color: $blue;
                white-space: nowrap;
                &:hover {
                     border: 1px solid $blue;
                     color: $white;
                }
                &:focus {
                    color: $blue;
                    text-decoration: none;
                }
                &:after {
                    background: $blue;
                    border: 1px solid $blue;
                    color: $white;
                }
            }
        }
    }
    blockquote {
        color: $link_default;
        &:after {
           color: $link_default;
        }
    }
    .hr_alignment_content {
        > div {
            background: $yellow;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;

        i {
           color: $yellow;
        }
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        color: $blue;
        i {
           color: $blue;
        }
        &:hover {
            color: $white;
            i {
               color: $white;
            }
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_white_button_navy_border;
        }
    }

}

.gray_dark_container {
    background: $gray-dark-flinders;
    @include alpha-transparency-options($gray-dark-flinders);
    color: $white;
    a {
       color:$gray-light-flinders-display3;
       &:hover, &:focus {
            color: $white;
        }
    }
    i {
        color: $blue;
        &.fa-twitter {
            color:$blue;
        }
    }
    .yellow_transparent_button {
        color: $black;
        &:hover {
            color: $black;
        }
    }
    .classic_white_button_small,
    .classic_white_button {
        color: $white;
        &:hover {
            color: $black;
        }
    }

    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }
    }

    h3 {
        a {
            text-decoration: none;
            color: $white;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    .classic_yellow_button,
    .classic_yellow_button_small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        color: $blue;
        i {
           color: $blue;
        }
        &:hover {
            color: $white;
            i {
               color: $white;
            }
        }
    }
    .hub_featured_content_area {
        .hub_classic_button {
             &-d1 {
                @extend .base_animation_buttons;
                background: $white;
                border: 1px solid $white;
                color: $black;
                white-space: nowrap;
                &:hover {
                     border: 1px solid  $gray-light-flinders;
                     text-decoration: none;
                     &:after {
                        @extend .base_animation_buttons__hover_after;
                    }
                }
                &:focus {
                    color: $black;
                    text-decoration: none;
                }
                &:after {
                    @extend .base_animation_buttons__after;
                    background: $gray-light-flinders;
                    border: 1px solid  $gray-light-flinders;
                }
            }
            &-d2 {
                @extend .base_animation_buttons;
                background: $primary_color;
                color: $white;
                border: 1px solid $white;
                &:hover {
                     border: 1px solid  $white;
                     color: $primary_color;
                     text-decoration: none;
                     &:after {
                        @extend .base_animation_buttons__hover_after;
                     }
                }
                &:focus {
                    color: $black;
                    text-decoration: none;
                }
                &:after {
                    @extend .base_animation_buttons__after;
                    background: $white;
                    border: 1px solid  $white;
                    color: $primary_color;
                }
            }
        }
    }
    blockquote {
        color: $white;
        &:after {
           color: $white;
        }
    }
    .hr_alignment_content {
        > div {
            background: $gray-dark-flinders;
            color: $white;
        }
        &:after {
           background: $white;
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_yellow_button_transform;
            &:hover {
                color: $blue;
            }
        }
    }
}
//Generate section styles!
@include section-styles-generator($section_styles_map);

.hub_featured_content_area {
    .hub_classic_button {
         &-d1 {
            @extend .base_animation_buttons;
            background: $yellow;
            border: 1px solid $yellow;
            color: $black;
            white-space: nowrap;
            &:hover {
                border: 1px solid $dusty-yellow;
                text-decoration: none;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: 1px solid $dusty-yellow;
            }
        }
         &-d2 {
            @extend .classic_white_button_navy_border;
            border: 1px solid $black-display1;
            background: none;
            color: $black;
            white-space: nowrap;
            &:hover {
                 border: 1px solid $black-display1;
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                background: $black-display1;
                border: 1px solid $black-display1;
                color: $white;
            }
        }
    }
}

.gray_darker_container-display2 {
    background: $charcoal_black-display2;
    @include alpha-transparency-options($charcoal_black-display2);
    color: $white;
    i {
        color: $white;
    }
}
.white_container_featured {
    a {
       // color: $link_default;
       &:focus {
           color: $flinders-navy;
        }
    }
     h3 {
        a {
            text-decoration: none;
            color: $black;

        }
    }
    .read_more {
        @extend .classic_yellow_button_transform;
        @extend .btn__icons;
        @extend .d-block;
        padding-right: 2.4rem;
    }
}
.parallax_section{
  background-repeat: no-repeat;
  transition: 0s linear;
  //transform: translate3d(0, 0, 0);
  transition-property: background-position;
  //overflow: hidden;
}
.fixed_parallax_section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.fixed_parallax_section.fixed-parallax-ios {
    background-attachment: scroll;
}

//Viewport settings (e.g. "viewport_75")
//Note: "viewport_auto" is the default class applied if one needs to add styles for default height.
//NOTE: on desktop header is 64 px high, on portrait ipad and smaller it is 70px (aka $header_height)
//"unquote()" simply removes the quotations around the calc, it's a bit of a hack to deal with some x-browser issues with calc() and vh+rem/px values and sass variables.
.viewport {
    &_auto {
        //Nothing applied here for now.
    }
    &_50 {
        height:unquote("calc( #{$viewport-50} - #{$header_height_lg}*0.5 )");
        //For Tablet-portrait and smaller
        @include media-breakpoint-down(md) {
            height:unquote("calc( #{$viewport-50} - #{$header_height}*0.5 )");
        }
        //This is used to prevent the Authoring UI from expanding forever (some Adobe JS constantly calculates the viewport height and increases it)
        .aem-AuthorLayer-Edit & {
            height:unquote("calc( #{$viewport-50}/4 - #{$header_height_lg}*0.5 )");
            max-height:unquote("calc( #{$max-height-viewports} * 0.5)");
        }
    }
    &_75 {
        height:unquote("calc( #{$viewport-75} - #{$header_height_lg}*0.75 )");
        //For Tablet-portrait and smaller
        @include media-breakpoint-down(md) {
            height:unquote("calc( #{$viewport-75} - #{$header_height}*0.75 )");
        }
        .aem-AuthorLayer-Edit & {
            height:unquote("calc( #{$viewport-75}/4 - #{$header_height_lg}*0.75 )");
            max-height:unquote("calc( #{$max-height-viewports} * 0.75)");
        }
    }
    &_100 {
        height:unquote("calc( #{$viewport-100} - #{$header_height_lg} )");
        //For Tablet-portrait and smaller
        @include media-breakpoint-down(md) {
            height:unquote("calc( #{$viewport-100} - #{$header_height} )");
        }
        .aem-AuthorLayer-Edit & {
            height:unquote("calc( #{$viewport-100}/4 - #{$header_height_lg} )");
            max-height: $max-height-viewports;
        }
    }
}

//Simple css for removing the centre gap between a two column section
.component_section {
    .row.hide_two_col_gap {
        @include media-breakpoint-up(lg) {
            & > [class*='col-']:first-child {
                padding-right: 0;
            }
            & > [class*='col-']:last-child {
                padding-left: 0;
            }
        }
    }
}

//visibility options for screen sizes
//Note(JD): these styles use !important because they need to take precedence over JS show/hide which uses inline styles
.component_section {
  &--hidden-desktop {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }
  &--hidden-tablet {
    @include media-breakpoint-only(md) {
      display: none !important;
    }
  }
  &--hidden-mobile {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
}


//Vertical centering of content requires some enhancement to how the bootstrap rows and columns are handeled.
//This is in long form for each case so it is a little easier to adjust later.
.col_1_section, .col_2_section {
    @include media-breakpoint-up(lg) {
        .vertical-align-height-fix {
            height: 100%;
        }
        .row.display-flex {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            & > [class*='col-'] {
                display: flex;
                flex-direction: column;
            }
            &.content_top > [class*='col-'] {
                align-self: flex-start;
            }
            &.content_middle > [class*='col-'] {
                align-self: center;
            }
            &.content_bottom > [class*='col-'] {
                align-self: flex-end;
            }

            //NOTE(Christof): Fixing IE weirdness with flex + rescaled images + height.
            .component_in_section_wrapper{
              height:100%;
            }
        }
    }
}
.col_3_section, .col_4_section, .col_6_section{
    @include media-breakpoint-up(md) {
        .vertical-align-height-fix {
            height: 100%;
        }
        .row.display-flex {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            & > [class*='col-'] {
                display: flex;
                flex-direction: column;
            }
            &.content_top > [class*='col-'] {
                align-self: flex-start;
            }
            &.content_middle > [class*='col-'] {
                align-self: center;
            }
            &.content_bottom > [class*='col-'] {
                align-self: flex-end;
            }

            //NOTE(Christof): Fixing IE weirdness with flex + rescaled images + height.
            .component_in_section_wrapper{
              height:100%;
            }
        }
    }
}


//Vertical aligning of content if ignore breakpoint is set.

.column-ignore-breakpoint {
    @include media-breakpoint-up(xs) {
        .vertical-align-height-fix {
            height: 100%;
        }
        .row.display-flex {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            & > [class*='col-'] {
                display: flex;
                flex-direction: column;
            }
            &.content_top > [class*='col-'] {
                align-self: flex-start;
            }
            &.content_middle > [class*='col-'] {
                align-self: center;
            }
            &.content_bottom > [class*='col-'] {
                align-self: flex-end;
            }

            //NOTE(Christof): Fixing IE weirdness with flex + rescaled images + height.
            .component_in_section_wrapper{
              height:100%;
            }
        }
    }
}

// Light blue for staff portal.

.blue_container {
   background: $light_blue;
   @include alpha-transparency-options($light_blue);
   color: $white;
   a {
       color:$gray-light-flinders-display3;
       &:hover, &:focus {
            color: $white;
        }
    }
    i {
        color: $white;
        &.fa-twitter {
            color:#3fbdee;
        }
    }
    h3 {
        a {
            text-decoration: none;
            color: $white;

        }
    }

    &.component_section_featured_component {
        h3 {
            a {
                text-decoration: none;
                color: $yellow;
            }
        }

    }

    .classic_yellow_button {
         @extend  .inside__definition;
         &:hover, &:focus {
            color: $blue;
        }
    }
     .classic_yellow_button_small {
        @extend  .inside__definition;
        &:hover, &:focus {
            color: $blue;
        }
    }
    .yellow_transparent_button {
        color: $yellow;
        &:focus {
            color: $yellow;
        }
        &:hover {
            color: $black;
        }
    }
    .classic_white_button_small,
    .classic_white_button  {
        @extend .inside__definition__white;
        &:hover {
            color: $black;
        }
    }
    .yellow_button {
        text-transform: uppercase;
        color: $blue;
        &:before {
            color: $blue;
        }
        &:hover {
            color: $blue;
        }
    }
    .classic_yellow_button_transform,
    .classic_yellow_button_transform__small {
        @extend  .inside__definition;
        &:hover {
            color: $blue;
        }
    }
    .classic_transparent_button_yellow_border,
    .classic_transparent_button_yellow_border__small {
        color: $yellow;
        i {
           color: $yellow;
        }
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_transparent_button_white_border,
    .classic_transparent_button_white_border__small {
        @extend .inside__definition__white;
        &:hover {
            color: $blue;
            i {
               color: $blue;
            }
        }
    }
    .classic_white_button_navy_border__small,
    .classic_white_button_navy_border  {
        @extend  .inside__definition;
        &:hover {
            @extend .inside__definition__white;
        }
    }
// For new cta white button with blue text.
    .classic_white_button_blue_text,
    .classic_white_button_blue_text__small {
      i {
        color: $light_blue;
      }
      &:hover {
        color: $light_blue;
        i {
          color: $light_blue;
        }
      }
    }
// For new cta white button with navy text.
    .classic_white_button_navy_text,
    .classic_white_button_navy_text__small {
      i {
        color: $blue;
      }
      &:hover {
        color: $blue;
        i {
          color: $blue;
        }
      }
    }
    .black_transparent_button {
        color: $black;

        &:hover { color: $yellow; }

    }

    .slick-dots{
        li {
            button::before {
                color: $white;
            }
            &.slick-active {
                color: $white;
            }
         }
    }
    blockquote {
        color: $white;
        &:after {
           color: $white;
        }
    }
    .hr_alignment_opening {
        color: $white;
    }
    .hr_alignment_content {
        > div {
            background: $light_blue;
            color: $white;
        }
        &:after {
           background: $white;
        }
    }
    &_featured {
        .read_more {
            @extend .readmore_details__yellow;
            &:hover {
                text-decoration: none;
                border: none;
                color: $blue;
                &:after {
                   @extend .base_animation_buttons__hover_after;
                }
            }
            &:focus {
                color: $black;
                text-decoration: none;
            }
            &:after {
                @extend .base_animation_buttons__after;
                background: $dusty-yellow;
                border: none;
            }
        }
    }
    &__bar {
       .bar_heading {
            @extend .hr_alignment_opening;
            line-height: 2rem;
            font-size: 1.4rem;
        }
        .bar_button {
            @extend .classic_transparent_button_white_border;
            &:hover {
                color: $blue;
            }
        }
    }
}
