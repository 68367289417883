//General Transparency rules mixin for solid colour background sections - these could be made available generally but are currently only used for section backgrounds
//Example use: "@include alpha-transparency-options($flinders-gold);"
@mixin alpha-transparency-options($bg-colour) {
    //Note: The second argument is the amount you want to reduce the alpha channel i.e. 0.2 would result in an Alpha value of 0.8
    &.transparency {
        //Note: The class "transparency_none" is used as a default for the config, if it ever needs an explicit value this can be used.
        // &_none {
        //     background-color: $bg-colour;
        // }
        //This works but is confusing to most people so I have reproduced it in long-form
        // @for $i from 1 through 4 {
        //     &_#{$i*20} {
        //         $alpha_to_use: $i / 5;
        //         background-color: transparentize($bg-colour, $alpha_to_use);
        //     }
        // }
        &_20 {
            background-color: transparentize($bg-colour, 0.2);
        }
        &_40 {
            background-color: transparentize($bg-colour, 0.4);
        }
        &_60 {
            background-color: transparentize($bg-colour, 0.6);
        }
        &_80 {
            background-color: transparentize($bg-colour, 0.8);
        }
    }
}