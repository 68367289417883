//eventon - reference to the eventon wordpress plugin that is installed on events.flinders.edu.au (flindersevents.wpengine.com)

.eventon_calendar_icon {
    color: $black;
    font-size: $btn-padding-x + $btn-padding-x;
    margin: 0.4rem 0 0;
    &:hover {
        color: $black;
    }
    &:focus {
        color: $black;
    }
}

.component_section_item {
    position: relative;
    > .component_section_eventon_wrap {
        background: $white;
        padding: 0rem;
        border: 1px solid $gray-light-flinders;
        .eventon_date {
            margin-bottom: 1rem;
            position: relative;
            &:after {
                position: absolute;
                content: '';
                top:0;
                left: 0;
                width: 34px;
                height: 3px;
                background: $yellow;
            }
            .eventon_date_month {
                font-size: 1.8rem;
                font-weight: $semibold;
                line-height: 2.5rem;
                display: block;
                
                i {
                    font-style: normal;
                    text-transform: uppercase;
                    font-size: $font-size-xs;
                    display: block;
                    line-height: 0.7rem;
                    font-weight: bold;
                    padding-left:2px;
                }
            }
            
            .event_type {
                letter-spacing: 2px;
                padding: 0.1rem 0.2rem;
                font: bold $font-size-xs $font_family;
                line-height: 0.8rem;
                color: $white;
                text-transform: uppercase;
                z-index: 1;
                margin-left: 0.5rem;
                float: right;
                background: $yellow;
            }
        }
        .eventon_actions {
            display: table;
            width: 100%;
            border-collapse: collapse;
            padding: 0;
            margin: 0;
            div {
                display: table-cell;
                vertical-align: middle;
                &:first-child {
                    text-align: left;
                    padding-right: 2px;
                }
                &:last-child {
                    text-align: center;
                    width: 120px;
                }
                
            }
        }
        h3 {
            height: 85px;
            overflow: hidden;
        }
        .news_date {
            line-height: 1rem;
            color: $black;
        }
        .classic_white_button_navy_border__small {
            white-space: nowrap;
        }
        .component_section_eventon_inner_wrap{ 
            padding:1rem;
        }
        .events_overlay {
            background: rgba(0, 0, 0, 0.4);
            .eventon_date, .news_date, .eventon_place, h3{
                color:#fff;
            }
        }
    }
}

.eventon_place {
    display: block;
    height: 33px;
    overflow: hidden;
}

@include media-breakpoint-down(md) {
    .eventon_component {
        padding-bottom: 3rem;
    }
    .eventon_place {
        height: auto;
    }
    .component_section_item {
        > .component_section_eventon_wrap {
            h3 {
                 height: auto;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .eventon_place {
        height: 50px;
    }
    .eventon_component {
        h4 {
            height: 62px;
            overflow: hidden;
        }
    }
}
@include media-breakpoint-up(xl) {
    .eventon_place {
         height: 33px;
    }
}