#sticky_nav {
    position: static;
}

.context-navigation {
    margin: 3rem 0 24px 0;
    &__heading {
        font-size: $font-size-xs;
        margin-top: 0;
        letter-spacing: 0.125rem;
        position: relative;
        span {
             @extend  .button_position_right_icon;
        }
    }
    &__list {
        list-style: none;
        font-size: $font-size-sm;
        
        &__item {
            text-decoration: none;
            cursor: pointer;
            font-weight: 600;
            padding: .75rem 1rem;
            text-decoration: none;
            background-color: inherit !important;
            transition: background-color 0.3s linear;
            &:first-child {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
            &:last-child {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            &.active, &:hover, &:focus {
                color: inherit;
                background-color: $gray-accordion-hover-over-header !important;
            }
            &.active:hover {
                color: inherit;
                background-color: $gray-accordion-hover-over-header !important;
            }

            i {
                font-size: $font-size-lg;
            }
        }
    }
    &__list-wrapper {
        margin-bottom: 1.5rem;
    }
}

.example_component_section {
    height: 300px;

    &-full_width {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            right: calc(100% - 15px);
            width: 9999px;
            height: 100%;
            z-index: -2;
            background-color: inherit;
            display: block;
        }
        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: calc(100% - 15px);
            width: 100%;
            height: 100%;
            z-index: -2;
            background-color: inherit;
            display: block;
        }
    }

    &-white {
        background-color: #fff;
    }

    &-grey {
        background-color: #f5f5f5;
    }
}
 .context-navigation {
    &__heading {

        i {
            display: none;
        }
    }
}
@include media-breakpoint-down(md) {
    .context-navigation {
        &__heading {
            background-color: inherit;
            padding: .75rem 1rem;
            margin-bottom: -1px;
            border: 1px solid $gray-accordion-border;

            i {
                cursor: pointer;
                display: block;
            }
        }
    }
   
}

@media (min-width: 992px) {
    #global-mobile-navigation {
        display: none;
    }
}

/* for Navigation component. */

.cmp-navigation {
	border-top: 1px solid $breadcrumb_light_grey;
	border-left: 1px solid $breadcrumb_light_grey;
	border-right: 1px solid $breadcrumb_light_grey;
	font-size: 14px;
	font-weight: 600;

	&__group {
		list-style: none;
		padding-left: 0;
		margine-bottom: 0;
	}
	
	&__item {
	
		a {
			color: #555;
			text-decoration: none;
		}
	
		&-link {
			position: relative;
			display: list-items;
			border-bottom: 1px solid $breadcrumb_light_grey;
			line-height: 45px;
		}
	
		&--level-0 &-link span {
			padding-left: 1rem;
		} 
		
		&--level-1 &-link span {
			padding-left: 2.5rem;
		} 
		
		&--level-2 &-link span {
			padding-left: 3.2rem;
		}
		
		&--level-3 &-link span {
			padding-left: 3.9rem;
		}
		
		&--active > &-link {
			background-color: $flinders-gold;
		}
		
	}
	
	&__parent {
		&--level {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			&-1, &-2, &-3 {
				display: none;
			}
				
			&-0 {
				display:block;
			}
		}
	}
		
	&__icon {
		float: left; 
		display: flex;
		z-index: 5;
		position: relative;
	}
	
	&__span {
		line-height: 1.5;
		display: inline-block;
		padding: 0.75rem 1rem 0.75rem;
	}
	
	li.open > .cmp-navigation__item-link {
		background-color: $flinders-gold !important;
	}

  .cmp-navigation-heading {
      margin: 3rem 0 24px 0;
      font-size: $font-size-xs;
      margin-top: 0;
      letter-spacing: 0.125rem;
      position: relative;
      display: none;
      span {
        @extend  .button_position_right_icon;
      }

      i {
        display: none;
      }
    }

  @include media-breakpoint-down(md) {
    .cmp-navigation-heading {
        background-color: $gray-light-flinders-display1;
        padding: .75rem 1rem;
        margin-bottom: -1px;
        border: 1px solid $breadcrumb_light_grey;
        display: block;

        i {
          cursor: pointer;
          display: block;
        }
    }

    ul {
      display: none;
    }

  }

}
// Border color on light section background.
.gray_light_container, .domestic_grey_container, .international_grey_container, .gold_container {
  .context-navigation {
    &__list {
      &__item {
        border-color: $gray-accordion-border ;
      }
    }

  }
}

.gray_dark_container, .gray_darker_container {
  .item-icon {
      color: $white;
    }
}
