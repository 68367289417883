
.breadcrumb_black {
    background: $blue;
    @include border-radius(0);
    padding: 0;
    line-height: 60px;
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
        display: none;
    }

    .breadcrumb_home {
        color: $yellow;
        &:focus {
            outline: none;
        }
        i {
          color: $yellow;
        }
    }
    .breadcrumb-item {
        position: relative;
        color: $yellow;
        font-size: $font-size-sm;
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
        .breadcrumb_chevron {
            font-weight: 300;
            font-size: 9px;
            color: $breadcrumb_light_grey;
            padding: 0 0.75rem;
        }
        &:before {
            //Overried bootstrap breadcrumb stuff
            content: "";
            display: none;
        }
        &:first-child {
            &:before,
            &:after {
                display: none;
            }
        }
        &.active {
            color: $white;
            font-size: $font-size-sm;
            &:hover {
                text-decoration: none;
            }
        }
    }
  .breadcrumb_color_white {
    color: #fff !important;
  }

  .breadcrumb_color_black {
    color: #000 !important;
  }
}

.breadcrumb {
    &--has-tail {
        display: block;
    }

    &-tail {
        background-color: $white;

        &--fixed {
            i {
                @include media-breakpoint-up(lg) {
                    color: $yellow;
                }
            }
        }

        &-panel {
            border-top: 3px solid $yellow;
            width: 100%;
            position: fixed;
            z-index: $z-index-fan-protected-tag;
            transition: border-color .25s linear;

            @include media-breakpoint-down(md) {
                display: none;
                top: $header_height;
                z-index: $z-index-fan-protected-tag;
                &--visible {
                    display: block;
                }

            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 0;
            }

            .breadcrumb-tail--fixed & {
                @include media-breakpoint-up(lg) {
                    border-top-color: $blue;
                    position: fixed;
                    top: 64px;
                    z-index: $z-index-fan-protected-tag;
                    .staff_portal_fan_indicator & {
                        top: 95px;
                    }
                }
            }
        }

        &-end {
            background-color: $yellow;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            float: right;
            font-size: 12px;
            padding: 0 10px;

            transition: background-color .25s linear;

            .breadcrumb-tail--fixed & {
                @include media-breakpoint-up(lg) {
                    background-color: $blue;
                    color: $white;
                }
            }
        }
    }
}

.breadcrumb_transparent {
  background: transparent !important;
}


