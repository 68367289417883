$light_grey_border_colour: $gray-light-flinders-display2;
$nav_item_vertical_padding: 10px;
$nav_item_horizontal_padding: 13px;
$top_navigation_height: 42px;
$expertise_list_item_grey: $gray-light-flinders-display1;
//Everything but the header can be wrapped inside this selector
.staff_profile_page {
	.research_intro {
		&_container {
			background-color: $gray-light-flinders;
			.tab_title {
        		font-size: 1.125rem;
				font-weight: 700;
				margin-bottom: 1rem;
        	}
			@include media-breakpoint-down(sm) {
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		&--desktop {
			padding: 25px 15px;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		&--mobile {
			padding: 15px;
			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
	}
    .top_nav_container {
    	border-bottom: 1px solid $light_grey_border_colour;
        .border_light_grey {
        	border: 1px solid $light_grey_border_colour;
        	&--bottom {
        		@include media-breakpoint-down(lg) {
	            	&:after{
	        			content: "";
	        			position: absolute;
	        			bottom: -1;
	        			border-bottom: 1px solid $light_grey_border_colour;
	        			//This is a bit of a hack to get the borders to stretch full width - outside of their containers. This is avoiding doing a calc or vw units
	        			left: 0;
	        			right: 0;
	        			margin-left: -500px;
	        			margin-right: -500px;
	        		}
        		}
        	}
        }
        .nav_buffer {
        	@include media-breakpoint-down(md) {
        		$white_full: rgba(255, 255, 255, .99);
	        	$white_trans: rgba(255, 255, 255, 0.30);
	        	z-index: 1;
	    		position: absolute;
			    top: 0;
			    bottom: 0;
			    height: 42px;
			    width: 70px;
			    pointer-events: none; //NOTE: Not supported in older IE - but will just mean the clickable area is smaller.
	        	&.left_buffer {
				    left: 0;
				    display: none;
	        		@include linear-gradient($white_full, $white_trans);
	        	}
	        	&.right_buffer {
				    right: 15px; //inset a little to account for the default padding on the sides
				    @include linear-gradient($white_trans, $white_full);
	        	}
        	}
        }
    }
    .tab_navigation_container {
    	overflow: auto;
        .nav {
            //
        }
        .nav-tabs {
            border: none;
            min-width: 825px;
            .nav-item {
            	margin-bottom: 0; //This is to cancel out the -1 margin from bootstrap - hoping this is not for x-browser stuff..
            	height: $top_navigation_height;
            	&+.nav-item {
			    	margin-left: 0; //This overrides some styles from bootstrap.
				}
            	&:first-of-type {
            		.nav-link {
            			padding-left: 0;
            			&.active,&:hover {
                        	&:before {
	                            left: 0;
	                        }
	                    }
            		}
            	}
                .nav-link {
                    text-decoration: none;
                    padding: $nav_item_vertical_padding $nav_item_horizontal_padding;
                    margin: 0;
                    font-size: 0.875rem;
                    line-height: 22px;
                    border: none;
                    color: $black;
                    position: relative;
                    text-align: center;
                    height: 42px;
                    &.active {
                        font-weight: 600;
                        &:before {
                            content: "";
                            position: absolute;
                            left: $nav_item_horizontal_padding;
                            right: $nav_item_horizontal_padding;
                            background: $flinders-gold;
                            height: 6px;
                            z-index: 99;
                            bottom: 0;
                        }
                    }
                    &:hover {
                        &:before {
                            content: "";
                            position: absolute;
                            left: $nav_item_horizontal_padding;
                            right: $nav_item_horizontal_padding;
                            background: $flinders-gold;
                            height: 6px;
                            z-index: 99;
                            bottom: 0;
                        }
                    }
                    &:after {
                        display: block;
                        content: attr(title);
                        font-weight: 600;
                        height: 0;
                        overflow: hidden;
                        visibility: hidden;
                    }
                }
            }
        }
    }
    .social_media_container {
    	$black_full: rgba(0, 0, 0, 0.18);
    	$black_trans: rgba(0, 0, 0, 0);
    	overflow: hidden;
    	@include media-breakpoint-down(md) {
    		overflow-y: hidden;
    		overflow-x: auto;
    	}
    	.social_nav_scroller {
    		cursor: pointer;
    		color: $black;
    		text-align: center;
    		line-height: 42px;
    		z-index: 1;
    		position: absolute;
		    top: 0;
		    bottom: 0;
		    height: 42px;
		    width: 2rem;
		    background-color: transparent;
		    display: none;
    		&.left_block {
				left: 0;
				@include linear-gradient($black_full, $black_trans);
    		}
    		&.right_block {
    			right: 0;
    			@include linear-gradient($black_trans, $black_full);
    		}
    	}
    	.social_media_list {
    		width: ($top_navigation_height * 12);
    		list-style: none;
    		margin-bottom: 0;
    		padding-left: 0;
    		.social_media_item {
    			float: left;
    			height: $top_navigation_height;
    			width: $top_navigation_height;
    			line-height: $top_navigation_height;
    			text-align: center;
    			color: $light_grey_border_colour;
    			font-size: 1rem;
    			border-left: 1px solid $light_grey_border_colour;
    			cursor: pointer;
    			&:last-of-type {
    				border-right: 1px solid $light_grey_border_colour;
    			}
    			.spp_icon_link {
    				vertical-align: top;
    			}
    			.spp_social_icon {
    				width: 100%;
    				height: 100%;
    			}
    		}
    	}
        height: $top_navigation_height;
    }
    .tab_content_container {
        .tab-content {
            // margin-top: 1rem;
            font-size: 1rem;
            
            p {
            	margin-bottom: 1rem;
            }
        
        	ul {
        		margin-bottom: 0px;
        		font-weight: normal;
        			li {
						margin-bottom: .5rem;
					}
        	}
        	
        	.tab_title {
        		font-size: 1.125rem;
				font-weight: 700;
				margin-bottom: 1rem;
        	}

        	.expertise_container {
				.expertise_list {
					&_item {
						padding-right: 0px;
						margin-bottom: 15px;
						&:nth-child(2n) {
							@include media-breakpoint-only(md) {
								padding-right: 15px;
							}
						}
						&:nth-child(3n) {
							@include media-breakpoint-up(lg) {
								padding-right: 15px;
							}
						}
						@include media-breakpoint-down(sm) {
							padding-right: 15px;
						}
						&_inner {
							background-color: $expertise_list_item_grey;
							height: 50px;
						}
						&_label {
							font-size: 1rem;
							// line-height: 1rem;
						    text-align: center;
							padding: 0 0.5rem;
							max-height: 50px;
							overflow: hidden;
						}	
					}
				}
			}
        
	        //Research expertise and interests tab.
			.research_expertise_tab {
				padding-top: 15px;
				padding-bottom: 15px;
				
				.research_expertise_section {
					margin-bottom: 1rem;
				}
				
				.expertise_list_item_label .ellip-line {
					display: block;
				}
			} // end of research tab.
				
			// Career tab layout
			.career_tab {
				// padding-top: 15px;
				padding-bottom: 15px;
				
				.career_qualifications_section {
					padding-left: 15px;
					padding-right: 15px;
					margin-bottom: .5rem;
				}
			
				.career_bio_section {
					padding-left: 15px;
					padding-right: 15px;
					margin-bottom: 1rem;

					p {
						br {
							margin-bottom: .5rem;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				
			} // end of career tab.
			
			//Teaching tab.
			
			.teaching_tab {
				padding-top: 15px;
				padding-bottom: 15px;
				
				.teaching_section {
					margin-bottom: 1rem;
				}
			
			} // end of teaching tab
			
			.engagement_tab {
				margin-top: 15px;
			}
			// Media tab
			.media_tab {
				padding-top: 15px;
				padding-bottom: 15px;
				
				.media_expert {
					margin-bottom: 1rem;
				}
			} // end of media tab
			
			// Contact info template
			
			.contact_details {
				margin-bottom: 1rem;
				overflow: hidden;
				color: #777;
				line-height: 1.5rem;
				.contact_item {
					margin-bottom: 0.25rem;
					.fa {
						width: 20px;
						text-align: center;
					}
				}
				
				.contact_building {
					display: flex;
					span i {
						font-size: 1.2rem;
					}
				}
				
				.contact_address {
					padding-left: 19px;
				}
			} // end of contact info
			
		} // end of tab content.
    }
    // Side Nav Styles
    .side_nav_parent_container {
    	@include media-breakpoint-down(md) {
    		background-color: $gray-light-flinders-display1;
    	}
    }
    .side_nav {
        margin-top: 1rem;
        padding-bottom: 1rem;
        font-size: .875rem;
        &_container {
            padding: 1rem;
            margin-bottom: 1rem;
            &--shadow {
                $nav_box_shadow_x_offset: 0;
                $nav_box_shadow_y_offset: 2px;
                $nav_box_shadow_blur: 4px;
                $nav_box_shadow_spread: 2px;
                $nav_box_shadow_colour: hsla(0, 0%, 77%, 0.33);
                @include box-shadow($nav_box_shadow_x_offset, $nav_box_shadow_y_offset, $nav_box_shadow_blur, $nav_box_shadow_spread, $nav_box_shadow_colour);
                padding: 1rem;
                margin-bottom: 1rem;
            }
        }
        
        // Further information section.
	    .further_info_section {
	    	padding-top: 0px;
	    	padding-bottom: 0px;	
	    		    
		    .further_info_title {
		    	font-family: Roboto,sans-serif;
				font-size: 1.375rem;
				font-weight: 700;
				color: $blue;
		    	padding: 0px 15px 15px 15px;		    	
		    }
		    
			.fixedHeight {
			    height: 15em;
			}
			
		    .further_info_detail {
		    	padding: 0px 15px 0px 15px;
		    	overflow: hidden;
		    	ul {
			    	list-style: none;
			    	border-top: 1px solid #777;
			    	padding: 0px;
		    	
			    	li {
				    	color: #777;
				    	padding-top: 15px;

				    	a {
					    	color: #777;
						}
		    		}
		    	}
		    }
		  		
			.further_info_read_more {
				padding: 15px 15px 0px 15px;
			}
		} // end of further info
		
		// Media expertise and interests section.	
		.media_expertise_section {
			padding-top: 0px;
			padding-bottom: 0px;

			.media_expertise_title {
				font-family: Roboto,sans-serif;
				font-size: 1.375rem;
				font-weight: 700;
				color: $blue;
		    	padding: 0px 15px 0px 15px;
			}

			.media_expertise_detail {
				ul li {
					list-style-type: none;
		  			padding-left: 15px;
				    color: #777;
				    padding-top: 5px;
				    text-indent: -0.5em;
		  		}
		  		
		  		ul li:before {
		  			content: "\25BA \0020";
		  			font-size: 6px;
		  			position: relative;
		  			top: -2px;
		  			left: -10px;
		  			color: $flinders_gold;
				}
			}
		} // end of media expertise.
		
		// Profile section on side panel
		.profile_section {
			display: flex;
			@include media-breakpoint-down(sm) {
				padding-left: 0;
			}
			padding-left: 15px;
			.profile_tag_section {
				padding-left: 0px;
				padding-right: 0px;
				align-self: center;
			}
			
			.profile_tag {
				width: 100%;
				background-color: $flinders-gold;
				margin: 5px 5px 5px 0px;
				padding-left: 10px;
				font-size: 0.9rem;
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
			
			.profile_pic_section {
				position: relative;
				width: 160px;
				height: 160px;
				border-radius: 50%;
				border: 5px solid #fff;
				background-position-x: center;
				background-position-y: center;
				background-repeat: no-repeat;
				background-color: $flinders-gold;
				background-size: cover;
				&.has_tags {
					left: -3%;
				}
				&.no_tags {
					margin: 0 auto;
				}
			}
		} // end of profile section.
		
		.profile_contact_details {
			padding-top: 15px;
		
			.profile_contact_phone {
				@include media-breakpoint-up(md) {
					padding-left: 2px;
				
					a {
						padding-right: 0px !important;
					}
				}
			}
			.profile_contact_email {
				padding-right: 2px;
				margin-bottom: 5px;
			}
			.profile_button {
				@include media-breakpoint-down(sm) {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
		
		.edit_profile_link {
			a {
				text-decoration: none;
				color: #777;
				
				span {
					line-height: 1.5rem;
					 i {
						font-size: 1rem;
						color: #777;
						vertical-align: sub;
					}
				}
			}
		}	
    }

 	//Maybe temporary research now link content styling?
	.research_now_profile_link{
		margin-top: 1rem;
		text-align: center;
		background: bottom / contain no-repeat url('/etc.clientlibs/flinders/clientlibs/clientlib-site/resources/images/research-now-background.jpg') black;
		.research_now{
			margin-bottom:0;
			padding-top: 10rem;
			@include media-breakpoint-down(md){
			padding-top:5rem;
			}
		}

		.outputs_publications{
			color:white;
			padding-bottom:2rem;
			@include media-breakpoint-down(md){
			padding-bottom:1rem;
			}
		}

		.explore_now{
			margin-bottom:23rem;
			@include media-breakpoint-down(md){
			margin-bottom:12rem;
			}
		}
	}

}

// Staff profile page header.
.staff_profile_sub_heading {
	margin-bottom: 0px;
	font-size: 1.5rem;
	font-weight: normal; 
}

.staff_profile_heading {
	margin-bottom: 0px;
}

.staff_profile_header {
	padding: 10rem 0rem 3rem 0rem;
	@include media-breakpoint-down(md) {
		padding-top: 5rem;
	}
}
.staff_profile_default_header {
	.no_picture {
		padding-top: 5rem;
		@include media-breakpoint-down(md) {
			padding-top: 2rem;
		}
	}
}    
