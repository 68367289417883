.news_date {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $font-size-xs;
    font-style: normal;
    color: $gray-search;
    font-weight: bold;
    line-height: 1.7rem;
    em {
        font-style: normal;
    }
}
.news_component_section_wrap {
    .component_section_picture {
        overflow: hidden;
        height: 226px;
        text-align: center;
        vertical-align: middle;
        line-height: 226px;
        position: relative;
        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}



.news_component {
    .row {
        padding-top: 1.5rem;
        color: $gray-search;
        h1, h2, h3, h4 {
            color: $gray-dark-flinders;
            margin-bottom: 0.5rem;
        }
    }
}
.news_component_section_wrap {
    h3 {
        height: 3.75rem;
        overflow: hidden;
        margin-bottom:0.5rem;
    }
    .js-news-body {
        height: 4.75rem;
        line-height: 1.5rem;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    
}

@include media-breakpoint-between(xs, md) {
    .news_component.component_section {
        padding-top: $btn-padding-x + $btn-padding-x;
    }
    
    .news_component .component_section_item {
        padding-bottom: 2rem;
    }

}




@include media-breakpoint-between(xs, sm) {
    .news_component_section_wrap {
        .component_section_picture {height: 285px; max-height: none;}
    }
}
@include media-breakpoint-between(sm, md) {
    .news_component_section_wrap {
        .component_section_picture {height: 300px; max-height: none;}
    }
}
@include media-breakpoint-between(md, lg) {
    .news_component_section_wrap {
        .component_section_picture {height: 165px; max-height: none;}
    }
}