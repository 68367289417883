.domesticSwitch{
	#tickImage2{
		display:none;
	}
	border:none;
	&:focus, &.active{
		border: 5px solid $yellow;
		#domesticButton{display:none;}
		#internationalButton{display:block;}
		#tickImage2{display:block;}
		#tickImage1{display:none;}
	}
	@extend .base_animation_buttons;
	background: $gray !important;
	color: $white !important;
	p:first-child{font-weight: 100;}
	p:nth-child(2){font-weight: 100;font-size:12px;}
	p:nth-child(3){
		font-weight: 100;
		font-size:12px;
		line-height: normal;
	}
}
.internationalSwitch{
	#tickImage1{
		display:none;
	}
	border:none;
	&:focus, &.active{
		border: 5px solid $yellow;
		#domesticButton{display:block;}
		#internationalButton{display:none;}
		#tickImage2{display:none;}
		#tickImage1{display:block;}
	}
	@extend .base_animation_buttons;
	background: $gray-light !important;
	color: $white !important;
	p:first-child{font-weight: 100;}
	p:nth-child(2){
		font-weight: 100;
		font-size:12px;
		line-height: normal;
	}
}
.locationswitch_yellow_button {
	background: $yellow;
	border: 1px solid $yellow;
	display: inline-block;
	color: $black !important;
	text-align: center;
	padding: 0 20px;
	height: 3rem;
	line-height: 2.9rem;
	font-weight: $semibold;
	font-size: 1rem;
	text-decoration: none;
	white-space: nowrap;
	max-width: 100%;
	overflow: hidden;
	.material-icons {
		color: $black !important;
		font-size: 1.3rem;
		vertical-align: text-top;
	}
	&:hover,
	&:focus {
		background: $dusty-yellow;
		border: 1px solid $dusty-yellow;
		color: $black !important;
		text-decoration: none;
	}
	&:focus {
		text-decoration: none;
		outline: none;
	}
}

@include media-breakpoint-only(md) {
	.loc {
		position:relative;
		left:85px;
	}
	.svgDomIntlsm{
		position:relative;
		float:right;
		right:15px;
	}
	.tick{
		right:15px;
	}
	.domIntIcons {
		display: none;
	}
}
@include media-breakpoint-up(lg) {
	.top_main_navigation.navbar-nav .smDomIntlSubPageMenu{display:none !important;}

	.sectionfixed{
		// css to change icons [save course and domIntl switch] into black on Yellow Headers
		svg.save-course{
			.Heart{fill:$black;}
			.Oval{fill:$black;}
		}
		.domIntIcons{
			.globe{fill:$black;}
			.aus-map{fill:$black;}
		}
	}
	.loc {
		position:relative;
		width: 585px;
	}
}
@include media-breakpoint-only(xs) {
	.loc {
		position:relative;
		left:50px;
		line-height:0px;
	}
	.domIntIcons {
		display: none;
	}
}
@include media-breakpoint-only(sm) {
	.loc {
		position:relative;
		left:205px;
	}
	.domIntIcons {
		display: none;
	}
}
@include media-breakpoint-up(md){
	#intlStudent,#domStudent{
		display:none;
	}
}
@include media-breakpoint-down(md){
  .domIntlToolTip{
    display:none;
  }
  #intlStudent,#domStudent{
    display:block;
  }
  #domNavMobile{
		.aus-map{
			right: 15px;
		}
	}
  #intlNavMobile{
  	.globe{
  		right: 15px;
  	}
  }
}
header{
	.domIntIcons{
		.globe, .aus-map{		
    		fill: white;
    		top: -4px;
    		position: relative;
    		width:24px;
    		height:24px;
		}
    }
}

.dom_int_toggle{
	.globe, .aus-map, .tick{
	    width: 50px;
    	height: 50px;
    	fill: $flinders-gold;
    }
}

#intlNavMobile{
	cursor:pointer;
	.globe{
		float:right;
		position:relative;
		bottom:10px;
		width:24px;
		height:24px;
		fill:$white;
	}
	.tick{
		display:none;
	}
	&:focus, &.active{
		.globe{
			display:none;
		}
		
	}
}
#domNavMobile{
	cursor:pointer;
	.aus-map{
		float:right;
		position:relative;
		bottom:10px;
		fill:$white;
		width:24px;
		height:24px;
	}
	.tick{
		display:none;
	}
	&:focus, &.active{
		.aus-map{
			display:none;
		}
	}
}

#domNavMobile, #intlNavMobile{
	&:focus, &.active{
		.tick{
			display:block;
			float:right;
			position:relative;
			height: 38px;
			width: 60px;
			top: -23px;
			margin-bottom: -18px;
			fill:$black;
			@include media-breakpoint-up(md){
				left: 4px;
			}
		}
		background-color: $dusty-yellow;
		a{
			color:$black;
		}
	}
}


// Tooltip for Domestic International Toggle
.domIntlToolTip.tooltip.bs-tether-element-attached-top .tooltip-inner:before,
.domIntlToolTip.tooltip.tooltip-bottom .tooltip-inner:before {
	border-bottom-color: $gray-dom-tooltip;
}
.domIntlToolTip.tooltip {
	z-index: 1112;
}
// TODO : Below style needs to work with ".domIntlToolTip" custom class; Temporarilyb removed to avoid the repositioning of it on any events on page...
.domIntlToolTip .tooltip-inner {
	padding-top: 10px;
	padding-bottom: 5px;
	padding-right: 10px;
	text-align: left;
	max-width: 320px;
	background-color: $gray-dom-tooltip;
	color: $white;
	border-radius: 0px;
	font-family: Open Sans;
	font-weight: 400;
	font-size: .75rem;
	line-height: 1rem;
}
.domIntlToolTip .close{
	opacity: 1;
	text-shadow: none;
	color:$white;
	font-weight: normal;
	font-size: 1.2rem;
	position: absolute;
	right: 2px;
	top: 2px;
}

//Styles for the standalone "Domestic/International Toggle" component that can be dragged and dropped onto the page.
.dom-int-toggle-component {
	&.toggle_open {
		//Apply some specific styles when toggle is open
		.toggle_container .toggle_options .toggle_icon {
			@include media-breakpoint-up(lg) {
	            z-index: 113;
	        }
		}
	}
	.domestic_colour {
		background-color: $domestic_grey;
	}
	.international_colour {
		background-color: $international_grey;
	}

	.toggle_container {
		@include media-breakpoint-down(md) {
            float: left;
            max-width: 100%;
            width: 100%;
            height: 50px;
            padding-right: 15px
        }
	    float: right;
	    max-width: 300px;
		width: 100%;
		position: relative;

		//Modal stuff
		.dom_int_modal_overlay {
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background: rgba(0,0,0,0.8);
		    z-index: 112;
		    display: none;
		}
		.dom_int_modal {  
			display: none;
		    position: absolute;
		    top: 60px;
		    right: 0;
		    width: 800px;
		    @include media-breakpoint-down(md) {
	            width: 100%;
	        }
			z-index: 113;
			&:before {
				@include media-breakpoint-up(lg) {
		            content: "";
				    position: absolute;
				    width: 60px;
				    right: 0;
				    background: $flinders-gold;
				    height: 6px;
				    z-index: 114;
				    top: -6px;
		        }
			    
			}
			.dom_int_modal_content {
				box-sizing: border-box;
				width: 100%;
				@include media-breakpoint-down(md) {
		            max-width: 500px;
    				margin: 0 auto;
		        }
				z-index: 114;
				text-align: center;
				position: relative;
				color: white;
				.option_container {
					box-sizing: border-box;
					float: left;
					width: 50%;
					@include media-breakpoint-down(md) {
			            width: 100%;
			        }
					padding: 1.5rem;
					&.domestic_option {
						background-color: $dom_int_toggle_bg_dom;
					}
					&.international_option {
						background-color: $dom_int_toggle_bg_int;
					}
					&.selected {
						border: 0.5rem solid $flinders-gold;
						padding: 1rem;
						.button_row {
							.tick_icon {
								display: inline-block;
								
							}
							.cta-button {
								display: none;
							}
						}
					}
					.big_icon_row {
						float: left;
						width: 100%;
						svg {
							fill: $flinders-gold;
							width: 50px;
							height: 50px;
						}
						@include media-breakpoint-down(md) {
				           	width: 20%;
				           	line-height: 2rem;
				           	height: 2rem;
				           	svg {
								width: 2rem;
								height: 2rem;
							}
				        }
					}
					.heading_row {
						float: left;
						width: 100%;
						@include media-breakpoint-down(md) {
				           	width: 80%;
				           	font-size: 1rem;
				           	line-height: 1rem;
				           	height: 2rem;
				           	text-align: left;
				           	margin-bottom: 1rem;
				        }
						font-size: 1.2rem;
						font-weight: 700;
					}
					.detail_row {
						float: left;
						width: 100%;
						height: 80px;
						font-size: 0.8rem;
						font-weight: 400;
						margin-bottom: 1rem;
						@include media-breakpoint-down(md) {
				           	display: none;
				        }
					}
					.button_row {
						float: left;
						width: 100%;
						height: 50px;
						.tick_icon {
							display: none;
							margin-bottom: 1rem;
							//TODO: Change to svg class
							svg {
								fill: $flinders-gold;
								width: 50px;
								height: 50px;
							}

						}
					}
					&:after {
						content: "";
						display: table;
						clear: both;
					}
				}
				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}
		}
		//end modal stuff

		.toggle_options {
			cursor: pointer;
			width: 100%;
			padding: 0;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				position: relative; 
				bottom: auto;
			}

			
			.toggle_label_container {
				height: 50px;
				//max-width: 250px;
				width: 80%;
				float: left;
				padding-right: 4px;
				.toggle_label {
					text-align: left;
					width: 100%;
					padding: 0 0.8rem;
					position: relative;

					.toggle_chevron.material-icons {
						font-size: 30px;
						position: absolute;
						right: 10px;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
				
			}
			.toggle_icon {
				//height: 50px;
				//max-width: 250px;
				// z-index: 116;
				width: 20%;
				max-width: 60px;
				line-height: 50px;
				text-align: left;
				float: left;
				padding: 0 0.8rem;
				background-color: $flinders-gold;
				position: relative;

				//For Animations...
				@extend .base_animation_buttons;
				&:after {
					@extend .base_animation_buttons__after;
					background: $dusty-yellow;
	    			border: none;
				}
				&:hover {
					&:after {
			           @extend .base_animation_buttons__hover_after;
			        }
				}
			}
			.toggle_link {
				cursor: pointer;
				display: inline-block;
				line-height: 50px;
				width: 100%;
				text-decoration: none;
				font-size: 1rem;
				font-weight: 400;
				color: $black;
				&:focus,&:hover {
					color: $black;
				}
				&> svg {
					position: absolute;
				    top: 50%;
				    -webkit-transform: translateY(-50%);
				    -ms-transform: translateY(-50%);
				    transform: translateY(-50%);
				    width: 35px;
				    height: 35px;
				    @include media-breakpoint-down(md) {
			            max-width: 35px;
			        }
				}
			}
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}
}