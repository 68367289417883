//Local variables with human-readable names
$even-row-background-color: $gray-light-flinders-display4;
$odd-row-background-color: $gray-light-flinders;
$hover-background-color: $table-hover-light-blue; //Not sure where this blue colour comes from but it is different to the other blues and hence good for this purpose
$border-color: #ffffff;
$table-mark-found-search-result-color: $hover-background-color;
$hover-background-transition: background-color 0.3s linear;

//NOTE (JD)
// - ".table_sort_wrap" is the class that wraps the "content-table" table
// - ".rte" is the class that wraps the RTE component, and any tables within it
// - ".cmp-search-results" is the class that wraps the tables in asset search results component.
.table_sort_wrap, .rte, .cmp-search-results {
  table {
    width: 100%;
    max-width: 100%;
    margin: 0 0 1rem;
    padding: 0;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    border: none;

    //NOTE (JD) - this style was brought into here, but it might be just for the dynatable component.
    th, td {
      @include media-breakpoint-down(sm) {
        white-space: nowrap;
      }
    }
    // Course page table overwrite
    .course_pages & {
      @include media-breakpoint-up(sm) {
        // Styles
        display: inline-table;
      }
    }

    thead {
      th {
        border: 1px solid $gray-light-flinders;
        font-weight: $semibold;
        font-size: $font-size-base;
        padding: 0.75rem;
        vertical-align: middle;
      }
    }
    tbody {
      tr {
        th{
          font-weight: $semibold;
        }
        &:nth-of-type(odd)  {
          td, th {
            background: $odd-row-background-color;
            border: 1px solid $border-color;
            padding: 0.75rem;
            transition: $hover-background-transition;

            // Course page table overwrite
            .course_pages & {
              padding: 2rem;
              vertical-align: middle;
            }
          }
          &:hover {
            td {
              background: $hover-background-color;
              border-top: 1px solid $border-color;
              border-left: 1px solid $border-color;
            }
          }
        }
        &:nth-of-type(even) {
          td, th {
            background: $even-row-background-color;
            border: 1px solid $border-color;
            padding: 0.75rem;
            transition: $hover-background-transition;

            // Course page table overwrite
            .course_pages & {
              padding: 2rem;
              vertical-align: middle;
            }
          }
          &:hover {
            td {
              background: $hover-background-color;
              border-left: 1px solid $border-color;
            }
          }
        }
        &:first-child {
          th {
            border: 1px solid $border-color;
            font-weight: $semibold;
            font-size: $font-size-base;
            padding: 0.75rem;
            vertical-align: middle;
            background: $white;

          }
          &:hover {
            th {
              background: $white;
              border: 1px solid $border-color;
            }
          }
        }
      }
    }
  }
}

mark{
	background: $table-mark-found-search-result-color;
	padding: 0;
	margin: 0;
}
.table_sort_wrap  {
	th {
		a {
			display: block;
			padding-right: 30px;
			position: relative;
			text-decoration: none;
		}
	}
}
.table_sort_wrap {
  overflow-x: auto;
	margin: 0;
  table {
    margin: 0;
    display: table;
  }
	thead {
		th{
			padding: 0;
			&.active {
				background: $table-mark-found-search-result-color;
			}
			a {
				position: relative;
				display: block;
				padding: 0.75rem 1.85rem 0.75rem 0.75rem;
				span {
					color: #777777;
					font-size: 20px;
					position: absolute;
					right: 0.5rem;
					&.default_style {
						color: $black;
					}

				}
				&:focus {
					color: $black;
				}
				&:active {
					color: $black;
				}

			}
		}
	}
}

.dynatable-search {
	text-align: right;
	display: block;
	color: #434343;
	font-size: 0.7rem;
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 1rem;
	input {
		border: 1px solid #eee;
		height: auto;
		line-height: auto;
		font-size: 16px;
		padding: .75rem 1.85rem .75rem .75rem;
		&:focus {
			outline: none;
		}
	}
}
.dynatable-record-count {
	display: block;
	padding: 17px 0;
	color: #777
}
.dynatable-pagination-links {
	float: right;
	margin: 5px;
	padding: 5px;
	font-size: 16px;
}
.dynatable-pagination-links span,
.dynatable-pagination-links li {
	display: inline-block;
}
.dynatable-page-link{
	margin-left: 0 !important;
	display: none;
}
.dynatable-page-break{
	display: none !important;
}
.content-table-pagination-max{
	margin-right: 1rem;
}
.content-table-search-icon{
	text-align: center;
	vertical-align: top;
	i {
		vertical-align: top;
		font-size: 1.9rem;
		height:50px;
		width: 50px;
		background: $flinders-gold;
		line-height: 50px;
	}
}


//light containers
.white_container, .gold_container, .gray_light_container, .secton_color__black
{
	.rte
	{
		table
		{
			color: $primary_color;
			tbody
			{
				tr
				{
					&:first-child
					{
						th
						{
							background: $white;
							font-weight: $semibold;
						}
						a
						{
							color: $link_default;
							&:hover,:focus {
								color: $flinders-navy;
							}
						}
					}
					td a
					{
						color: $link_default;
						&:hover,:focus {
							color: $flinders-navy;
						}
					}
				}
			}
		}
	}
	&.style_table_hide_first_col_bg {
		.rte
		{
			table
			{
				tbody
				{
					tr
					{
						th
						{
							background: $white;
							font-weight: $semibold;
							a
							{
								color: $link_default;
								&:hover,:focus {
									color: $flinders-navy;
								}
							}
						}
					}
				}
			}
		}
	}
}

//dark-side (and image-background with white text) containers...
.dark_blue_container, .black_container, .gray_darker_container, .gray_dark_container, .secton_color__white, .blue_container
{
	.rte
	{
		table
		{
			color: $primary_color;
			tbody
			{
				tr
				{
					&:first-child
					{
						th
						{
							background: $black;
							color: $white;
							font-weight: $semibold;
							a
							{
								color: $gray-light-flinders-display3;
								&:hover,:focus {
									color: $white;
								}
							}
						}
					}
					td a
					{
						color: $link_default;
						&:hover,:focus {
							color: $flinders-navy;
						}
					}
				}
			}
		}
	}
	&.style_table_hide_first_col_bg {
		.rte
		{
			table
			{
				tbody
				{
					tr
					{
						th
						{
							background: $black;
							color: $white;
							font-weight: $semibold;
							a
							{
								color: $gray-light-flinders-display3;
								&:hover,:focus {
									color: $white;
								}
							}
						}
					}
				}
			}
		}
	}
}
