.component_section_featured_component {
    .col-md-4 {
        p:first-child {
            overflow: hidden;
            height: 226px;
            text-align: center;
            vertical-align: middle;
            line-height: 226px;
            position: relative;
            img {
                height: 100%;
                left: 0;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: 100%;
            }
            &.compat-object-fit {
                background-size: cover;
                background-position: center center;
                img {
                    opacity: 0;
                }
            }
        }
       
    }
    
}
.component_section_featured_component {
    h3 {
        height: 3.75rem;
        overflow: hidden;
        margin-bottom:0.5rem;
    }
    p:not(:first-child) {
        height: 4.75rem;
        overflow: hidden;
        line-height: 1.5rem;
    }
}


.container_fullwidth {
    img {
        width: 100%; //This is also overwriting the AEM image component (making it responsive on Full width container)
    }
    div.image {
    margin-bottom: 0px; //Removing 20 px margin from the bottom of image within full width container.
    }
}


@include media-breakpoint-between(xs, sm) {
    .component_section_featured_component {
        .col-md-4 {
            p:first-child {
                max-height: 285px;
            }
        }
    }
    .component_section_featured_component {
        h3 {
            height: auto;
            overflow: inherit;
            margin-bottom: 1.3rem;
        }
        p:not(:first-child) {
            height: auto;
            overflow: inherit;
            margin-bottom: 1rem; 
        }
    }
}
@include media-breakpoint-between(sm, md) {
    .component_section_featured_component {
        .col-md-4 {
            p:first-child {
                max-height: 300px;
            }
        }
    }
}
@include media-breakpoint-between(md, lg) {
    .component_section_featured_component {
        .col-md-4 {
            p:first-child {
                max-height: 165px;
            }
        }
    }
}