//NOTE (JD) - I'm using "& &__icon" in this context to increase the specificity of the selectors so I don't need to use !important
//"&" means the parent so the generated css would look like ".system-links .system-links__icon" when using two.
.system-links {
  padding-bottom: 0.5rem;
  &__header {
    margin-bottom: 5px;
    display: flex;
    width: 100%;
  }
  &__title {
    margin-bottom: 20px;
    font-weight: 300;
  }
  & &__icon {
    text-decoration: none;
    color: inherit;
  }
  & &__configure-button {
    margin-left: auto;
    text-decoration: none;
    color: inherit;
    white-space: nowrap;
    &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }
  }
  &__configure-button-text {
    &--edit {
      .system-links--edit & {
        display: none;
      }
    }
    &--save {
      display: none;
      .system-links--edit & {
        display: inline;
      }
    }
  }
  &__show-hidden {
    margin-top: 17px;
    margin-bottom: 25px;
    text-align: center;
    .system-links--edit &, .aem_author_mode & {
      display: none;
    }
  }
  & &__show-hidden-button {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__items-container {
    margin-right: -0.5rem;
    .new.section {
      //NOTE(JD) - Make the drop area the same width as other items...because I can't figure out how to add the class
      float: left;
      width: 25%;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .system-links--edit &, .aem_author_mode & {
      border: 2px dashed;
      padding: 0.5rem 0 0.5rem 0.5rem;
      margin-right: 0;
      min-height: 50px;
    }
  }
  &__visible-items {
    .system-links--edit &, .aem_author_mode & {
      margin-bottom: 15px;
    }
  }
  &__hidden-items {
    display: none;
    .system-links--edit &, .aem_author_mode & {
      display: block !important;
    }
    &--expanded {
      //special styles for expanded view if required
    }
  }
  &__edit-container-title {
    margin-bottom: 0;
    display: none;
    .system-links--edit &, .aem_author_mode & {
      display: block;
    }
  }
  &__instructional-text {
    font-size: 14px;
    text-align: right;
    display: none;
    .system-links--edit &, .aem_author_mode & {
      display: block;
    }
  }
  &__item {
    float: left;
    width: 25%;
    padding-bottom: 0.5rem;
    opacity: 0;
    height: 115px;
    .aem_author_mode & {
      display: block;
      opacity: 1;
      overflow: hidden;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }
  &__item-link {
    margin-right: 0.5rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    text-align: center;
    background-color: white;
    transition: background-color 0.3s linear;
    border: 1px solid $gray-light-flinders;
    .system-links__items-container--border-none & {
      border: none;
    }
    text-decoration: none;
    justify-content: space-evenly;
    &:hover, &:focus {
      background-color: $gray-light-flinders;
      text-decoration: none;
    }
  }
  &__item-image {
    max-width: 100%;
    height: 50px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
    &-placeholder {
      width: 50px;
      height: 50px;
      background-color: $flinders-gold;
    }
  }
  &__item-title {
    color: black;
    font-size: 14px;
    overflow: hidden;
    width: 100%;
  }
}
