/* content on image, content on background */

.onslider_content {
    position: absolute;
    left: 0;
    width: 100%;
    color: $white;
    line-height: 2rem;
    font-size: 1.125rem;
}

.position_bottom {
    bottom: 2rem;
}
.tparrows {
    z-index: 100;
}
.tp-bullets {
    z-index:  100;
}
.just_oneSlide {
    .tparrows {
        display: none;
        visibility: hidden;
    }
}


.just_oneSlide {
    .tp-bullets {
        display: none;
        visibility: hidden;
    }
}

.position_center {
    top: 50%;
    transform: translateY(-50%);
}

.position_top {
    top: 2rem;
}

.testimonials-wrapper {
    clear: both;
    min-height: 55px;
    position: relative;
    margin-left: 15px;
}

.no_picture {
    position: relative;
    bottom: 0;
    padding-bottom: 2rem;
    padding-top: 3rem;
}

.header_image_component {
    height: 300px;
    overflow: hidden;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}

.header_image_component.transparent_layer {
    position: relative;
    z-index: 1;
    &:after {
        @extend .transparent_layer_after;
    }
}

.rev_multilines_button_styles.rev-btn {
    &.flinders_navy {
        @extend .black_transparent_button;
        padding-top: 0;
        padding-bottom: 0;
        color: $blue !important;
        border-color: $blue;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        font-family: Roboto;
        font-style: italic;
        font-weight: 500;
        font-size: 17px;
        &:hover {
            color: $white !important;
            background-color: $blue;

            &:before {
                color: $white !important;
            }
        }
        &:before {
            line-height: 0.7rem;
            top: 0.9rem;
        }
    }
    &.flinders_gold {
        @extend .yellow_transparent_button;
        padding-top: 0;
        padding-bottom: 0;

        border-color: rgba(255, 211, 0, 1.00);
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        background-color: rgba(0, 0, 0, 0);
        font-family: Roboto;
        font-style: italic;
        font-weight: 500;
        font-size: 17px;
        &:hover {
            color: $blue !important;
            background-color: rgba(255, 211, 0, 1.00);
        }
        &:before {
            line-height: 0.7rem;
            top: 0.9rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .onslider_content {
        bottom: 1.7rem;
        font-size: 0.9rem;
        line-height: 1.4;
        .mb1 {
            margin-bottom: 0.5rem;
        }
    }
    .onslider_content.no_picture {
        bottom: 0;
    }
    .onslider_content.position_center {
        bottom: auto;
    }
    .no_picture {
        .mb1 {
            max-height: inherit;
        }
    }
    /*hack's for content on slider  - remove */

}

.rev_slider_wrapper {
    .rev_slider {
        ul {
            padding: 0;
            margin: 0;
        }
    }
}

.tp-caption.slider-title, .slider-title {
    color: rgba(255, 255, 255, 1.00);
    font-size: 50px;
    line-height: 65px;
    font-weight: 400;
    font-style: normal;
    font-family: $font_family-Oswald;
    text-decoration: none;
    background-color: transparent;
    border: 0 none transparent;
    border-radius: 0;
    &-52px {
        font-size: 64px;
        line-height: 58px;
        font-weight: 700;
        font-family: $font_family-Roboto-Condensed;
        text-decoration: none;
    }
}

.slider {
    overflow: hidden;

    &-button {
        color: rgba(255, 211, 0, 1.00);
        font-size: 17px;
        line-height: 17px;
        font-weight: 400;
        font-style: normal;
        font-family: $font_family-titles;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0);
        border: 2px solid rgba(255, 211, 0, 1.00);
        border-radius: 0;
    }
}

.stop_button_revolution {
    position: absolute;
    right: -28px;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    line-height: 1.1rem;
}

.tp-bullets {
    margin-left: -28px;
}

//Stand Alone Testimonial
.testimonial {
    .testimonials-slider-slide__text{
        height: auto;
        max-height: 320px;
    }
}

// SLICK SLIDER: TESTIMONIALS
.twitter-feed.testimonials,
.testimonials {
    position: relative;
    &-wrapper {
        h3 {
            padding-left: 15px;
            line-height: 2.5rem;
            font-weight: 500;
            padding-right: 131px;
            margin-bottom: 5px;

        }
        @include media-breakpoint-down(sm) {
            .testimonials-arrows {
                visibility: hidden;
            }
            h3 {
                padding: 30px 0 0 15px;
                height: auto;
                line-height: normal;
            }
        }
        .testimonials-arrows {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 10;
            &__angle {
                width: 48px;
                height: 48px;
                padding: 0;
                margin: 5px 0 0 0;
                border: 0;
                background-color: #fff;
                position: relative;
                cursor: pointer;
                @extend .base_animation_buttons;
                &:hover {
                    text-decoration: none;
                    &:after {
                        @extend .base_animation_buttons__hover_after;
                    }
                }
                &:after {
                    @extend .base_animation_buttons__after;
                    background: $yellow;
                }
                i {
                    color: $blue;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                &:hover {
                    i:before, i:after {
                        color: #011c2c;
                    }
                }
                &.slick-disabled {
                    cursor: default;
                    background-color: #eee;
                    i {
                        &:before,
                        &:after {
                            color: #ccc;
                        }
                    }
                    :hover {
                        background-color: #eee;
                        i:before, i:after {
                            color: #ccc;
                        }
                    }
                    &:after {
                        background: none;
                    }
                }
                &_left {

                }
                &_right {

                }
            }
        }
    }
    &-slider {
        &__slide {
        }

        &-slide {
            width: 370px;
            &.testimonials-slider-slide_fixed {
                padding-top: 15px;
                width: 100%;
                .testimonials-slider-slide__quote {
                    margin: 0 15px 15px;
                }
            }

            &__quote {
                position: relative;
                background-color: #ffffff;
                padding: 15px 30px;
                margin: 15px;
                &:after {
                    position: absolute;
                    content: '';
                    bottom: -16px;
                    left: 0;
                    width: 0;
                    height: 0;
                }
            }

            &__quotation {
                color: $yellow;
                font-family: $font_family-titles;
                margin: 0 0 5px 0;
                font-size: 4rem;
                line-height: 2.625rem;
                font-weight: 500;
                padding: 0;
                display: block;
                height: 30px;
            }
            &__text {
                font-family: $font_family-titles;
                font-weight: 500;
                line-height: 2rem;
                color: $black;
            }

            &__user {
                position: relative;
                margin: 16px 0 15px 15px;

            }
            &-user {
                img {
                    max-width: 50%;
                    max-height: 50%;
                    float: left;
                    margin-right: 10px;
                    @extend .rounded-circle;
                }
                &__text {
                    margin: 10px 0 0;
                }
                &__text-top {
                    display: block;
                    font-family: $font_family;
                    font-weight: 600;
                    font-size: $font-size-fln-h6;
                    text-transform: uppercase;
                    margin-bottom: $btn-padding-y;
                }
                &__text-bottom {
                    display: block;
                    font-family: $font_family;
                    font-weight: 500;
                    font-size: $font-size-xs;
                    text-transform: uppercase;
                }
            }
            &-image_square {
                width: 50%;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                @include border-radius(50%);
                margin-right: 3%;
                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
                @media only screen and (min-width: 769px){
                    .one_slide & {
                        width: 20%;
                    }
                    .two_slides &{
                    width: 30%;
                    }
                }
                &.testimonials-slider-full-width {
                    width: 20%;
                }
            }
        }
    }
}

.slick-dots {
    bottom: -45px;
    li {
        margin: 0;
        button::before {
            font-size: 0.5rem;
        }
    }

}

.twitter-feed {
    .testimonials {
        &-slider {
            &-slide {
                width: 370px;

                &__quotation {
                    color: #3fbdee;
                    font-size: 1.5rem;
                    // height: 30px;
                }

                &__text {
                    font-family: $font_family;
                    font-weight: 400;
                    line-height: 1.5rem;
                    font-size: 0.875rem;

                    height: auto;
                    margin-bottom: auto;

                    img {
                        max-width: 90px;
                        max-height: 70px;
                        margin: 0 0 2rem 1.5rem;
                    }
                }
                &__quote {
                    min-height: 145px;
                }
                &__link {
                    color: #3fbdee;
                }
                &__time {
                    position: absolute;
                    top: 20px;
                    right: 30px;
                    font-size: $font-size-fln-h6;
                    color: #b2b2b2;
                }
            }
        }
    }
}

.tp_img_settings {
    img {
        max-width: 1000px !important;
        max-height: 300px;
    }
}

@include media-breakpoint-between(md, lg) {
    .tp_img_settings {
        img {
            max-width: 1000px !important;
            max-height: 200px;
        }
    }
    .testimonials-slider-slide-image_square.testimonials-slider-full-width {
        width: 20%;
    }
}

@include media-breakpoint-between(sm, md) {
    .slick-dots {
        li {
            margin: 0;
        }
    }
    .testimonials-arrows {
        right: 0;
    }
    .tp_img_settings {
        img {
            max-width: 600px !important;
            max-height: 200px
        }

    }

    .testimonials-slider-slide-image_square.testimonials-slider-full-width {
        width: 25%;
    }
}

@include media-breakpoint-down(sm) {
    .slick-dots {
        li {
            margin: 0;
        }
    }
    .testimonials-arrows {
        right: 0;
    }
    .testimonials-slider-slide__quote,
    .twitter-feed.testimonials-slider-slide__quote {
        margin: 15px 3px;
        padding: 15px 20px;
    }
    .tp_img_settings {
        img {
            max-width: 330px !important;
            max-height: 130px;
        }
    }

    .testimonials-slider-slide-image_square.testimonials-slider-full-width {
        width: 40%;
    }
}

@include media-breakpoint-down(xs) {
    .testimonials-slider-slide-image_square.testimonials-slider-full-width {
        width: 40%;
    }
}
.youtube-overlay-custom>a:link{
    display: block;
    background-color: transparent;
    &:hover {
        outline: 0;
        &:before {
            opacity: .8;
        }
    }
    &:before  {
        display: block;
        position: absolute;
        content: '';
        background-image: url(../resources/images/youtube-play.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        margin-left: -40px;
        margin-top: -40px;
        opacity: .5;
        -webkit-transition: opacity .3s ease;
        -moz-transition: opacity .3s ease;
        transition: opacity .3s ease;
    }
    &:after {
        display:none;
    }
}

.testimonials-slider {

    &-slide {

        &__quote {
            position: relative;
        }

        &__text {
            height: auto;
            height: 320px;
            margin-bottom: 48px;
            overflow: hidden;
            p:last-child { margin-bottom: 0; }
        }

        &__action-pane {
            background: #fff;
            font-size: 0;
            text-align: right;

            position: absolute;
            right: 15px;
            bottom: 15px;
            left: 15px;
        }
    }
}

.hidden {
    display: none !important;
}

// Vendor styles
// Ref: https://github.com/jjenzz/jquery.ellipsis
.ellip {
    display: block;
    height: 100%;
}

.ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
}

.ellip,
.ellip-line {
    position: relative;
    overflow: hidden;
}
