.step-by-step {
  padding-top: 25px;
  .step {
    padding-top:0px !important;
    margin-left: 0px !important;
    &:nth-child(1n) {
      .card-item {
        border-left: 1px solid $black;
      }

    }

    &:last-child {
      .card-item {
        border-left: none;
      }

    }
    &-step-item {
      /*padding-top:25px;*/
      margin-left: 25px;

      &-card {

        &__header {
          background-color: #fff;
          position: relative;
          padding: 0;
        }

        &__block {
          min-height: 60px;
          background-color: transparent;
          padding: 0;

          .circular-counter {
            position: absolute;
            left: -8px;
            top: -9px;
          }

          svg.circular-counter {
            width: 75px;
            @include media-breakpoint-down(sm) {
              width: 60px;
            }
          }

          .section_component_default {
            padding: 0;
          }
        }
      }
    }
    .section_component_default {
      padding: 0;
    }
  }
}
