
//NOTE(Christof): in-page-nav is the div AEM automatically creates for us, while in_page_nav is the child div we added
//				  the outer one stays in place, while in_page_nav becomes sticky when scrolled past.
//TODO(Christof): rename this to be less confusing?
div.in-page-nav.static{
	div.in_page_nav{
		position:fixed;
		z-index: 90;
		display:flex;

		div.width_extension{
			display:block;
			width:100vw;
			height:100%;
			background-color:$white;
			position:absolute;
			z-index:-1;
		}
	}
}

div.in_page_nav{
	background-color:$white;
	display:flex;
	align-items: end;
	align-items: center;

	div.width_extension{
		display:none;
	}

	@include media-breakpoint-up(lg) {
		&.desktop_hidden_until_stuck{
			display:none;
		}

		&.desktop_on_top{
			z-index: 120 !important; //NOTE(Christof): because of specificity rules and I guess how sass compiles the media rules, this is only guranteed to be applied with !important :(
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction:row;
		align-items: center;
		&.mobile_hidden_until_stuck{
			display:none;
		}

		&.mobile_on_top{
			z-index: 120!important; //NOTE(Christof): because of specificity rules and I guess how sass compiles the media rules, this is only guranteed to be applied with !important :(
		}
	}


	a.link{
	 	color:$blue;
 	 	text-decoration:none;
 	 	font-family: $font_family-circular-medium;
 	}

 	&.can_scroll_left{
 		.scroll.left{
 			display:unset;
 		}
 	}

 	&.can_scroll_right{
 		.scroll.right{
 			display:unset;
 		}
 	}

	div.mobile_dropdown{
		display:none;
		@include media-breakpoint-down(md) {
			display:unset !important; //NOTE(Christof): because of specificity rules and I guess how sass compiles the media rules, this is only guranteed to be applied with !important :(
		}
		height: 3.5em;
		flex-grow: 1;

		div.selection{
			display:flex;
			flex-direction:row;
			height:100%;
			align-items:center;

			a.link{
				flex-grow:1;
				display: flex;
				background-color:$white;
			}
			.fa-chevron-up{
				display: none;
			}
		}

		.options{
			display:none;
		}


		&.expanded{
			z-index: 10;


			.options{
				display:flex;
				flex-direction: column;
				background-color:white;
				max-height: 35vh;
				overflow-y: auto;
			}
			div.selection{
				
				.fa-chevron-up{
					display: unset;
				}

				.fa-chevron-down{
					display: none;
				}
			}
		}


		a.link{
			border-bottom:none;
			padding: 0.5em 0.5em;

			&.selected{
				background-color:$yellow;
			}

			
		}
	}

	.scroll{
		width: 30px;
		position:absolute;
		height:100%;
		top:0;
		display:none;

		&.left{
			background: linear-gradient(90deg, rgba(200, 200, 200, 0.5), rgba(255, 255, 255, 0.5));
			left:0;
		}

		&.right{
			background: linear-gradient(90deg,  rgba(255, 255, 255, 0.5), rgba(200, 200, 200, 0.5)); 
			left:-30px;
		}
	}

	.links
	{

		@include media-breakpoint-down(md) {
			display:none;
		}

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin-right:auto;
		overflow:hidden;
		height: 100%;
		
		a.link{
			min-height: 3em;

	 	 	padding: 0 1em 0.1em 1em;
	 	 	border-bottom: 0.4em solid $white;
	 	 	border-top: 0.4em solid $white;
			height: 100%;
			display: flex;
			align-items: center;
	 	 	white-space: nowrap;
	 	 	background-color: white;

	 	 	&:hover{
	 	 		border-bottom-color: $dusty-yellow !important;
	 	 	}

			&.scrolled_to{
				border-bottom-color:$yellow;
			}
		}
	}

	div.mobile_dropdown{
		display:none;
	}

	div.buttons{
		display:flex;
		justify-content: end;
		position: relative;



		div.cta-button{
			margin-top:5px;
			margin-left: 5px;
			white-space: nowrap;
		}

		p{
			margin-top:0;
			margin-bottom:0;
		}

		.component_section_d1{
			padding-top:0;
			padding-bottom:0;
		}

		.newpar{
			min-width:10em;
			flex-basis: 100%;
		}

	}
}