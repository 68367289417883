$gdpr-container-height: 410px;
$gdpr-container-height-small: 560px;
$gdpr-container-height-desktop: 350px;
$gdpr-background-colour: $black;
$gdpr-text-colour: $white;
$gdpr-divider-height: 8px;
$gdpr-divider-colour: $flinders-gold;
$gdpr-title-text-colour: $flinders-gold;

//This class is added to the container that holds all content
.gdpr-shown {
	padding-top: calc(#{$gdpr-container-height-desktop} + #{$gdpr-divider-height});
	@include media-breakpoint-down(md) {
		padding-top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height});
	}
  @include media-breakpoint-down(sm) {
    padding-top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height});
  }

	.global_header {
		top: calc(#{$gdpr-container-height-desktop} + #{$gdpr-divider-height});
		@include media-breakpoint-down(md) {
			top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height}) !important;
		}
    @include media-breakpoint-down(sm) {
      top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height}) !important;
    }
	}
	.sub_site_header {
		.global_header {
			top: calc(#{$gdpr-container-height-desktop} + #{$gdpr-divider-height}) !important;
			@include media-breakpoint-down(md) {
				top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height}) !important;
			}
      @include media-breakpoint-down(sm) {
        top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height}) !important;
      }
		}
	}
	.sectionfixed .head_inner_second_line {
		top: calc(#{$gdpr-container-height-desktop} + #{$gdpr-divider-height});
		@include media-breakpoint-down(md) {
			top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height});
		}
    @include media-breakpoint-down(sm) {
      top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height});
    }

	}
	.breadcrumb-tail-panel {
		@include media-breakpoint-down(md) {
			top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height} + #{$header_height});
		}
    @include media-breakpoint-down(sm) {
      top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height} + #{$header_height});
    }
	}
	.head_inner_horisontal .open.quicklink_section_mv .dropdown-menu, .top_main_navigation_wrap {
		@include media-breakpoint-down(md) {
			top: calc(#{$gdpr-container-height} + #{$gdpr-divider-height});
		}
    @include media-breakpoint-down(sm) {
      top: calc(#{$gdpr-container-height-small} + #{$gdpr-divider-height});
    }
	}
}

.gdpr-notification {
	color: $gdpr-text-colour;
	z-index: $z-index-gdpr-notification;
	position: fixed;
	top: 0;
	width: 100%;

	a {
		color: $gdpr-text-colour;
		&:hover, &:focus {
			color: $gdpr-text-colour;
		}
	}

	&__container {
		background-color: $gdpr-background-colour;
		height: $gdpr-container-height-desktop;
		@include media-breakpoint-down(md) {
			height: $gdpr-container-height;
		}
    @include media-breakpoint-down(sm) {
      height: $gdpr-container-height-small;
    }
		z-index: auto;
		display: flex;
		align-items: center;
	}
	&__content {
		@include make-container();
		@include make-container-max-widths();
		padding-top: 1rem;
		padding-bottom: 1rem;
      p {
        margin-top: 1rem;
      }
	}

	&__title {
		color: $gdpr-title-text-colour;
	}

	&__description {
	}

	&__cta {
		&.cta-button {
			.classic_yellow_button_transform {
				display: inline-block;
				@include media-breakpoint-down(md) {
					display: block;
				}
        @include media-breakpoint-down(sm) {
          display: block;
        }
			}
		}
	}

	&__divider {
		height: $gdpr-divider-height;
		background-color: $gdpr-divider-colour;
	}

}
