.list-core {

  ul.disc-style {
    list-style: disc;
  }

  ul.no-style {
    list-style: none;
  }

  ul.list-group {
    list-style: none;
  }

  .cmp-list {
    a.no-decoration {
      text-decoration: none;
    }
    a {
      color: #555;
    }
    &__item:first-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    &__item:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}
