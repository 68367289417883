/* iframe styles */

.iframe-ratio {
    position: relative;
    display: block;
    overflow: hidden;
    &__none{ /*  default size */
        padding-bottom:5%;
    }
    &__square{ /* Square ratio 1:1 */
        padding-bottom:100%;
    }
    &__traditional{ /* Square ratio 4:3 */
        padding-bottom:75%;
    }
    &__classic{ /* Classic ratio 3:2 */
        padding-bottom:66.67%;
    }
    &__cinemascope { /* Cinemascope ratio 21:9 */
        padding-bottom:42.86%;
    }
    &__widescreen{ /* Widescreen ratio 16:9 */
        padding-bottom:56.25%;
    }
    &__largeslider{ /* Large slider  */
        padding-bottom:750px;
        @include media-breakpoint-between(sm, md) {
            padding-bottom: 550px; 
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 500px; 
        }
    }
    &__smallslider{ /* Small slider */
        padding-bottom:500px;
        @include media-breakpoint-between(sm, md) {
            padding-bottom: 400px; 
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 400px; 
        }
        @include media-breakpoint-down(xs) {
            padding-bottom: 350px; 
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 1px; //ios 11 & 12 continuous resize fix
        min-height: 100%; //ios 11 & 12 continuous resize fix
        width: 1px; //ios 11 & 12 continuous resize fix
        min-width: 100%; //ios 11 & 12 continuous resize fix
        border: 0;
    }
    &__fullscreen{ /* Widescreen ratio 16:9 */
        //padding-bottom:56.25%;
        iframe {
            position: fixed;
            background: #000;
            border: none;
            top: 0; right: 0;
            bottom: 0; left: 0;
            width: 100%;
            height: 100%;
            z-index: $z-index-iframe-full-screen;
        }
    }
}

.iframe__fulscreen-placeholder {
    Padding:50px;
}



