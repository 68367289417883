button {
    &:focus {
        outline-offset: 0;
        outline: none;
        text-decoration: none;
    }
}

/********buttons***********/
.base_animation_buttons__after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -2;
    transition: transform .1s;

}
.base_animation_buttons__hover_after {
     transform: translateY(-100%);
     transition: transform .3s;
}
.base_animation_buttons {
    font:  $semibold $font-size-base 'Open Sans', sans-serif;
    line-height: 1.5rem;
    padding: 0.8rem 1rem;
    text-decoration: none;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    position: relative;
    transition: color .3s;
    display: inline-block;
}
.icons_in_buttons {
    padding-left: 1rem;
    vertical-align: middle;
    font-size: $font-size-lg;
    position: relative;
    top: -1px;
    z-index:111;
    right: 0;
    &.fa-chevron-right {
       font-size: $font-size-xs;
    }
}

.button_position_right_icon {
    height: 100%;
    line-height: 100%;
    position: absolute;
    right: 1rem;
    text-align: center;
    top: 0;
    width: 2rem;
    i {
        font-size: $font-size-sm;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
}
.scroll-top {
    z-index: 1049;
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 40px;
    height: 40px;
    @extend .base_animation_buttons;
    border: 1px solid $blue;
    background: rgba(255, 255, 255, 0.25);
    padding: 0;
    text-align: center;
    margin: 0;
    i {
        font-size: 2rem;
        line-height: 40px;
    }
    &:hover {
        text-decoration: none;
        border: 1px solid $blue;
        &:after {
           @extend .base_animation_buttons__hover_after;
           color: $white !important;
        }
        i {
           color: $white !important;
        }
    }
    &:after {
        @extend .base_animation_buttons__after;
        background: $blue;
        border: 1px solid $blue;
        color: $white !important;
    }
}
.small_button_param {
    font-size: $font-size-sm;
    padding: 0.19rem 1rem;
    line-height: 1.5rem;
}
.fixed_width {
    width: 17.875rem;
    max-width: 100%;
}
.default_indents {
    padding: 0;
    margin: 0;
}
.before_after_default {
    position: absolute;
    content: '';
}
.style_round_button{
  border-radius: 50px;
}
.white_transparent_button {
    position: relative;
    background: $white;
    font: $semibold $font-size-base 'Open Sans', sans-serif;
    line-height: 2.1rem;
    font-style: italic;
    border: 1px solid $black;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    transition: color .3s;
    text-align: left;
    color: #000 !important;
    padding-right: 50px;
    text-decoration: none;
    display: inline-block;
    &:hover {
        border: 1px solid $yellow;
        color: $black !important;
        text-decoration: none;
        &:after {
            transform: translateY(-100%);
            transition: transform .3s;
        }
    }
    &:after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: $yellow;
        content: "";
        z-index: -2;
        transition: transform .3s;
    }
    &:before {
        @extend .fa;
        content: "";
        right: 12px;
        top: 0.4rem;
        position: absolute;
        font-size: $font-size-lg;
    }
}
.yellow_button {
    @extend .white_transparent_button;
    line-height: 2.3rem;
    background: $yellow;
    color: $black !important;
    border: none;
    display: inline-block;
    padding: 0 50px 0 1rem;
    &:hover {
        border: none;
        color: $black !important;
        text-decoration: none;
        &:after {
            transform: translateY(-100%);
            transition: transform .3s;
        }
    }
    &:after {
        background: $dark-yellow;
    }
    &:before {
        color: $black !important;
        top: 8px;
    }
}
.yellow_transparent_button {
    @extend .white_transparent_button;
    background: none;
    border: 2px solid $yellow;
    color: $yellow !important;
    display: inline-block;
    &:hover {
        border: 2px solid $yellow;
        color: $black !important;
        text-decoration: none;
        &:after {
            transform: translateY(-100%);
            transition: transform .3s;
        }
        &:before {
            color: $black !important;
        }
    }
    &:active,
    &:focus {
        outline: none;
        text-decoration: none;
    }
    &:after {
        background: $yellow;
    }
    &:before {
        color: $yellow !important;
    }
}

.black_transparent_button {
    @extend .white_transparent_button;
    background: none;
    border: 2px solid $black;
    color: $black !important;
    display: inline-block;
    &:hover {
        border: 2px solid $blue;
        color: $yellow !important;
        text-decoration: none;
        &:after {
            transform: translateY(-100%);
            transition: transform .3s;
        }
        &:before {
            color: $yellow !important;
        }
    }
    &:after {
        background: $blue !important;
    }
    &:before {
        color: $black !important;
    }

    &--no-icon {
        font-style: normal;
        padding-left: 16px;
        padding-right: 16px;
        text-align: center;
        text-transform: uppercase;

        &:before { display: none; }
    }
}

.classic_yellow_button {
    background: $yellow;
    border: 1px solid $yellow;
    display: inline-block;
    color: $black !important;
    text-transform: uppercase;
    text-align: center;
    padding: 0 20px;
    height: 3rem;
    line-height: 2.9rem;
    font-weight: $semibold;
    font-size: 1rem;
    text-decoration: none;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    .material-icons {
        color: $black !important;
        font-size: 1.3rem;
        vertical-align: text-top;
    }
    &:hover,
    &:focus {
        background: $dusty-yellow;
        border: 1px solid $dusty-yellow;
        color: $black !important;
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
        outline: none;
    }

}

.classic_white_button_blue_border {
    @extend .classic_white_button_navy_border
}
.classic_white_button {
    @extend .classic_yellow_button;
    border: 1px solid $white;
    color: $white !important;
    background: none;
    white-space: nowrap;
    .material-icons {
        color: $white !important;
    }
    &:hover,
    &:focus {
        background: $white;
        border: 1px solid $white;
        color: $black-display1 !important;
        .material-icons {
            color: $black-display1 !important;
        }
    }
}
.small_btn_parameters {
    font-size: 0.9rem;
    height: 1.9rem;
    line-height: 1.7rem;
    padding: 0 1rem;
}

.classic_yellow_button_d1 {
    @extend .classic_yellow_button;
    @extend .small_btn_parameters;
}
.icon_small {
     vertical-align: middle;
     font-size: 0.625rem;
}
.classic_yellow_button_small {
    @extend .classic_yellow_button;
    @extend .small_btn_parameters;
    i {
        margin-left: 0.5rem;
        &.fa-chevron-right {
            @extend .icon_small;
        }
    }

}
.classic_white_button_small {
    @extend .classic_white_button;
    @extend .small_btn_parameters;
    &:hover,
    &:focus {
        i {
            color: $black !important;
        }
    }
    i {
        margin-left: 0.5rem;
        &.fa-chevron-right {
            vertical-align: middle;
            font-size: 0.625rem;
        }

    }
}

.classic_white_button_blue_border_small {
    @extend .classic_white_button_blue_border;
    font-size: 0.9rem;
    height: 1.9rem;
    line-height: 1.7rem;
    padding: 0 15px;
    &:hover,
    &:focus {
        .material-icons {
            color: $white !important;
        }
    }
    i {
        margin-left: 0.5rem;
        &.fa-chevron-right {
            vertical-align: middle;
            font-size: 0.625rem;
        }
    }
}
.classic_yellow_button_transform {
    @extend .base_animation_buttons;
    background: $yellow;
    border: none;
    color: $black !important;
    i{
      color: $black !important;
    }
    &:hover {
        text-decoration: none;
        border: none;
        i{
          color: $black !important;
        }
        &:after {
           @extend .base_animation_buttons__hover_after;
        }
    }
    &:focus {
        color: $black !important;
        text-decoration: none;
    }
    &:after {
        @extend .base_animation_buttons__after;
        background: $dusty-yellow;
        border: none;
    }
    &__small {
        @extend .base_animation_buttons;
        @extend .small_button_param;
        background: $yellow;
        border: none;
        color: $black !important;
        &:hover {
            text-decoration: none;
            border: none;
            &:after {
              @extend .base_animation_buttons__hover_after;
            }
        }
        &:after {
            @extend .base_animation_buttons__after;
            background: $dusty-yellow;
            border: 1px solid $dusty-yellow;
        }
        span:not(.inline-button-text) {
            i {
                font-size: $font-size-sm;
                &.fa-chevron-right {
                   font-size: $font-size-xs;
                }
            }
        }
        &.fixed_width {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                i {
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
        &.btn__icons {
            white-space: inherit;
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                i {
                    @extend .icons_in_buttons;
                    padding-left: 0.5rem;
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
        }
        &.d-block {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                }

             }
        }
    }
    &.d-block {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
         &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
                padding-right: 1rem;
            }

         }
    }
    &.fixed_width {
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
        &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
                padding-right: 1rem;
            }

         }
    }
    &.btn__icons {
        white-space: inherit;
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
    }
}
// Start CTA white with navy text
.classic_white_button_navy_text {
  @extend .base_animation_buttons;
  background: $white;
  border: none;
  color: $flinders-navy !important;
  i{
    color: $flinders-navy !important;
  }
  &:hover {
    text-decoration: none;
    border: none;
    i{
      color: $flinders-navy !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $flinders-navy !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $gray_hover;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $white;
    border: none;
    color: $flinders-navy !important;
    &:hover {
      text-decoration: none;
      border: none;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $gray_hover;
      border: 1px solid $gray_hover;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA white wth navy text

//Start CTA white with blue text

.classic_white_button_blue_text {
  @extend .base_animation_buttons;
  background: $white;
  border: none;
  color: $light_blue !important;
  i{
    color: $light_blue !important;
  }
  &:hover {
    text-decoration: none;
    border: none;
    i{
      color: $light_blue !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $light_blue !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $gray_hover;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $white;
    border: none;
    color: $light_blue !important;
    &:hover {
      text-decoration: none;
      border: none;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $gray_hover;
      border: 1px solid $gray_hover;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA button white with blue text

//Start CTA summer red with white text
.classic_summer_red_button_white_text {
  @extend .base_animation_buttons;
  background: $summer_red;
  border: none;
  color: $white !important;
  i{
    color: $white !important;
  }
  &:hover {
    text-decoration: none;
    border: none;
    i{
      color: $white !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $white !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $summer_red_hover;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $summer_red;
    border: none;
    color: $white !important;
    &:hover {
      text-decoration: none;
      border: none;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $summer_red_hover;
      border: 1px solid $summer_red_hover;
    }
    i{
      color: $white !important;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA summer red with white text

//Start CTA summer red with white hover
.classic_summer_red_button_white_hover {
  @extend .base_animation_buttons;
  background: $summer_red;
  border: none;
  color: $white !important;
  i{
    color: $white !important;
  }
  &:hover {
    text-decoration: none;
    color: $summer_red !important;
    border: none;
    i{
      color: $summer_red !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $white !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $white;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $summer_red;
    border: none;
    color: $white !important;
    &:hover {
      text-decoration: none;
      border: none;
      color: $summer_red !important;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $white;
      border: 1px solid $white;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA summer red with white hover

//Start CTA midnight blue with white text
.classic_midnight_blue_button_white_text {
  @extend .base_animation_buttons;
  background: $midnight_blue;
  border: none;
  color: $white !important;
  i{
    color: $white !important;
  }
  &:hover {
    text-decoration: none;
    border: none;
    i{
      color: $white !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $white !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $ocean_blue;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $midnight_blue;
    border: none;
    color: $white !important;
    &:hover {
      text-decoration: none;
      border: none;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $ocean_blue;
      border: 1px solid $ocean_blue;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA midnight blue with white text

//Start CTA stone grey with navy text
.classic_stone_grey_button_navy_text {
  @extend .base_animation_buttons;
  background: $stone_grey;
  border: none;
  color: $blue !important;
  i{
    color: $blue !important;
  }
  &:hover {
    text-decoration: none;
    color: $white !important;
    border: none;
    i{
      color: $white !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $blue;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $blue;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $stone_grey;
    border: none;
    color: $blue !important;
    &:hover {
      text-decoration: none;
      border: none;
      color: $white !important;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $blue;
      border: 1px solid $blue;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA stone grey with navy text

//Start CTA navy with white text
.classic_navy_button_white_text {
  @extend .base_animation_buttons;
  background: $blue;
  border: none;
  color: $white !important;
  i{
    color: $white !important;
  }
  &:hover {
    text-decoration: none;
    color: $blue !important;
    border: none;
    i{
      color: $blue !important;
    }
    &:after {
      @extend .base_animation_buttons__hover_after;
    }
  }
  &:focus {
    color: $stone-grey !important;
    text-decoration: none;
  }
  &:after {
    @extend .base_animation_buttons__after;
    background: $stone-grey;
    border: none;
  }
  &__small {
    @extend .base_animation_buttons;
    @extend .small_button_param;
    background: $blue;
    border: none;
    color: $white !important;
    &:hover {
      text-decoration: none;
      border: none;
      color: $blue !important;
      &:after {
        @extend .base_animation_buttons__hover_after;
      }
    }
    &:after {
      @extend .base_animation_buttons__after;
      background: $stone-grey;
      border: 1px solid $stone-grey;
    }
    span:not(.inline-button-text) {
      i {
        font-size: $font-size-sm;
        &.fa-chevron-right {
          font-size: $font-size-xs;
        }
      }
    }
    &.fixed_width {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
          margin-right: 0;
        }

      }
    }
    &.btn__icons {
      white-space: inherit;
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
        i {
          @extend .icons_in_buttons;
          padding-left: 0.5rem;
          &.fa-chevron-right {
            font-size: $font-size-xs;
          }
        }
      }
    }
    &.d-block {
      span:not(.inline-button-text) {
        @extend .button_position_right_icon;
      }
      &.btn__icons {
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
          padding-left: 0;
          padding-right: 1rem;
        }

      }
    }
  }
  &.d-block {
    white-space: inherit;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.fixed_width {
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
    &.btn__icons {
      padding-right: 2.5rem;
      span:not(.inline-button-text) {
        padding-left: 0;
        padding-right: 1rem;
      }

    }
  }
  &.btn__icons {
    white-space: inherit;
    padding-right: 2.5rem;
    span:not(.inline-button-text) {
      @extend .button_position_right_icon;
    }
  }
}
// End CTA navy with white text

.classic_white_button_navy_border {
    @extend .base_animation_buttons;
    background: none;
    color: $blue !important;
    border: 1px solid $blue;
    i{
      color: $blue !important;
    }
    &:hover {
        border: 1px solid $blue;
        color: $white !important;
        text-decoration: none;
        i{
        color: $white !important;
        }
        &:focus {
            color: $white !important;
            text-decoration: none;
        }
        &:after {
           @extend .base_animation_buttons__hover_after;
        }
    }
    &:focus {
        color: $blue !important;
        text-decoration: none;
    }
    &:after {
        @extend .base_animation_buttons__after;
        border: 1px solid $blue;
        background: $blue;
        color: $white !important;
    }
    &__small {
        @extend .base_animation_buttons;
        @extend .small_button_param;
        background: none;
        color: $blue !important;
        border: 1px solid $blue;
        &:hover {
            text-decoration: none;
            border: 1px solid $blue;
            color: $white !important;
            text-decoration: none;
            &:after {
               @extend .base_animation_buttons__hover_after;
            }
        }
        &:after {
            @extend .base_animation_buttons__after;
            border: 1px solid $blue;
            background: $blue;
            color: $white !important;
        }
        &.fixed_width {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                }

             }
        }
        &.btn__icons {
            white-space: inherit;
             padding-right: 2.5rem;
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                 i {
                    @extend .icons_in_buttons;
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
        }
        &.d-block {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
    }
    &.btn__icons {
        white-space: inherit;
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
    }
    &.d-block {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
         &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
            }

         }
    }
    &.fixed_width {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
        &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
                padding-right: 1rem;
            }

         }
    }
}
.classic_transparent_button_yellow_border {
    @extend .base_animation_buttons;
    background: none;
    color: $yellow !important;
    border: 1px solid $yellow;
    i{
      color: $yellow !important;
    }
    &:focus {
        color: $blue !important;
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
        border: 1px solid $yellow;
        color: $blue !important;
        &:after {
           @extend .base_animation_buttons__hover_after;
        }
    }
    &:after {
        @extend .base_animation_buttons__after;
        border: 1px solid $yellow;
        background: $yellow;
        color: $blue !important;
    }
    &__small {
        @extend .base_animation_buttons;
        @extend .small_button_param;
        background: none;
        color: $yellow !important;
        border: 1px solid $yellow;
        &:hover {
            text-decoration: none;
            color: $blue !important;
            &:after {
               @extend .base_animation_buttons__hover_after;
            }
        }
        &:after {
            @extend .base_animation_buttons__after;
            background: $yellow;
            border: 1px solid $yellow;
        }
        &.fixed_width {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                i {
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
        &.btn__icons {
            white-space: inherit;
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                i {
                    @extend .icons_in_buttons;
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
        }
        &.d-block {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
    }
    &.btn__icons {
        white-space: inherit;
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
        &:hover {
            i {
                color: $blue !important;
            }
        }
    }
    &.d-block {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
         &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
                padding-right: 1rem;
            }
         }
    }
    &.fixed_width {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
        &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
                padding-right: 1rem;
            }

         }
    }

}

.classic_transparent_button_white_border {
    @extend .base_animation_buttons;
    background: none;
    color: $white !important;
    border: 1px solid $white;
    i {
        color: $white !important;
    }
    &:hover {
        text-decoration: none;
        border: 1px solid $white;
         color: $blue !important;
        i {
            color: $blue !important;
            &.fa-chevron-right{
                color: $blue !important;
            }
        }
        &:after {
           @extend .base_animation_buttons__hover_after;
        }
    }
    &:after {
        @extend .base_animation_buttons__after;
        border: 1px solid $white;
        color: $blue !important;
        background: $white;
        i {
            color: $blue !important;
            border: 1px solid green;
        }
    }
    &:focus {
        text-decoration: none;
    }
    &__small {
        @extend .base_animation_buttons;
        @extend .small_button_param;
        background: none;
        color: $white !important;
        border: 1px solid $white;
        i {
            color: $white !important;
        }
        &:hover {
            text-decoration: none;
            color: $blue !important;
            i {
              color: $blue !important;
              &.fa-chevron-right{
                color: $blue !important;
            }
          }
            &:after {
               @extend .base_animation_buttons__hover_after;
            }
        }
        &:after {
            @extend .base_animation_buttons__after;
            border: 1px solid $white;
            color: $blue !important;
            background: $white;
            i {
                color: $blue !important;
                border: 1px solid green;
            }
        }


        &.fixed_width {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                i {
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
        &.btn__icons {
            white-space: inherit;
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
                 i {
                    @extend .icons_in_buttons;
                    padding-left: 0.5rem;
                    &.fa-chevron-right {
                       font-size: $font-size-xs;
                    }
                }
            }
        }
        &.d-block {
            span:not(.inline-button-text) {
                @extend .button_position_right_icon;
            }
            &.btn__icons {
                padding-right: 2.5rem;
                span:not(.inline-button-text) {
                    padding-left: 0;
                    padding-right: 1rem;
                    margin-right: 0;
                }

             }
        }
    }
    &.d-block {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
         &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
            }
            &:hover {
                i {
                    color: $black !important;
                }
            }
         }
    }
    &.fixed_width {
        white-space: inherit;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
        }
        &.btn__icons {
            padding-right: 2.5rem;
            span:not(.inline-button-text) {
                padding-left: 0;
            }

         }
    }

    &.btn__icons {
        white-space: inherit;
        padding-right: 2.5rem;
        span:not(.inline-button-text) {
            @extend .button_position_right_icon;
            padding-right: 0;
        }
    }
}
.btn__icons {
    span:not(.inline-button-text) {
        i {
            &.material-icons {
                font-size: 1.2rem;
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .white_transparent_button {
        line-height: 1.8rem;
    }
    .yellow_transparent_button {
        &:before {

            line-height: 1.2rem;
            top: 4px;
            color: #ffd300 !important;
        }
    }


}

.cta-button {
	p a, p button{
	    text-align: left;
	}
    &.align_text_center {
        p a, p button {
            text-align: center;
        }
    }
	button.d-block {
		width: 100%;
	}
    .text_align_center { //overwrite btn text alignment - changing align_text_center affects the fast facts component
            text-align: center !important;
    }
}


