     /**
     * Search box
     */
    .sj-search-input-holder-outer {
      padding: 0.9em 0px;
      position: relative;
      height: 66px;
      box-sizing: border-box;
    }
    .sj-search-input-holder-inner {
      width: 100%;
      position: absolute;
    }
    .sj-search-bar-input-common {
      width: 100%;
      font-size: 20px;
      padding: 0.4em;
      outline: none;
      letter-spacing: 0.6px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      line-height: 24px;
      text-rendering: optimizeLegibility;
      border-radius: 0;
    }
    .sj-search-bar-completion {
      border: 1px solid #d9d9d9;
      color: #bebebe;
    }
    .sj-search-bar-input {
      border: 1px solid transparent;
      position: absolute;
      background: transparent;
      color: #666;
      top: 0px;
      left: 0px;
    }
    .sj-search-icon {
      display: none;
    }
    /**
     * Autocomplete override
     */
    .sj-result-summary-autocomplete-override {
      display: block;
      padding-top: 16px;
      font-size: 1.2em;
    }
    .sj-result-summary-autocomplete-override>a {
      color: #1a0dab;
    }
    /**
     * Results
     */
    .sj-result-summary {
      padding-bottom: 1.5em;
      font-size: 16px;
      color: #aaa;
    }
    .sj-result {
      clear: both;
    }
    .sj-result-list > * {
      margin-top: 1.5em;
    }
    .sj-result-list > :first-child {
      margin-top: 0;
    }
    .sj-result-title {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sj-result-title a {
      text-decoration: none;
      font-weight: 400;
      font-size: 20px;
      color: #333;
      line-height: 21.6px;
    }
    .sj-result-title a:hover {
      text-decoration: underline;
    }
    .sj-result-description {
      color: #545454;
      font-size: 15px;
      line-height: 22px;
      overflow-wrap: break-word;
      margin-top: 2px;
      margin-bottom: 4px;
    }
    .sj-result-url {
      font-size: 13px;
      line-height: 18.2px;
      margin: 0;
      color: #a2a2a2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sj-result-url a {
      text-decoration: none;
      color: #a2a2a2;
    }
    .sj-result-image-container {
      float: left;
      width: 100px;
    }
    .sj-result-image-container img {
      max-height: 90px;
      max-width: 90px;
    }
    /**
     * Paginator
     */
    .sj-paginator {
      margin: 1em 0;
      text-align: center;
    }
    .sj-paginator > div {
      display: inline;
      padding: 10px;
      color: #777;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
    .sj-paginator > div.current {
      color: #333;
    }
    .sj-paginator > div.disabled {
      color: #aaa;
    }
    /**
     * Tabs
     */
    .sj-tabs-container {
      border-bottom: 1px solid #ebebeb;
      color: #777;
      width: 100%;
      margin-bottom: 1em;
    }
    .sj-tabs {
      overflow: auto;
      white-space: nowrap;
    }
    .sj-tab {
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      margin: 0;
      padding: .9em;
      user-select: none;
    }
    .sj-tab-active {
      color: #333;
      border-bottom: 3px solid #333;
    }
    
    @media (max-width: 845px) {
      .sj-pipeline-response {
        padding: 0px 10px;
      }
    }
