// For Article page header
.article_header_image_component {
  height: 500px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}

.article_header_image_component.transparent_layer {
  position: relative;
  z-index: 1;
  &:after {
    @extend .transparent_layer_after;
  }
}

.yellow_text {
  color: $flinders-gold;
}

.blue_text {
  color: $blue;
}

.black_text {
  color: $black;
}

.white_text {
  color: $white;
}

.article-page {
  .header_site {
    background: $blue;
  }
  .global_header {
    background-color: $blue;
  }
}

// For editable template
.article-page {
  .logo_line {
    height: auto;
  }
  .position_top {
    top: 7rem;
  }
}
