//This mixin can be used to replace ~80% of the code in the sections.scss file - but for now it is only being used for light grey, domestic grey and international grey sections...
//TODO(JD): Extend support of this mixin to all section background colours.
//TODO(JD): Also add some validation and also a default "section_container"
//Adding more section background colours means adding to the map in the _variables.scss file ($section_styles_map).
@mixin section-styles-generator($section_map) {
	@each $section_class, $section_details_map in $section_map {

		$section_type : map-get($section_details_map, section-type); //Not currently being used but generally speaking there are two "groups" of section background "light" and "dark"
		$section_bg_colour : map-get($section_details_map, background-colour);
		$section_text_colour : map-get($section_details_map, text-colour);

		//e.g. .grey_light_container
		.#{$section_class}_container {
			background-color: $section_bg_colour;
		    @include alpha-transparency-options($section_bg_colour);
		    color: $section_text_colour;
		    a {
		       // color: $link_default;
		       &:focus {
		           color: $flinders-navy;
		        }
		    }
		    h3 {
		        a {
		            text-decoration: none;
		            color: $section_text_colour;

		        }
		    }
		    .hr_alignment_content {
		        > div {
		            background: $section_bg_colour;
		            color: $section_text_colour;
		        }
		    }
		    //TODO: Genralize this block
		    //e.g. [.grey_light_container]_featured
		    &_featured {
		        .read_more {
		            @extend .readmore_details__yellow;
		            &:hover {
		                text-decoration: none;
		                border: none;
		                color: $blue;
		                &:after {
		                   @extend .base_animation_buttons__hover_after;
		                }
		            }
		            &:focus {
		                color: $black;
		                text-decoration: none;
		            }
		            &:after {
		                @extend .base_animation_buttons__after;
		                background: $dusty-yellow;
		                border: none;
		            }
		        }
		    }
		    //TODO: Genralize this block
		    .hub_featured_content_area {
		        .hub_classic_button {
		            &-d1 {
		                @extend .base_animation_buttons;
		                background: $yellow;
		                border: 1px solid $yellow;
		                color: $black;
		                white-space: nowrap;
		                &:hover {
		                    border: none;
		                    border: 1px solid $dusty-yellow;
		                    text-decoration: none;
		                    &:after {
		                       @extend .base_animation_buttons__hover_after;
		                    }
		                }
		                &:focus {
		                    color: $black;
		                    text-decoration: none;
		                }
		                &:after {
		                    @extend .base_animation_buttons__after;
		                    background: $dusty-yellow;
		                    border: 1px solid $dusty-yellow;
		                    border: none;
		                }
		            }
		             &-d2 {
		                @extend .classic_white_button_navy_border;
		                border: 1px solid $black-display1;
		                background: none;
		                color: $black;
		                white-space: nowrap;
		                &:hover {
		                     border: 1px solid $blue;
		                     color: $white;
		                }
		                &:focus {
		                    color: $black;
		                    text-decoration: none;
		                }
		                &:after {
		                    background: $blue;
		                    border: 1px solid $blue;
		                    color: $white;
		                }
		            }
		        }
		    }
		    &__bar {
		       	.bar_heading {
		            @extend .hr_alignment_opening;
		            line-height: 2rem;
		            font-size: 1.4rem;
		        }
		        .bar_button {
		            @extend .classic_yellow_button_transform;
		            &:hover {
		                color: $blue;
		            }
		        }
		    }
		}
	} //end @each
} //end mixin()