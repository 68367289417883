$spacer_author_bg_colour: transparentize(#a2f0ff, 0.5);
$spacer_author_border: 1px solid #e4e4e4;
$spacer_author_min_height: 25px;

.spacer-component {
  border: none;
  background: transparent;
  &--authoring {
    border: $spacer_author_border;
    min-height: $spacer_author_min_height;
    background-color: $spacer_author_bg_colour;
  }
  &__height-box {
    display: none;
    &--desktop {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
    &--tablet {
      @include media-breakpoint-only(md) {
        display: block;
      }
    }
    &--mobile {
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}
