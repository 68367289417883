$dark-blue: #002d62;
$light-blue: #406289;
$light-grey: #f6f6f6;
$dark-grey: #eeeeee;
.student-course-details {
  &__accordion {
    margin-bottom: 0.5rem;
  }
  &__accordion-header {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    text-decoration: none !important;
    &--dark-blue {
      background: $dark-blue;
      color: white !important;
    }
    &--light-blue {
      background: $light-blue;
      color: white !important;
    }
    &--light-grey {
      background: #f6f6f6;
      color: black !important;
      font-size: 1.2rem;
    }
    &--reduced {
      font-size: 1.2rem;
      padding: 1rem;
    }

    position: relative;
    display: flex;
    align-items: center;
    &--open {
      .fa-chevron-circle-down {
        transform: rotate(-180deg);
      }
    }
    .fa-chevron-circle-down {
      font-size: 1.5rem !important;
      margin-left: auto;
      text-align: center;
      -moz-transition: all .3s linear;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
    }
  }
  &__topic-groups-container {
    padding: 1rem;
  }
  &__topics-container {
    padding: 0;
    width: 100%;
  }
  &__accordion-content {
    padding: 1rem;
    &--condensed{
      padding-left: 0;
      padding-right: 0;
    }
    &--light-grey {
      background-color: #f6f6f6;
    }
    &--margin-bottom {
      margin-bottom: 0.5rem;
    }
    line-height: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__accordion-content-label {
    &--bold {
      font-weight: bold;
    }
  }
  &__course-details-container {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
  }
  &__course-details-featured {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
      flex-basis: 100%;
      justify-content: center;
    }
  }
  &__topic-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    flex-basis: 35%;
    @include media-breakpoint-down(md) {
      flex-basis: 50%;
    }
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
    }
  }
  &__topic-details-links {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    @include media-breakpoint-down(md) {
      flex-basis: 100%;
    }
  }

  &__link-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  &__feature-link {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 24px;
    display: block;
  }
  &__course-detail {
    margin-bottom: 5px;
  }
  &__button-link {
    color: black;
    font-weight: bold;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    line-height: 24px;
    width: 250px;
    transition: background-color 0.3s linear;
    display: block;
    position: relative;
    text-decoration: none;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
    &--grey {
      background-color: #eeeeee;
      &:hover {
        background-color: #979797;
      }
    }
    &--right {
      margin-left: auto;
    }
  }
  &__button-link-icon {
    position: absolute;
    right: 16px;
    top: 0;
    line-height: 40px;
  }

  &__circle-feature {
    color: white;
    background-color: $light-blue;
    width: 140px;
    height: 140px;
    @include media-breakpoint-down(sm) {
      zoom: 0.8; //NOTE(JD) - this might cause weird artefacts, but it is a super easy/lazy way of downscaling everything instead of doing it manually!!!
    }
    margin-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:first-child {
      margin-left: 0;
    }

    &-value {
      font-size: 2rem;
      line-height: 1;
    }
    &-label {

    }
  }
}
