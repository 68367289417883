
@include media-breakpoint-up(lg) {
  .cmp-image__image-align-desktop {
    &--left {
      margin-left: 0;
      margin-right: auto;
    }
    &--center {
      margin: 0 auto;
    }
    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
@include media-breakpoint-only(md) {
  .cmp-image__image-align-tablet {
    &--left {
      margin-left: 0;
      margin-right: auto;
    }
    &--center {
      margin: 0 auto;
    }
    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
@include media-breakpoint-down(sm) {
  .cmp-image__image-align-mobile {
    &--left {
      margin-left: 0;
      margin-right: auto;
    }
    &--center {
      margin: 0 auto;
    }
    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
div.image-general img {
  display: block;
}
