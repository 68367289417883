//Fast Facts
$ff_key_fact_height: 115px;
$ff_key_fact_height_offset: 52px;
$ff_key_fact_internal_padding: 1.6rem;
$ff_font_family: $font_family; //Open Sans
$ff_key_fact_heading_font_weight: 700;
$ff_key_fact_detail_font_weight: 400;
$ff_key_fact_heading_font_size: 2.5rem;
$ff_key_fact_detail_font_size: 1rem;
$ff_key_fact_heading_line_height: 2.5rem;
$ff_key_fact_detail_line_height: 1rem;

$dom_background_colour: $domestic_grey;
$int_background_colour: $international_grey;
$ff_dom_int_toggle_bg_dom: #55595c;
$ff_dom_int_toggle_bg_int: #818a91;

.key_fact { 
	@include dynamic-even-columns(4);
}
//This is the icon that triggers the tooltip
.fast_facts_tooltip_icon {
	margin: 0 5px;
	color: black;
	&:focus,:hover,:active {
		color: black;
	}
}
//This is the actual tooltip container
.ff_tooltip.tooltip {
	.tooltip-inner {
		background-color: $gray-dom-tooltip;
		&:before {
			border-top-color: $gray-dom-tooltip;
		}
	}
	.tooltip-arrow {
		background-color: $gray-dom-tooltip;
	}
}
.main_fast_facts_container {
	//Apparently our version of bootstrap does not have the helper classes I need, so i am making them here, just for the fast-facts..
	.d-none {
		display: none !important;
	}
	
	.d-lg-block {
		@include media-breakpoint-up(lg) {
			display: block !important;
		}
	}
	.d-lg-none {
		@include media-breakpoint-up(lg) {
			display: none !important;
		}
	}
	.d-md-none {
		@include media-breakpoint-down(md) {
			display: none !important;
		}
	}

	.align-self-end {
		-ms-flex-item-align: end !important;
    	align-self: flex-end !important;
	}
	.align-items-end {
		-webkit-box-align: end !important;
	    -ms-flex-align: end !important;
	    align-items: flex-end !important;
	}

	.row.flex-row {
		@include media-breakpoint-up(sm) {
			display: -webkit-box;
		    display: -ms-flexbox;
		    display: flex;
		    -ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		}
	}

	//End temp helper classes
	.ff_dom_int_warning {
		min-height: 300px;
		.dom_int_warning_large_text {
			font-size: 1.525rem;
	    	font-weight: 100;
	    	line-height: 1.2em;
	    	margin-bottom: 1rem;
	    	margin-top: 1rem;
		}
	}
	
	.ff_content_row {
		min-height: 300px;
	}


	padding-top: 15px;
	@include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
    }
	width: 100%;
	//Background colour classes
	.domestic_colour {
		background-color: $dom_background_colour;
		&.fast_facts_bottom_container {
			@include media-breakpoint-down(md) {      
	            background-color: transparent;
	            &> .container {
	            	background-color: $dom_background_colour;
	            }
	        }
		}
	}
	.international_colour {
		background-color: $int_background_colour;
		&.fast_facts_bottom_container {
			@include media-breakpoint-down(md) {      
	            background-color: transparent;
	            &> .container {
	            	background-color: $int_background_colour;
	            }
	        }
		}
	}
	.fast_facts_top_container {
		& > .container {
			@include media-breakpoint-down(md) {      
	            padding: 0;
	        }
		}
	}
	//Key facts styles (the 2 or 3 tabs at the top)
	.key_facts_container {
		@include media-breakpoint-down(md) {      
            max-width: 100%;
            // padding: 0 15px;
        }
		max-width: 33.33%;
		width: 100%;
		float: left;
		.key_fact {
			font-family: $ff_font_family;
			float: left;
			padding: 0 2px;
			position: relative;
			// height: $ff_key_fact_height;
			.key_fact_sub_container {
				position: relative;
				@include media-breakpoint-down(md) {      
		            background-color: transparent;
		            padding-left: 15px;
		            padding-right: 15px;
		        }
				background-color: $flinders-gold;
				// height: $ff_key_fact_height;
				padding: $ff_key_fact_internal_padding;
				.key_fact_heading {
					//currently not used
					font-size: $ff_key_fact_heading_font_size;
					font-weight: $ff_key_fact_heading_font_weight;
					line-height: $ff_key_fact_heading_line_height;
					margin-bottom: 1rem;
					// @include media-breakpoint-down(md) {
			  //           font-size: 2rem;
					// 	line-height: 2rem;
			  //       }
				}
				.key_fact_detail {
					// position: absolute;
					// bottom: $ff_key_fact_internal_padding;
					// left: $ff_key_fact_internal_padding;
					font-size: $ff_key_fact_detail_font_size;
					font-weight: $ff_key_fact_detail_font_weight;
					line-height: $ff_key_fact_detail_line_height;
					// @include media-breakpoint-down(md) {
					// 	font-size: 0.75rem;
					// 	line-height: 0.75rem;
			  //       }
				}
				// //For Animations...
				// width: 100%;
				// @extend .base_animation_buttons;
				// &:after {
				// 	@extend .base_animation_buttons__after;
				// 	background: $dusty-yellow;
	   //  			border: none;
				// }
				// &:hover {
				// 	&:after {
			 //           @extend .base_animation_buttons__hover_after;
			 //        }
				// }
			}
			//Remove padding from first and last key fact
			&:first-of-type {
				padding-left: 0;
			}
			&:last-of-type {
				padding-right: 0;
			}
		}
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	//Tabs styles
	.ff_toggle_container {
		@include media-breakpoint-down(md) {
            float: left;
            max-width: 100%;
            width: 100%;
            height: 50px;
            padding-right: 15px
        }
	    float: right;
	    max-width: 300px;
		width: 100%;
		height: 115px;
		position: relative;

		//Modal stuff
		.ff_modal_overlay {
		    position: fixed;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background: rgba(0,0,0,0.8);
		    z-index: 112;
		    display: none;
		}
		.ff_modal {  
			display: none;
		    position: absolute;
		    top: 65px;
		    right: 0;
		    width: 800px;
		    @include media-breakpoint-down(md) {
	            width: 100%;
	        }
			z-index: 113;
			.ff_modal_content {
				box-sizing: border-box;
				width: 100%;
				@include media-breakpoint-down(md) {
		            max-width: 500px;
    				margin: 0 auto;
		        }
				z-index: 114;
				text-align: center;
				position: relative;
				color: white;
				.option_container {
					box-sizing: border-box;
					float: left;
					width: 50%;
					@include media-breakpoint-down(md) {
			            width: 100%;
			        }
					padding: 1.5rem;
					&.selected {
						border: 0.5rem solid $flinders-gold;
						padding: 1rem;
						.button_row {
							.tick_icon {
								display: inline-block;
								
							}
							.cta-button {
								display: none;
							}
						}
					}
					&#ff_modal_int_option {
						background-color: $ff_dom_int_toggle_bg_int;
					}
					&#ff_modal_dom_option {
						background-color: $ff_dom_int_toggle_bg_dom;
					}
					.big_icon_row {
						float: left;
						width: 100%;
						svg {
							fill: $flinders-gold;
							width: 50px;
							height: 50px;
						}
						@include media-breakpoint-down(md) {
				           	width: 20%;
				           	line-height: 2rem;
				           	height: 2rem;
				           	svg {
								width: 2rem;
								height: 2rem;
							}
				        }
					}
					.heading_row {
						float: left;
						width: 100%;
						@include media-breakpoint-down(md) {
				           	width: 80%;
				           	font-size: 1rem;
				           	line-height: 1rem;
				           	height: 2rem;
				           	text-align: left;
				           	margin-bottom: 1rem;
				        }
						font-size: 1.2rem;
						font-weight: 700;
					}
					.detail_row {
						float: left;
						width: 100%;
						height: 80px;
						font-size: 0.8rem;
						font-weight: 400;
						margin-bottom: 1rem;
						@include media-breakpoint-down(md) {
				           	display: none;
				        }
					}
					.button_row {
						float: left;
						width: 100%;
						height: 50px;
						.tick_icon {
							display: none;
							margin-bottom: 1rem;
							//TODO: Change to svg class
							svg {
								fill: $flinders-gold;
								width: 50px;
								height: 50px;
							}

						}
					}
					&:after {
						content: "";
						display: table;
						clear: both;
					}
				}
				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}
		}
		//end modal stuff
		.ff_toggle_options {
			cursor: pointer;
			width: 100%;
			list-style: none;
			padding: 0;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				position: relative; 
				bottom: auto;
			}
			position: absolute;
			bottom: 51px;
			left: 0;
			
			.ff_toggle_label_container {
				height: 50px;
				//max-width: 250px;
				width: 80%;
				float: left;
				padding-right: 4px;
				.ff_toggle_label {
					text-align: left;
					width: 100%;
					padding: 0 0.8rem;
					position: relative;
					.toggle_chevron.material-icons {
						font-size: 30px;
						position: absolute;
						right: 10px;
						top: 50%;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}
				}
				
			}
			.ff_toggle_icon {
				//height: 50px;
				//max-width: 250px;
				// z-index: 116;
				width: 20%;
				max-width: 60px;
				line-height: 50px;
				text-align: left;
				float: left;
				padding: 0 0.8rem;
				background-color: $flinders-gold;
				position: relative;

				//For Animations...
				@extend .base_animation_buttons;
				&:after {
					@extend .base_animation_buttons__after;
					background: $dusty-yellow;
	    			border: none;
				}
				&:hover {
					&:after {
			           @extend .base_animation_buttons__hover_after;
			        }
				}
			}
			.ff_toggle_link {
				cursor: pointer;
				display: inline-block;
				line-height: 50px;
				width: 100%;
				text-decoration: none;
				font-size: $ff_key_fact_detail_font_size;
				font-weight: 400;
				color: $black;
				&:focus,&:hover {
					color: $black;
				}
				&> svg {
					position: absolute;
				    top: 50%;
				    -webkit-transform: translateY(-50%);
				    -ms-transform: translateY(-50%);
				    transform: translateY(-50%);
				    width: 35px;
				    height: 35px;
				    @include media-breakpoint-down(md) {
			            max-width: 35px;
			        }
				}
			}
			&:after {
				content: "";
				display: table;
				clear: both;
			}
		}
	}
	
	//BOTTOM CONTAINER
	.fast_facts_bottom_container {
		width: 100%;
		margin-top: -($ff_key_fact_height_offset);
		@include media-breakpoint-down(md) {
            // margin-top: -($ff_key_fact_height_offset + $ff_key_fact_height_offset + 15px); //15px for padding above key facts
            margin-top: 0;
            margin-bottom:15px;
        }

		.ff_tab_container {
			width: 100%;
			// padding: 0.8rem;

			.ff-tab-content {
				display: none;
				// min-height: 400px;
				padding-top: $ff_key_fact_height_offset + 15px;
				@include media-breakpoint-down(md) {
					padding-top: 15px;
		            // padding-top: ($ff_key_fact_height_offset + $ff_key_fact_height_offset + 15px + 15px); //first 15px for padding above key facts and second for below
		        }
				&.active {
					display: block;
				}
				@include media-breakpoint-up(md) {
					.pull-right-md-up {
						float: right;
					}
		        }
			}

			.ff_save_course_button_row {
				.ff_save_course_button {
					padding: 0 2px;
					&:first-of-type {
						padding-left: 0;
					}
					&:last-of-type {
						padding-right: 0;
					}

					.ff_save_course_button_icon {
						//height: 50px;
						//max-width: 250px;
						// width: 20%;
						// max-width: 60px;
						line-height: 50px;
						height: 50px;
						text-align: left;
						// padding: 0 0.8rem;
						background-color: $flinders-gold;
						position: relative;
						padding:  0;
						display: block;
						width: 100%;

						//For Animations...
						@extend .base_animation_buttons;
						&:after {
							@extend .base_animation_buttons__after;
							background: $dusty-yellow;
			    			border: none;
						}
						&:hover {
							&:after {
					           @extend .base_animation_buttons__hover_after;
					        }
						}
					}
				}
				.ff_save_course_icon, .ff_save_course_list {
					cursor: pointer;
					display: inline-block;
					line-height: 50px;
					width: 100%;
					text-decoration: none;
					font-size: $ff_key_fact_heading_font_size;
					font-weight: 400;
					color: $black;
					height: 50px;
					padding: 10%;
					&:focus,&:hover {
						color: $black;
					}
					&> svg {
						position: absolute;
					    top: 50%;
					    -webkit-transform: translateY(-50%);
					    -ms-transform: translateY(-50%);
					    transform: translateY(-50%);
					    width: 80%;
					    height: 80%;
					    @include media-breakpoint-down(md) {
				            max-width: 80%;
				        }
					}
				}
				&:after {
					content: "";
					display: table;
					clear: both;
				}
			}
		}
	}
	$ff_content_header_font_size: 0.875rem;
	$ff_content_header_line_height: 1.25rem;
	.content_container {
		.content_header {
			font-size: $ff_content_header_font_size;
			font-weight: 700;
			line-height: $ff_content_header_line_height;
			color: black;
		}
		.content_detail {
			font-size: $ff_content_header_font_size;
			font-weight: 400;
			line-height: $ff_content_header_line_height;
			color: black;
			margin-top: 0; //from p tag styles
			margin-bottom: 1rem; //from p tag styles
			.content_list {
				list-style: none;
				margin: 0;
				margin-bottom: 0.5rem;
				padding-left: 0;
			}
		}
	}
	$ff_english_reqs_large_text_size: 3rem;
	.english-reqs {
		margin-bottom: 0.6rem;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;

		&__column {
			padding-right: 2rem;
			flex: 50%;
			margin-right: auto;
			display: flex;
			flex-direction: column;
		}
		&__summary {
			margin-bottom: 1rem;
			display: flex;
		}

		&__detail {
			&.content_detail {
				margin-bottom: 0.4rem;
			}
			display: flex;
		}
		&__title {
			line-height: $ff_content_header_font_size;
			align-self: flex-end;
		}
		&__sub-title {
			line-height: 1rem;
		}
		&__score {
			margin-left: auto;
			&--small {
				min-width: 2.5rem;
				text-align: center;
				line-height: 1rem;
			}
			&--large{
				font-size: $ff_english_reqs_large_text_size;
				line-height: $ff_english_reqs_large_text_size;
			}
		}
	}
}


// TOOLTIP STYLES - MOVE TO COMMON?
.tooltip .tooltip-inner {
    //max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #333;
    border-radius: 0;
}



