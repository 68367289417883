.user-details {
  &__item {
    font-size: 1.5rem;
    &--large {
      font-family: $font_family-titles;
      font-weight: 400;
      font-size: 3rem;
      margin-left: -2px; //NOTE(JD) - this is just to left align a large font (because of how fonts work) - if there are layout dramas, this is probably the cause.
    }
  }
  &__item-label {
    font-weight: 600;
    .user-details__item--large & {
      font-weight: 400;
    }
  }
  &__item-value {
    font-weight: 100;
    .user-details__item--large & {
      font-weight: 400;
    }
  }
  &__item-after {
    &--gold {
      color: $flinders-gold !important;
    }
  }
}
