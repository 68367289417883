$filter_options_title_font_weight: 700;
$filter_options_font_weight: 500;
$filter_options_text_colour: $charcoal_black-display1;

//Finder
.pathways-finder {
  display: flex;
  flex-direction: column;
  &__heading {
    margin-bottom: 2rem;
  }
  &__search {
  }
  &__search-title {
    font-size: 1.525rem;
    font-weight: 100;
    line-height: 1.2em;
    margin-bottom: 1rem;
  }
  &__dropdown {
    border-bottom: 2px solid $black;
    box-sizing: border-box;
    position: relative;
    select {
      width: 100%;
      &:focus {
        outline: none;
      }
      font-size: 1.5rem;
      background: transparent;
      border: none;
      /*hide default down arrow in webkit/moz */
      appearance: none;
      //IE
      &::-ms-expand {
        display: none;
      }
    }
    &-icon {
      position: absolute;
      right: 0;
      top: 5px;
      pointer-events: none;
    }
  }
  &__select {
    cursor: pointer;
    &-item {
      text-align: center;
    }
  }
  &__link {
    text-align: left;
  }
}

//Results
.pathways-finder-results {
  &__ellipsis {
    margin-bottom: 5px;

    display: block;
    height: 100%;
    position: relative;
    overflow: hidden;
    &-line {
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      max-width: 100%;
    }
  }
  &__filters {
    background-color: #FAFAFA;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  &__filter-title {
    font-weight: $filter_options_title_font_weight;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;
    font-size: 12px;
  }
  &__atar-filter {
    &-item {
      float: left;
      width: 20%;
      margin-bottom: 0.5rem;
      white-space: nowrap;
      @include media-breakpoint-down(md) {
        width: 50%;
      }
      &:last-of-type {
        &_sub {
          margin-right: 0;
        }
      }
      &_sub {
        padding: 0.5rem;
        margin-right: 0.5rem;
        display: block;
        text-decoration: none;
        border: 1px solid $flinders-gold;
        background-color: white;
        text-align: center;
        font-weight: $filter_options_font_weight;
        color: $filter_options_text_colour;
        transition: background-color 0.3s;
        &:hover,&:focus {
          text-decoration: none;
          background-color: $gray-light-flinders;
        }
        &--selected {
          background-color: lightgrey;
          &:hover,&:focus {
            text-decoration: none;
          }
        }
      }

    }
  }

  &__view-selector {
    margin-bottom: 0.5rem;
    &-dropdown {
      border: 1px solid $flinders-gold;
      box-sizing: border-box;
      position: relative;
      font-weight: $filter_options_font_weight;
      color: $filter_options_text_colour;
      padding: 0;
      select {
        width: 100%;
        padding: 0.5rem;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        background: transparent;
        border: none;
        /*hide default down arrow in webkit and moz */
        appearance: none;
        //IE
        &::-ms-expand {
          display: none;
        }
      }
      &-icon {
        position: absolute;
        right: 7px;
        top: 6px;
        pointer-events: none;
      }
      .view_dropdown {
        &_item {
        }
      }
    }
  }
  &__count {
    color: $gray-search;
    font-size: 1rem;
    margin: 1rem 0;
    .results_count {
      font-weight: bold;
    }
  }
  &__results {
    padding-top: 1rem;
  }
  &__spinner {
    text-align: center;
    font-size: 5rem;
    padding: 2rem;
    color: $gray-flinders;
  }
  //LIST ITEM STYLES
  &__headings {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgray;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &__item-heading {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 0.5rem;
    cursor: text;
    text-decoration: none;
    &--orderable {
      cursor: pointer;
    }
  }
  &__order-direction {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }
  &__order-direction-chevron {
    cursor: pointer;
    line-height: 1rem;
    height: 14px;
    color: $gray-flinders;
    &--selected {
      color: $gray-search;
    }
  }
  &__item {
    &--shown {
      display: flex;
    }
    display: none;
    align-items: center;
    border-bottom: 1px solid lightgray;
    transition: all .3s linear;
    &:hover {
      background-color: $gray-light-flinders;
    }
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }
  &__item-label {
    display: none;
    @include media-breakpoint-down(md) {
      display: inline;
      font-weight: 600;
    }
  }
  &__item-column {
    padding: 0.5rem;
    &--satac {
      flex-basis: 10%;
      flex-grow: 1;
      order: 1;
      @include media-breakpoint-down(md) {
        order: 2;
        flex-basis: 50%;
      }
    }
    &--course-name {
      flex-basis: 35%;
      flex-grow: 2;
      order: 2;
      @include media-breakpoint-down(md) {
        order: 1;
        flex-basis: 100%;
      }
    }
    &--options-list {
      flex-basis: 25%;
      max-width: 25%;
      flex-grow: 1;
      order: 3;
      @include media-breakpoint-down(md) {
        order: 4;
        max-width: 80%;
        flex-basis: 80%;
      }
    }
    &--atar {
      flex-basis: 15%;
      flex-grow: 1;
      order: 4;
      @include media-breakpoint-down(md) {
        order: 3;
        flex-basis: 50%;
      }
    }
    &--contact {
      flex-basis: 10%;
      order: 5;
      @include media-breakpoint-down(md) {
        max-width: 20%;
        flex-basis: 20%;
        margin-left: auto;
      }
    }
  }
  &__satac {
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  &__course-name {
    @include media-breakpoint-down(md) {
      font-weight: bold;
    }
  }
  &__options-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    &-item {
      overflow-x: hidden;
    }
  }
  &__atar {
    font-size: 2rem;
    line-height: 2rem;
    &--small {
      font-size: 1rem;
    }
  }
  &__icons {
    .enquire_link {
      width: 48px;
      background: $flinders-gold;
      display: block;
      font-size: 30px;
      line-height: 42px;
      text-align: center;
    }
  }
  //END ITEM STYLES
  &__paginator {
    text-align: center;
    margin: 1rem 0;
    .paginator {
      &_page {
        text-decoration: none;
        padding: 0 0.5rem;
        color: $gray-flinders;
        &--selected {
          color: $gray-search;
        }
      }

      &_prev {
        color: $gray-search;
        &--disabled {
          color: $gray-flinders;
        }
      }
      &_next {
        color: $gray-search;
        &--disabled {
          color: $gray-flinders;
        }
      }
    }
  }
}


