// generic transform
@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}
