/**filter dropdown(select)**/
.fancybox-skin {
    background: none;
}
.filter_section {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

.select_easydropdown_wrap.open {
    border: 1px solid yellow;
}

.wrap_featured_content-media {
    padding-top: 3rem;
    padding-bottom: 3rem;

}
.featured_content-media {
    background: $gray-light-flinders-display1;
    padding-top: 15px;
    padding-bottom: 15px;
    color: $gray-search;
    font-size: $font-size-sm;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    height: 145px;
    overflow: hidden;
    h4 {
        color: $charcoal_black-display2;
    }
    p {
        height: 2.69rem;
        margin-bottom: 1rem;
        overflow: hidden;
    }
    div {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: $font-size-xs;
        color: $gray-search;
        font-weight: bold;
    }

}

.input_positioned_filter {
    margin-right: 60px;
}
.hub_filter_page_component {
    margin-bottom: 30px;
    .media_gal_picture {
        margin-bottom: 0;
        i {
            color: $white;
            background: rgba(100, 100, 100, 0.6);
            bottom: 0;
            height: 2rem;
            line-height: 2rem;
            position: absolute;
            right: 0;
            text-align: center;
            width: 3rem;
            font-size: $font-size-xs;
            font-weight: bold;
            cursor: pointer;
            font-style: normal;
            z-index:1;
            &:before {
                display: inline-block;
                @extend .fa;
                content: "";
                font-size: $font-size-xs;
                margin-right: 0.34rem;
            }
        }
    }

    &:hover {
         .featured_content-media {
            background: $gray-light-flinders;
        }
        .media_gal_picture {
            ins {
                display: block;
            }
        }
    }
}

.hub_section_text {
    font-size: $font-size-sm;
    position: absolute;
    bottom: 0;
    z-index: 111;
    width: 100%;
    right: 0;
    line-height: 1.5;
    padding-bottom: 1rem;
    strong {
        font-weight: $semibold;
        max-height: 3rem;
        overflow: hidden;
        color: $white;
        line-height: 1.6;
        margin-bottom: 0.5rem;
        color: $white;
        display: block;

    }
    span {
        color: $gray-flinders;

    }
}
.hub_filter_button {
    a {
        @extend .readmore_details__yellow;
        margin-top: 2.4rem;
        width: 100%;
        height: 3.3rem;
        line-height: 1.6rem;
        text-align: center;
        border: none;
        background: $gray-flinders;
        z-index: 99;
        font-size: $font-size-base;
        text-transform: uppercase;
        text-align: left;
        &:hover {
           border: none;
           text-decoration: none;
           color: $blue;
           &:after {
               @extend .base_animation_buttons__hover_after;
           }
        }
        &:after {
            @extend .base_animation_buttons__after;
            background: $yellow;
            border: none;
        }
        i {
           &.fa-search {
                font-size: $font-size-base !important;
                padding-left:0.5rem;
           }
        }
    }

}
.hub_alert {
    display: none;
}

.featured_content-media_picture {
    height: 227px;
    overflow: hidden;
    line-height: 227px;
    a {
        text-decoration: none;
        img {
            vertical-align: middle;
        }
        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: none;
            -webkit-transition: all 0.5s ease 0s; /* Safari */
            transition: all 0.5s ease 0s;
        }
        i {
            color: $white;
            background: rgba(100, 100, 100, 0.6);
            bottom: 0;
            height: 2rem;
            line-height: 2rem;
            position: absolute;
            right: 0;
            text-align: center;
            width: 3rem;
            font-size: $font-size-xs;
            font-weight: bold;
            cursor: pointer;
            font-style: normal;
            &:before {
                display: inline-block;
                @extend .fa;
                content: "";
                font-size: $font-size-xs;
                margin-right: 0.34rem;
            }
        }
    }
}

.hub_section_text {
    display: none;
}

.wrap_featured_content-media {
    .row {
        & > div:hover {

            .featured_content-media_picture {
                a {
                    position: relative;
                    z-index: 2;
                    display: block;
                    &:after {
                        background: rgba(0, 0, 0, 0.25);

                    }
                    i {
                        z-index: 11;
                    }
                    .hub_section_text {
                        display: block;
                    }
                }
            }
            /*
            .featured_content-media {
                background: $gray-light-flinders;
            }*/
        }
    }
}


.media_gal_picture {
    height: 227px;
    overflow: hidden;
    line-height: 227px;
    margin-bottom: 30px;
    ins {
        background: rgba(0, 0, 0,0.25);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: all 0.5s ease 0s;
        width: 100%;
        z-index:1;
        display: none;
    }
}
.col-lg-3 {
    .media_gal_picture {
        height: 180px;
        line-height: 180px;
    }
}
.media_gal_picture:hover {
        position: relative;
         &:after {
            background: rgba(0, 0, 0, 0.25);
             z-index: -1;
             position: absolute;
             left: 0;
             top: 0;
             content:'';
        }
        .fancybox-hub {
            ins {
                display: block;
            }
            .hub_section_text {
                display: block;
            }
        }


        /*
    .featured_content-media {
        background: $gray-light-flinders;
    }*/
}
.filter_section {
    p {
        margin-bottom: 0.4rem;
        label {
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 2px;
            font-weight: 600;
            color: $charcoal_black;
        }
    }
}


.custom_input_noborder {
    border: none;
    height: 3.3rem;
    line-height: 3.3rem;
    border: 1px solid $gray-light-flinders;
    padding-right: 15px;
}

.filter_section {
    .custom_search_text {
        border-right: none;
        z-index:1;
    }
}
.input_positioned_filter.active + a {
    border: 1px solid $yellow;
    background: $yellow;
}

.media_hub_pagination {
    color: $gray-search;
    font-size: $font-size-sm;
    vertical-align: middle;
    padding-top: 60px;
    margin-top: 30px;
    &:before {
        content: '';
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        width: auto;
        height: 1px;
        background: $gray-light-flinders;
    }
    input[type="text"] {
        border: 2px solid $gray-flinders;
        height: 2.875rem;
        width: 2.875rem;
        text-align: center;
        color: $charcoal_black;
        margin: 0 1rem;
        padding: 0;
    }
    .pag_yellow_button {
        @extend .readmore_details__yellow;
        height: 3rem;
        width: 3rem;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            border: none;
            color: $blue;
            &:after {
               @extend .base_animation_buttons__hover_after;
            }
        }
        &:focus {
            color: $black;
            text-decoration: none;
        }
        &:after {
            @extend .base_animation_buttons__after;
            background: $dusty-yellow;
            border: none;
        }
        &:before {
            @extend .fa;
            content: "";
            right: 1.1rem;
            position: absolute;
            top: 22px;
            line-height: 0;
            font-size: 1.4rem;
            color: $black;
        }
    }
    .btn_left {
        margin-left: 1rem;
        &:before {
            content:"";
        }
    }
}

.filter_section_button_mobile {
    display: none;
}

.filter_section .container.close_view {
    display: block;
}

.filter_section {
    .custom_input_noborder {
        &:focus {
            border: 1px solid $yellow;
            outline: none;
        }
    }
}

.filter_section {
    .custom_search_text {
        border-right: none;
    }
}

.filter_section {
    .custom_input_noborder {
        &:focus + a {
            border: 1px solid $yellow;
            border-left: none;
            background: $yellow;
        }
    }
}

.hub_filter_search_results {
    .ui-menu {
        padding-top: 1rem;
        padding-bottom: 1rem;
        .ui-menu-item-wrapper {
            padding: 0.2rem 1rem;

        }
        &.ui-state-active {
            border: 1px solid red;
        }

    }
}

.hub_filter_search_results {
    .ui-menu {
        .ui-menu-item-wrapper.ui-state-active {
            border: none;
            background: $yellow;
            color: $charcoal_black;
        }

    }
}

.hub_filter_search_results {
    .ui-widget.ui-widget-content {
        border: 2px solid $gray-light-flinders;
    }
}

.featured_content-media {
    a {
        text-decoration: none;
    }
}

.hubform_date_range {
    position: relative;
}

.hub_fancybox_individual_gallery {
    .mediahub-fancybox {
        max-width: 1000px;
        img {
            max-width: 100%;
        }
    }
}

.hubform_date_range {
    input {
        width: 48%;
        float: left;
        padding-right: 5%;
        padding-left: 5%;
        &:focus {
            background: $yellow;
        }
        + input {
            margin-left: 4%;
        }
    }
}

.hubform_date_range {
    input + input {
        margin-left: 4%;
    }
}

.ui-datepicker-buttonpane {
    span {
        text-transform: uppercase;
        color: $primary_color;
        border: 1px solid $primary_color;
        display: inline-block;
        float: left;
        margin: 0.5em 0.2em 0.4em 0;
        padding: 0 1rem;
        cursor: pointer;
        font: $font-size-sm $font_family;
        line-height: 1.8rem;

    }
}
.media_gal_picture  {
    img {
        position: absolute;

        @include media-breakpoint-down(md) {
            width: 100%;

            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        @include media-breakpoint-up(lg) {
            height: 100%;
            max-width: none;

            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        // height: 100%;
        // left: 0;
        // object-fit: cover;
        //
        // top: 0;
        // width: 100%;
    }
}
/*changed styles for datepicker */
.datepicker_hub.ui-datepicker {
    @include border-radius(0);

    font-family: $font_family;
    .ui-widget-header {
        background: none;
        @include border-radius(0);
        border: none;
        .ui-state-hover {
            background: none;
            border: none;
        }
        .ui-icon {
            background-image: none;
        }
    }
    th {
        font-weight: normal;
        color: #777;
        font-size: 0.7rem;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    .ui-datepicker-title {
        font-weight: 400;
        line-height: 2.4rem;
    }
    .ui-datepicker{
        &-next {
            cursor: pointer;
            top: 8px;
            z-index:11;
            span {
                position: relative;
                height: 50px;
                &:before {
                    @extend .fa;
                    @extend .fa-chevron-right;
                    position: absolute;
                    left: 0;
                    top: 8px;
                    width: 10px;
                    height: 10px;
                    line-height: 0;
                    display: inline-block;
                    font-size: 0.65rem;
                    color: $black;
                    text-indent: 1px;
                    z-index: 1111;
                    display: block;
                }
            }
         }
         &-prev {
            cursor: pointer;
            top: 8px;
            z-index:11;
            span {
                position: relative;
                height: 50px;
                &:before {
                    @extend .fa;
                    @extend .fa-chevron-left;
                    position: absolute;
                    left: 0;
                    top: 8px;
                    line-height: 0;
                    display: inline-block;
                    font-size: 0.65rem;
                    color: $black;
                    text-indent: 1px;
                    z-index: 1111;
                }
            }
         }
         &-calendar {
            tbody {
                tr {
                    td {
                        border: 1px solid $gray-light-flinders;
                        height: 48px;
                        vertical-align: bottom;
                        padding: 0;
                        background: $white;
                        min-width: 2.8rem;
                        a {
                            border: none;
                            font-weight: $semibold;
                            background: none;
                            padding-left: 22px;
                            font-size: 1rem;

                        }
                    }
                }
            }
         }
         &-buttonpane {
            border: none;
            .ui-datepicker-close {
                border: none;
                background: $yellow;
                color: $black;
                @include border-radius(0);
                text-transform: uppercase;
                padding: 0 1rem 0;
                font: $semibold $font-size-sm $font_family;
                line-height: 1.8rem;
                margin-right: 0;
                border: 1px solid $yellow;
            }
         }
    }
    .ui-state-default {
        border: none;
        background: none;
    }
}
.ui-datepicker {
    table {
        overflow: inherit;
    }
}
.datepicker_hub {
    .ui-datepicker-calendar {
        td.ui-datepicker-current-day {
            background: $gray-light-flinders;

            font-weight: $semibold;
            a {
                color: $black;
            }
        }

    }
}

.ui-widget.ui-widget-content.datepicker_hub {
    border: 1px solid $yellow;
    z-index: 111 !important;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    margin-top: -1px;
}

/**Individual fancybox gallery **/
.hub_fancybox_individual_gallery {
    .hub_fancybox_close_btn {
        background: none;
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: $gray-flinders;
        font-size: 2rem;
        right: -3rem;
        top: -1.5rem;
    }
}

.hub_fancybox_individual_gallery {
    &.fancybox-opened {
        .fancybox-skin {
            background: none;
            box-shadow: none;
        }
        .js-mediahub-fancybox {
            &-body {
                color: $white;
                font: $semibold $font-size-sm $font_family;
                padding-top: 1rem;
            }
            &-date {
                color: $gray-light-flinders-display2;
                font: $font-size-sm $font_family;
            }
        }

    }
}

.datepicker_hub {
    .ui-datepicker-current {
        display: none;
    }
}

.hub_fancybox_individual_gallery {
    .hub_gallery {
        &_left {
            position: absolute;
            left: 10px;
            top: 47%;
            width: auto;
            height: auto;
            text-decoration: none;
            &:after {
                @extend .fa;
                display: block;
                content: "";
                color: $white;
                font-size: 3rem;
                left: 0;
                top: 0;
            }

        }
        &_right {
            @extend .fa;
            position: absolute;
            right: 10px;
            top: 47%;
            width: auto;
            height: auto;
            text-decoration: none;
            &:after {
                @extend .fa;
                display: block;
                content: "";
                color: $white;
                font-size: 3rem;
                right: 0;
                top: 0;
            }
        }
    }
}
.hub_load_btns a {
    margin-right: 15px;
}
.mediahub_fancybox_img {
    width: 980px;
    height: 600px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    img {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .mediahub_fancybox_img {
        width: 730px;
        height: 500px;
    }

}
@include media-breakpoint-up(lg) {
    .ui-datepicker-calendar {
         tbody {
            tr {
                td {
                    min-width: 2.8rem;
                }
            }
        }

    }

 }

@include media-breakpoint-down(sm) {

    .filter_section_button_mobile + .container.close_view {
        display: none;
    }
    .filter_section {
        padding-bottom: 0;
        margin-top: 2rem;
        padding-top: 0;
        p {
            margin-bottom: 3px;
            margin-top: 1rem;
            label {
                margin-bottom: 0;
            }
        }
    }
    .filter_section_button_mobile {
        display: block;
        background: $yellow;
        a {
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 2px;
            font-weight: 600;
            color: $charcoal_black;
            height: 3rem;
            line-height: 3rem;
            display: block;
            text-decoration: none;
            position: relative;
            i {
                font-size: 1rem;
                position: absolute;
                right: 1rem;
                top: 1.1rem;
            }
            &:hover {
                text-decoration: none;
            }
            &:focus {
                text-decoration: none;
            }
        }
    }
    .filter_section {
        .custom_input_noborder {
            margin-bottom: 10px;
        }
    }
    .close_view_btn {
        background: $gray-light-flinders;
        a {
            i {
                display: none;
            }
            &:after {
                display: inline-block;
                font: normal normal normal 1.2rem FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 1rem;
                content: "";
                top: 1rem;
                -ms-transform: rotate(90deg); /* IE 9 */
                -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
                transform: rotate(90deg);
            }
        }
    }

    .wrap_featured_content-media {
        padding-top: 2rem;
        .row {
            & > [class^="col-"],
            & > [class*="col-"] {
                .featured_content-media {
                    h4 {
                        margin-bottom: 0.3rem;
                    }
                    p {
                        height: inherit;
                        margin-bottom: 0.4rem;
                    }

                }
            }
        }
    }
    .hub_load_btns a {
        width: 100%;
        margin-bottom: 15px;
    }
    .media_gal_picture  {
        overflow: inherit;
    }
    .mediahub_fancybox_img {
        width: 500px;
        height: 500px;
    }
    .hub_filter_button {
        a {
            margin-top: 0;
        }
    }
}
@include media-breakpoint-down(xs) {
    .mediahub_fancybox_img {
        width: 300px;
        height: 300px;
    }
}
@include media-breakpoint-down(lg) {
    .featured_content-media_picture {
        height: 188px;
    }
    .hub_fancybox_individual_gallery {
        .hub_gallery {
            &_left {
                left: 0;
            }
            &_right {
                right: 0;
            }
        }
    }
    .hub_fancybox_individual_gallery {
        .hub_fancybox_close_btn {
            right: 0;
            top: 0;
        }
    }
    .featured_content-media {
        height: 165px;
    }
}

@include media-breakpoint-down(md) {
    .featured_content-media_picture {
        height: auto;
    }
    .featured_content-media {
        height: auto;
    }
}
