#navigation-mobile-menu, #navigation-mobile-menu-only{
    li{
        list-style: none;
    }
    a{
        text-decoration: none;
    }
    .mobile-menu__navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    }
    .mobile-menu__hamburger {
        display: none;
    }

    .mobile-menu__bar {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }


    .mobile-menu__nav-menu {
        display: flex;
        justify-content: right;
        align-items: center;
        margin: 0px;
    }
    .mobile-menu__title{
        display: none;
    }

    .mobile-menu__nav-item {
        margin-left: 2rem;
    }

    .mobile-menu__nav-link{
        font-family: $font_family-circular-medium;
        font-size: 1rem;
        font-weight: 400;
    }

    .mobile-menu__black {
        color: $black !important;
        a {
            color: $black !important;
            transition: 0.3s;
        }
        .mobile-menu__bar {
            background-color: $black !important;
        }
    }
    .mobile-menu__white {
        color: $white !important;
        a {
            color: $white !important;
            transition: 0.3s;
        }
        .mobile-menu__bar {
            background-color: $white !important;
        }
    }
    .mobile-menu__hover-black a:hover,  {
        color: $black !important;
        transition: 0.3s;
    }
    .mobile-menu__hover-white a:hover {
        color: $white !important;
        transition: 0.3s;
    }
    .mobile-menu__hover-gold a:hover {
        color: $flinders-gold !important;
        transition: 0.3s;
    }
    .mobile-menu__hover-navy a:hover {
        color: $flinders-navy !important;
        transition: 0.3s;
    }
    .mobile-menu__hover-blue a:hover {
        color: $light_blue !important;
        transition: 0.3s;
    }
    .mobile-menu__hover-light-grey a:hover {
        color: $gray-light-flinders !important;
        transition: 0.3s;
    }
}





@media only screen and (max-width: 768px) {
    #navigation-mobile-menu{
        .mobile-menu__bg-black{
            .mobile-menu__nav-menu {
                 background-color: $black !important;
            }
        }
        .mobile-menu__bg-white{
            .mobile-menu__nav-menu {
                 background-color: $white !important;
            }
        }
        .mobile-menu__bg-gold{
            .mobile-menu__nav-menu {
                 background-color: $flinders-gold !important;
            }
        }
        .mobile-menu__bg-navy{
            .mobile-menu__nav-menu {
                 background-color: $flinders-navy !important;
            }
        }
        .mobile-menu__bg-blue{
            .mobile-menu__nav-menu {
                 background-color: $light_blue !important;
            }
        }
        .mobile-menu__bg-light-grey{
            .mobile-menu__nav-menu {
                 background-color: $gray-light-flinders !important;
            }
        }
        .mobile-menu__bg-ochre{
            .mobile-menu__nav-menu {
                 background-color: $ochre !important;
            }
        }
        .mobile-menu__bg-eucalypt_green{
            .mobile-menu__nav-menu {
                 background-color: $eucalypt_green !important;
            }
        }
        .mobile-menu__bg-midnight-blue{
            .mobile-menu__nav-menu {
                 background-color: $midnight_blue !important;
            }
        }
        .mobile-menu__bg-summer-red{
            .mobile-menu__nav-menu {
                 background-color: $summer_red !important;
            }
        }
        .mobile-menu__bg-stone-grey{
            .mobile-menu__nav-menu {
                 background-color: $stone_grey !important;
            }
        }
        .mobile-menu__bg-dusty-white{
            .mobile-menu__nav-menu {
                 background-color: $dusty_white !important;
            }
        }
        .mobile-menu__bg-ocean-blue{
            .mobile-menu__nav-menu {
                 background-color: $ocean_blue !important;
            }
        }
        .mobile-menu__bg-sunset-pink{
            .mobile-menu__nav-menu {
                 background-color: $sunset_pink !important;
            }
        }

        .mobile-menu__title{
            display: block;
            font-family: $font_family-circular-black;
            font-size: 1.5em;
            margin-bottom: .5em;
        }

        .mobile-menu__nav-menu {
            position: fixed;
            left: -100%;
            top: 0;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: 0.3s;
            padding: 0px;
            z-index: 9999;
        }

        .mobile-menu__nav-menu.active {
            left: 0;
            z-index: 9999;
          .mobile-menu__nav-link{
            font-size: 1.3rem;
          }
        }

        .mobile-menu__nav-item {
            margin: 0.5em;
        }

        .mobile-menu__hamburger {
            display: block;
            cursor: pointer;
            z-index: 99999;
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(2) {
            opacity: 0;
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(1) {
            -webkit-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(3) {
            -webkit-transform: translateY(-8px) rotate(-45deg);
            transform: translateY(-8px) rotate(-45deg);
        }
    }
}

@media only screen and (max-width: 9999px) {
    #navigation-mobile-menu-only{
        .mobile-menu__bg-black{
            .mobile-menu__nav-menu {
                 background-color: $black !important;
            }
        }
        .mobile-menu__bg-white{
            .mobile-menu__nav-menu {
                 background-color: $white !important;
            }
        }
        .mobile-menu__bg-gold{
            .mobile-menu__nav-menu {
                 background-color: $flinders-gold !important;
            }
        }
        .mobile-menu__bg-navy{
            .mobile-menu__nav-menu {
                 background-color: $flinders-navy !important;
            }
        }
        .mobile-menu__bg-blue{
            .mobile-menu__nav-menu {
                 background-color: $light_blue !important;
            }
        }
        .mobile-menu__bg-light-grey{
            .mobile-menu__nav-menu {
                 background-color: $gray-light-flinders !important;
            }
        }
        .mobile-menu__bg-ochre{
            .mobile-menu__nav-menu {
                 background-color: $ochre !important;
            }
        }
        .mobile-menu__bg-eucalypt_green{
            .mobile-menu__nav-menu {
                 background-color: $eucalypt_green !important;
            }
        }
        .mobile-menu__bg-midnight-blue{
            .mobile-menu__nav-menu {
                 background-color: $midnight_blue !important;
            }
        }
        .mobile-menu__bg-summer-red{
            .mobile-menu__nav-menu {
                 background-color: $summer_red !important;
            }
        }
        .mobile-menu__bg-stone-grey{
            .mobile-menu__nav-menu {
                 background-color: $stone_grey !important;
            }
        }
        .mobile-menu__bg-dusty-white{
            .mobile-menu__nav-menu {
                 background-color: $dusty_white !important;
            }
        }
        .mobile-menu__bg-ocean-blue{
            .mobile-menu__nav-menu {
                 background-color: $ocean_blue !important;
            }
        }
        .mobile-menu__bg-sunset-pink{
            .mobile-menu__nav-menu {
                 background-color: $sunset_pink !important;
            }
        }


        .mobile-menu__nav-menu {
            position: fixed;
            left: -100%;
            top: 0;
            flex-direction: column;
            justify-content: center;
            background-color: #011c2c;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: 0.3s;
            padding: 0px;
            z-index: 9999;
        }

        .mobile-menu__title{
            display: block;
            font-weight: 700;
            font-size: 1.7em;
            margin-bottom: .5em;
        }

        .mobile-menu__nav-menu.active {
            left: 0;
            z-index: 9999;
            .mobile-menu__nav-link{
              font-size: 1.3rem;
            }
        }

        .mobile-menu__nav-item {
            margin: 0.5em;
        }

        .mobile-menu__hamburger {
            display: block;
            cursor: pointer;
            z-index: 99999;
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(2) {
            opacity: 0;
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(1) {
            -webkit-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
        }

        .mobile-menu__hamburger.mobile-menu__hamburger-active .mobile-menu__bar:nth-child(3) {
            -webkit-transform: translateY(-8px) rotate(-45deg);
            transform: translateY(-8px) rotate(-45deg);
        }
    }
}
