//This style sheet is for the Rich Text Editor (RTE) custom styles definitions (i.e.) the styles plugin/dropdown when editing an RTE

//NOTE: There is javascript for the styles to be applied to list items, it is contrained in the common.js file

.rte {
  //Custom colour styles
	.text_color {
		&_flinders_navy {
			color: $flinders-navy;
		}
		&_flinders_gold {
			color: $flinders-gold;
		}
		&_flinders_white {
			color: $white;
		}
		&_flinders_black {
			color: $black;
		}
		&_flinders_light_grey {
			color: $gray-light-flinders; //#eee
		}
		&_flinders_medium_grey {
			color: $gray-search; //#777
		}
		&_flinders_dark_grey {
			color: $gray-dark-flinders; //#333
		}
		&_flinders_midnight_blue{
			color: $midnight_blue;
		}
	}
	.text_bg_color {
		&_flinders_gold {
			background-color: $flinders-gold;
		}
	}
	.text_size {
		&_large {
			font-size: $rte-font-size-large;
			font-weight:100;
			line-height:1.2em;
		}
	}
	.feature_heading {
			font-size: $rte-font-size-feature-heading;
			font-weight:700;
			line-height:1em;
		&_giant  {
			font-size: $rte-font-size-feature-heading-giant;
			font-weight:700;
			line-height:.75em;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
  			// Styles
  				font-size: $rte-font-size-feature-heading;
			}

		}
	}
  .rte-custom-style {
    &__font-size {
      &--huge {
        font-family: $font_family-titles;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -1px;
        //Adjusts to screen size
        font-size: 7rem;
        @include media-breakpoint-down(md) {
          font-size: 4rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 3rem;
        }
      }
    }
    &__font-family {
      &--anton {
        font-family: $font_family-Anton !important;
      }
      &--roboto {
        font-family: $font_family-titles !important;
      }
      &--open-sans {
        font-family: $font_family !important;
      }
      &--circular-thin {
        font-family: $font_family-circular-thin !important;
      }
      &--circular-regular {
        font-family: $font_family-circular-regular !important;
      }
      &--circular-book {
        font-family: $font_family-circular-book !important;
      }
      &--circular-medium {
        font-family: $font_family-circular-medium !important;
      }
      &--circular-black {
        font-family: $font_family-circular-black !important;
        a {
			    text-decoration: none;
			    position: relative;
					}
					a:after {
					    content: "";
							width: 100%;
							position: absolute;
							left: 0;
							bottom: -10px;
							border-width: 0px 0 4px;
							border-style: solid;
					}
					a:hover{
						opacity:0.75;
					}
      }
      &--reckless-medium {
        font-family: $font_family-reckless-medium !important;
      }
      &--reckless-medium-italic {
        font-family: $font_family-reckless-medium-italic !important;
      }
      &--reckless-regular {
        font-family: $font_family-reckless-regular !important;
      }
      &--reckless-regular-italic {
        font-family: $font_family-reckless-regular-italic !important;
      }
      &--circe {
        font-family: $font_family-circe !important;
      }
    }
    &__font-weight {
      &--100 {
        font-weight: 100 !important;
      }
      &--200 {
        font-weight: 200 !important;
      }
      &--300 {
        font-weight: 300 !important;
      }
      &--400 {
        font-weight: 400 !important;
      }
      &--500 {
        font-weight: 500 !important;
      }
      &--600 {
        font-weight: 600 !important;
      }
      &--700 {
        font-weight: 700 !important;
      }
      &--800 {
        font-weight: 800 !important;
      }
      &--900 {
        font-weight: 900 !important;
      }
    }
  }

	ul, ol {
		margin-bottom: 0;
		li {
			padding-left: 1rem;
			margin-bottom: 0.3125rem;
		}
		li:last-child {
			margin-bottom: 1.5rem;
		}
	}

	//general list item styles within tables
	table
	{
		li:last-child
		{
			margin-bottom: .3125rem;
		}

	}

  pre {
    color: inherit;
  }

}
