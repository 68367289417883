//No idea what is using this style so can't remove it.
.fa-times {
    line-height: 9px;
}

$hover_grey: #eeeeee;
$black: black;
$list_item_light_grey_bg: #fafafa;
$hover_background_transition: background-color 0.3s linear;
$chevron_grey: #adadad;
$border_grey: $gray-accordion-border;
$star-colour: #0999E1; //blue
$tick-colour: #39A70B; //green
$midyear_colour: $midyear_icon; //orange

.course_list_component {
    .legend_container {
        margin-bottom: 0.3rem;
        .legend_item {
            margin-right: 1.5rem;
            @include media-breakpoint-down(sm) {
                display: block;
            }
            .fa {
                width: 20px;
            }
            &--new {
                .fa-star {
                    color: $star-colour;
                    width: 24px;
                    text-align: center;
                }
            }
            &--online {
                .fa-check {
                    color: $tick-colour;
                    width: 24px;
                    text-align: center;
                }
            }
            &--midyear {
                &_icon {
                    width: 24px;
                    height: 24px;
                    color: $midyear_colour;
                    vertical-align: bottom;
                }
            }
          &--festival-plaza {
            &_icon {
              height: 24px;
              vertical-align: bottom;
            }
          }
        }
    }

    .accordion_container {
        .accordion_item {
            border: 1px solid $border_grey;
            border-top: none;
            &:first-of-type() {
                border-top: 1px solid $border_grey;
            }
            .item {
                &_heading {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    padding: 0.8rem 1rem;
                    cursor: pointer;
                    transition: $hover_background_transition;
                    color: inherit;
                    text-decoration: none;
                    &:hover {
                        background-color: $hover_grey;
                        color: $black;
                    }
                    .fa-chevron-right {
                        color: $chevron_grey;
                        margin-left: auto;
                        text-align: center;
                        -moz-transition: all .3s linear;
                        -webkit-transition: all .3s linear;
                        transition: all .3s linear;
                    }
                }
                &_content {
                    display: none;
                    .course_list {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li {
                            border-top: 1px solid $border_grey;
                            &:last-of-type() {
                                border-bottom: none;
                            }
                        }
                        .course_list_item {
                            cursor: pointer;
                            display: flex;
                            align-items: flex-start;
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                            padding-left: 0;
                            padding-right: 1rem;
                            @include media-breakpoint-down(sm) {
                                padding-right: 0.5rem;
                            }
                            background-color: $list_item_light_grey_bg;
                            transition: $hover_background_transition;
                            color: inherit;
                            text-decoration: none;
                            &:hover, &:focus {
                                background-color: darken($list_item_light_grey_bg, 5);
                            }
                            .fa {
                                width: 20px;
                            }
                            .new_label {
                                text-align: center;
                                align-self: center;
                                padding-left: 1rem;
                                padding-right: 1rem;
                                @include media-breakpoint-down(sm) {
                                    padding-left: 0.5rem;
                                    padding-right: 0.5rem;
                                }
                                .fa-star {
                                    color: $star-colour;
                                }
                                &.no_star {
                                    .fa-star {
                                        visibility: hidden;
                                    }
                                }
                            }
                            .course_name_label {
                                align-self: center;
                                margin-right: auto;
                                color: $black;
                                .course_link {

                                }
                            }
                            .indicator_icon {
                                &--right {
                                    align-self: center;
                                    padding-left: 0.3rem;
                                    padding-right: 0;
                                    @include media-breakpoint-down(sm) {
                                        padding-left: 0.2rem;
                                        padding-right: 0;
                                    }
                                }
                            }
                            .online_label {
                                .fa-check {
                                    color: $tick-colour;
                                }
                            }
                            .midyear_label {
                                .midyear_icon {
                                    width: 24px;
                                    height: 24px;
                                    color: $midyear_colour;
                                    vertical-align: bottom;
                                }
                            }
                            .festival-plaza_label {
                                .festival-plaza_icon {
                                  height: 24px;
                                  vertical-align: bottom;
                                }
                            }
                        }
                    }
                }
            }
            &.open {
                .item {
                    &_heading {
                        background-color: $hover_grey;
                        color: $black;
                        .fa-chevron-right {
                            -ms-transform: rotate(90deg);
                            -moz-transform: rotate(90deg);
                            -webkit-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                    }
                    &_content {
                        .course_list {
                            .course_list_item {
                            }
                        }
                    }
                }
            }
        }
    }
}
