/* dataTables CSS modification & positioning */

.data-table {
  table.dataTable {

    thead {
      .sorting:before,
      .sorting_asc:before,
      .sorting_desc:before,
      .sorting_asc_disabled:before,
      .sorting_desc_disabled:before {
        right: 0 !important;
        content: "" !important;
      }

      .sorting:after,
      .sorting_asc:after,
      .sorting_desc:after,
      .sorting_asc_disabled:after,
      .sorting_desc_disabled:after {
        right: 0 !important;
        content: "" !important;
      }

      th {
        position: relative;
        background-image: none !important;
        padding: .50rem;
      }

      th.sorting_asc, th.sorting_desc {
        background-color: #abd0e4;
      }

      th.sorting:after,
      th.sorting_asc:after,
      th.sorting_desc:after {
        position: absolute !important;
        top: 12px !important;
        right: 8px !important;
        display: block !important;
        font-family: FontAwesome !important;
        line-height: 1;
        font-weight: 600;
        opacity: unset;
      }

      th.sorting:after {
        content: "\f107" !important;
        font-size: 0.8em !important;
        padding-top: 0.12em !important;
      }

      th.sorting_asc:after {
        content: "\f106" !important;
      }

      th.sorting_desc:after {
        content: "\f107" !important;
      }

      th.sorting_asc:after, th.sorting_desc:after {
        opacity: .3;
      }

    }

  }

  .table_sort_wraps {
    overflow-x: hidden;
  }

  .dataTables_length {
    padding-top: 1.25em;
    padding-right: 2.25em;

    select {
      border-color: #cccccc;
      text-align: center;
    }
  }

  .dataTables_info {
    float: left;
  }

  .dataTables_filter label {

    text-align: center !important;
    vertical-align: top;

    input {
      border: 1px solid #eee;
      height: auto;
      line-height: inherit !important;
      font-size: 16px;
      padding: 12px 29.6px 12px 12px;
      margin-left: 0px !important;
      //padding: .75rem 1.85rem .75rem .75rem;
    }

    i {
      vertical-align: top;
      font-size: 1.9rem;
      height: 50px;
      width: 50px;
      background: $flinders-gold;
      line-height: 50px;
      float: right;
    }
  }

  .dataTables_paginate {
    padding-right: 1.80em;
    input {
      width: 40px;
      text-align: center;
      border: 1px solid #ccc;
      height: 50px;
      width: 50px;
      color: #434343;
      margin: 0 5px;
      padding: 0;
    }

    span.previous, span.next {
      text-align: center;

      i {
        vertical-align: top;
        font-size: 1.9rem;
        height: 50px;
        width: 50px;
        background: #ffd300;
        line-height: 50px;
        margin: 0 5px;
        cursor: pointer;
      }
    }

    span.previous.disabled, span.next.disabled {
      background: none;
      opacity: .3;
    }

  }

  @media (max-width: 992px) {
    table {
      display: table;
    }

    .dataTables_length {
      padding-right: 0px;
    }

    .dataTables_paginate {
      padding-right: 0px;
    }

    .dataTables_paginate {
      span.next i {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: 576px) {
    .pull-left {
      float: none;
    }
    .pull-right {
      float: none;
    }

    .dataTables_info {
      float: none;
      padding-bottom: .85em;
    }

    .dataTables_length {
      padding-top: .85em;
    }

    .dataTables_paginate {
      span.next i {
        margin-right: 0px;
      }
    }

  }

  .custom-select {
    border-radius: 0px !important;
  }

  table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child::before {
    top: 15px;
  }
}

