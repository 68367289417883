//Custom styles for tippy.js tooltips
//Will just add styles as we need them, try to follow some semblance of order...

.tippy-content {
  //Style for links within a tooltip component - we might ideally make the light/dark text part of a higher level style
  &__link {
    &--light {
      color: $white;
      &:hover, &:focus {
        color: $white;
      }
    }
  }
}
