.accordion {
  &-card {
    border-bottom:1px solid $gray-accordion-border;
    &__header {
      background-color: transparent;
      position: relative;
      padding:0;
      &.style_accordion_headers {
        font-size: $rte-font-size-large;
        font-weight:100;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 15px;
        font-family: $font_family-circular-medium;
        i {
          &.toggle_chevron {
            transition: transform 0.5s;
          }
          &.toggle_add {
            transition: transform 0.5s;
            @include rotate(45);
          }
        }
        &.collapsed {
          i {
            &.toggle_chevron, &.toggle_add{
              @include rotate(180);
            }
          }
        }
      }
      a, a:focus {
        width:100%;
        text-decoration: none;
        color: $black-display1;
      }
      &:hover {
        background: rgba(0, 0, 0, .1);
        transition: background 0.3s linear;
      }
    }
    &__header, &__header:first-child {
      border-bottom:1px solid lightgrey;
      border-radius: 0;
    }
    &__block {
      background-color: transparent;
      padding: 0;
      &.component_section{padding: 0px 0px 0px 0px;}
    }
    &.accordion-card_large {
      .accordion-card__header {
        a {
          font-size: $font-size-fln-h3;
          padding: 15px;
          i {
            font-size: $font-size-fln-h5;
          }
        }
      }
    }
  }
  .style_accordion_show_more {
    display: flex;
    flex-direction: column-reverse;
    .accordion-card__header{
      padding:0;
      a{
        justify-content: center;
        &.collapsed {
          span {
            display:none;
          }
          &:before{
            content: 'Show more';
          }
        }
        i{
          margin-left: 15px;
        }
      }
    }
    .accordion_show_more{
      padding: 0;
    }
  }
}

.dark_blue_container, .black_container, .gray_dark_container, .gray_darker_container, .secton_color__white, .blue_container{
  .accordion {
    &-card {
      border-bottom: 1px solid $white;
      &__header{
        &:hover {
          background: rgba(256, 256, 256, .1);
          transition: background 0.3s linear;
        }
      }
    }
  }
  a , a:focus{
    color: $white;
    i{
      color: $white;
    }
  }
}

.gold_container, .international_grey_container, .gray_light_container, .domestic_grey_container, .secton_color__black {
  .accordion {
    &-card {
      border-bottom: 1px solid $black;
      &__header {
        &:hover {
          background: rgba(0, 0, 0, .1);
          transition: background 0.3s linear;
        }
      }
    }
  }
}

.secton_color__black{
  a , a:focus , a:hover{
    color: $black;
    i{
      color: $black;
    }
  }
}

.style_accordion_remove_bottom_border {
  border-bottom: none !important;
}
