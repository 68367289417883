//Example use: 
//"margin_left_50" 
//or 
//"margin_left_50--md_up" 
//NOTE: "--" denotes optional in this casel.
@mixin generate-margin-helper-classes($margin_size_options) {
    $margin_options: (
        all : margin,
        top : margin-top,
        bottom : margin-bottom,
        left : margin-left,
        right : margin-right
    );
    $breakpoint_labels: (
        "xs",
        "sm",
        "md",
        "lg",
        "xl"
    );
    .margin {
        //Loop through margin options i.e. '[.margin]_left'
        @each $margin_option, $style in $margin_options {
            //Loop through margin sizes i.e. '[.margin_left]_100'
            @each $margin_size_option, $size in $margin_size_options {
                &_#{$margin_option} {
                    &_#{$margin_size_option} {
                        #{$style}: $size; //This is the main style generated i.e. 'margin-left: 100px;'

                        //Loop through breakpoint options i.e. '[.margin_left_100]--sm'
                        @each $breakpoint_label in $breakpoint_labels {
                            &--#{$breakpoint_label} {
                                //Breakpoint ranges i.e. '[.margin_left_100--sm]_only'
                                &_only {
                                    @include media-breakpoint-only($breakpoint_label) {
                                        #{$style}: $size;
                                    }
                                }
                                &_up {
                                    @include media-breakpoint-up($breakpoint_label) {
                                        #{$style}: $size;
                                    }
                                }
                                &_down {
                                    @include media-breakpoint-down($breakpoint_label) {
                                        #{$style}: $size;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}