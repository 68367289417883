//eventon - reference to the eventon wordpress plugin that is installed on events.flinders.edu.au (flindersevents.wpengine.com)


.events_date, .events_details {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $font-size-xs;
    font-style: normal;
    color: $black;
    font-weight: bold;
    line-height: 1.7rem;
 }


//******* General Layout styles ********* //   
.component_section_item {
    position: relative;
    .events-background-image {
        background-size:cover;
        background-repeat: no-repeat;
        background-position: 50% 30%;
    }
    .component_section_events_wrap {
        padding: 1rem;
        .ellip-line {
            white-space: wrap;
        }
        .events_date {
            margin-bottom: 1rem;
            position: relative;
            &:after {
                position: absolute;
                content: '';
                top:0;
                left: 0;
                width: 34px;
                height: 3px;
                background: $yellow;
            }
            .event_startDate_month {
                font-size: 1.8rem;
                line-height: 2.5rem;
                vertical-align: top;
                i {
                    font-size: $font-size-xs;
                }
            }
            .event_endDate_month {
                font-size: 1.3rem;
                line-height: 2rem;
                vertical-align: top;
                i {
                    font-size: .6rem;
                }
            }
            .event_date_dash {
                font-size: 1rem;
                line-height: 2rem;
                vertical-align: middle;
                padding-right: 10px;
                padding-left:10px;
            }
            
            .event_startDate_month, .event_endDate_month, .event_date_dash {
                font-weight: $semibold;
                display: table-cell;
                
                
                i {
                    font-style: normal;
                    text-transform: uppercase;
                    display: block;
                    line-height: 0.7rem;
                    font-weight: bold;
                    padding-left:2px;
                }

            .event_endDate_month{
                    font-size: $font-size-xs;
                }
            }
        }
        h3 {
            overflow: hidden;
            //margin-bottom: 0px;
        }
        .events_details {
            line-height: 1rem;
            color: $black;
        }
        .classic_white_button_navy_border__small {
            white-space: nowrap;
        }

        .event_actions {
            display: table;
            width: 100%;
            border-collapse: collapse;
            padding: 0;
            margin: 0;
            div {
                display: table-cell;
                vertical-align: middle;
                &:first-child {
                    text-align: left;
                    padding-right: 2px;
                }
                &:last-child {
                    text-align: center;
                    width: 120px;
                }
                
            }
        }
    }


    .events-read-more-btn-navy {
            @extend .classic_white_button_navy_border__small;
    }

    .events-read-more-btn-white {
                @extend .classic_transparent_button_white_border__small;
    }
}

.rowSpacing {
    margin-bottom: 30px;
}

.hideMoreButton, .hide_date, .events_hide_excerpt{
    display:none !important;
}


.eventon-feed-v2 .no-component-padding, .eventon-feed-v2 .no-component-padding .padding_top_d1 {
    padding:0 !important;
}

.mobile_events_btn {
    display: none !important;
}

.no-gutter {
    .events-overlay-layout{
        margin-bottom:0px;
    }
}

.events-list-layout.events-border {
          border: none;
}

.events-standard-layout.events-border, .events-overlay-layout.events-border {
    border: 1px solid $gray-light-flinders;
 }


//******* Overlay Layout ********* //   

.component_section_item {
    .events-overlay-layout {
        .flex-container {
            flex-direction: column;
            justify-content:space-between;
        } 
        .events_hide_excerpt{
           display:none; 
        }
    }
    .events_overlay  {
            @include linear-gradient(rgba(0,0,0,0.05), rgba(0,0,0,0.8), vertical);
         .events_v2_date, .events_date, .eventon_place, h3, .events_show_excerpt, .events_details {
                 color:$white;
         }
        .events_title{
            display: flex;
            align-items:flex-end;
            flex-grow:1;
        }
        .events_details {
            align-self:flex-end;
        }
    }
    .events_overlay_excerpts_line {
         margin-bottom: 1rem;
    }
}   

//******* END Overlay Layout ********* //    


//******* Standard Layout ********* //

.events-standard-layout {
    .events_image{
        display:none;
    }
    .flex-container {
        height:300px;
        flex-direction: column;
        justify-content: space-between;
        .component_section_events_inner_wrap{
            display:flex;
            flex-grow:2;
            flex-direction: column;
            justify-content:space-between;
            .events_details {
                align-self:flex-end;
            }
        }
    }
    
}


//******* List Layout ********* //

.events-list-layout {
    background-color: $gray-light-flinders;
    margin-bottom: 20px;
    display:flex;
    .events_image{
        display:none;
    }
    .flex-container {
        height:100px;
        width:100%;
        flex-direction: row;
        align-items: center;
            .events_date {
                width:15%;
                margin-bottom: 0;
            }
            .events_title{
                width:55%;
                margin-right: 20px;
                margin-left: 20px;
            }
            .events_details {
                width:30%;
                .eventon_place{
                    display: inline;
                }
            }
            .events_cta {
                margin-top: 6px;
                margin-left: 20px;
            }
    }
    h3 {
        margin-bottom: 0px;
    }
}

//******* END List Layout ********* // 


//******* Feature Image styles ********* //

    .events-standard-layout {
        .events_image{
            display:block;
            width:100%;
            height:240px;
        }
    }


.container_fullwidth {
     .events-standard-layout {
        .events_image{
            height:300px;
        }
    }
    .events-overlay-layout {
        .events_image{
             height:300px;
        }
    }
}

.events-overlay-layout {
    .events_image {
        height:300px;
    }
} 

//******* Feature Image styles ********* // 


//******* Section background colours/borders adjustments  ********* //

.gray_darker_container, .gray_dark_container, .blue_container, .black_container, .dark_blue_container {


    .eventon-feed-v2 {
        .classic_white_button_navy_border, .classic_white_button_navy_border i{
            color: $white!important;
        }
        .classic_white_button_navy_border{
            border: 1px solid $white !important;
        }
        .classic_white_button_navy_border:hover, .classic_white_button_navy_border:hover i {
            text-decoration: none;
            color: $blue !important;
        }
        .classic_white_button_navy_border:after{
            border: 1px solid $white !important;
            background: $white !important;
            color: $blue!important;
        }
    }


    .events-standard-layout, .events-overlay-layout {

        .events_v2_date, .events_date, .eventon_place, h3, .events_show_excerpt, .events_details, i {
                 color:$white !important;
         }
        .events-read-more-btn-navy {
            background: none;
            color: $white !important;
            border: 1px solid $white !important;
        }
        .events-read-more-btn-navy:hover {
            text-decoration: none;
            color: $blue !important;
        }
        .events-read-more-btn-navy:after {
            border: 1px solid $white !important;
            color: $blue !important;
            background: $white !important; 
        }
        .events-standard-layout.events-border, .events-overlay-layout.events-border {
          border: 1px solid $gray-light-flinders;
        }

    }

    .events-list-layout {

        .events_v2_date, .events_date, .eventon_place, h3, .events_show_excerpt, .events_details, i {
                 color:$charcoal_black;
         }

    }

}

.gray_light_container, .domestic_grey_container {


    .events-list-layout {
        background-color: $gray-flinders;
    }
    .events-standard-layout.events-border, .events-overlay-layout.events-border {
          border: 1px solid $charcoal_black;
    }

}

.gold_container {

    .events-standard-layout.events-border, .events-overlay-layout.events-border {
          border: 1px solid $charcoal_black;
    }
        
    .component_section_item {
        .events-standard-layout {
            .component_section_events_wrap {
                .events_date:after {
                    background: $charcoal_black;
                }
            }
        }
        .events-overlay-layout {
            .events_overlay_no_bg_image {
                .events_date:after {
                    background: $charcoal_black;
                }
            }
        }
    }
    

}

//******* End Section background colours/borders  ********* //




//******* Media breakpoints ********* // 


@include media-breakpoint-down(md) {


    .events-list-layout {
        .flex-container {
            height:auto;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
                .events_date {
                    margin:0px 0px 20px 0px;
                    width:auto;
                }
                .events_title{
                    margin:0px 0px 20px 0px;
                    width:100%;
                }
                .events_details {
                    margin:0px 0px 20px 0px;
                    width:auto;
                    .eventon_place{
                        display: inline;
                    }
                }
                .events_cta {
                    display: inline-block;
                    margin:0px 0px 0px 0px;
                }
        }
    
    }

    .container_fullwidth {
         .events-standard-layout {
            .events_image{
                height:260px;
            }
        }
    }


}

@include media-breakpoint-down(sm) {


    .desktop_events_btn {
        display: none;
    }

    .mobile_events_btn {
        display: block !important;
    }


}








