//This file is to contain custom styles for form elements
//Dropdowns/selects, text inputs, etc

.flinders-forms {

  &__label {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 0.5rem;
    &--lightweight {
      font-size: 1.525rem;
      font-weight: 100;
      line-height: 1.2em;
    }
    &--heavyweight {
      font-weight: 700;
      font-size: 0.75rem;
    }
  }
  &__select-container {
    //Required for the parent container
    border-bottom: 2px solid; //inherit colour from text colour to support dark sections :D
    box-sizing: border-box;
    position: relative;
    margin-bottom: 1rem;
  }
  &__select {
    //Add to the "<select>" tag
    width: 100%;
    cursor: pointer;
    color: inherit;
    &:focus {
      outline: none;
    }
    font-size: 1.5rem;
    background: transparent;
    border: none;
    /*hide default down arrow in webkit/moz */
    appearance: none;
    //IE
    &::-ms-expand {
      display: none;
    }
    option {
      color: #000000;
    }
  }
  &__select-icon {
    position: absolute;
    right: 0;
    top: 5px;
    pointer-events: none;
  }
  &__checkbox {

  }
  &__radio {

  }
}


