//Created by James Duncan <james.duncan@flinders.edu.au> 04/08/2017

//These styles are only to be applied when printing
@media print {
  $default-text-color : #000;
  //Only show this when print-mode
  .print-only {
    display: block !important;
  }

  //avoid blank pages at the end
  html, body {
    height: 99%;
  }

  //hide breadcrumb
  .breadcrumb{
    display: none;
  }

  @page {
    size: auto;   /* auto is the initial value */
    /* this affects the margin in the printer settings (top right bottom left) */
    //If we add any extra margin on the sides, the break-points for dynamic columns will break
    margin: 10mm;
  }

  //the header shouldn't be that far from the top of the page
  header{
    padding: 0 !important;
  }

  .component_section{
    padding: 0;
  }

  // hide read-more buttons
  .read_more.print-only{
    display: none !important;
  }

  // Add extra hight when there is sub title
  .header_image_component.print-only{
    height: 160px;
  }

  //navigation should be hidden
  .navigation-section{
    display: none;
  }

  //navigation context component should be hidden
  .navigation-context2{
  	display: none;
  }

  //we don't need the underline when printing
  .section a{
    text-decoration: none;
  }

  table {
    page-break-after: auto
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto
  }
  thead {
    display: table-header-group
  }
  tfoot {
    display: table-footer-group
  }

  //Prevent the top of every page being cut off (print will insert a fixed header into every page, hiding content)
  .global_header {
    position: relative;
  }

  //Ensure the correct logo displays - in this case, the black logo.
  .header_logo_place {
    .flinders_logo_white {
      display: none;
    }
    .flinders_logo_black {
      display: block;
    }
  }

  .component_section {
    &--hidden-print {
      display: none !important;
    }
  }


  //Hide following components...
  //"component" 			| "reason"
  //"iframe-componment" 	| "The contents are unpredictable/not viable for printing purposes"
  //"searchresult" 		| "This is not relevant for printing"
  //"twitter-feed"		| "This breaks the print layout"
  .iframe-component, .scroll-top, .footer_copyright .footer_support_logos, .footer_copyright a, .footer_copyright .footer_slogan, .searchresult, .twitter-feed, form, .dynatable-search, .footer_main .footer_list, .hide-from-print {
    height: 0;
    display: none !important;
  }

  //hide top quicklinks, search and top nav text
  .global_header {
    .header_quicklink_section, .header_search_top, nav.navbar {
      height: 0;
      display: none !important;
    }
  }

  //Adjust height of header (background image will be auto-hidden)
  .header_image_component {
    height: 60px;

    //should hide the buttons from the header
    a{
      display: none;
    }

    //Setting this to keep the title of the page at the top
    .position_bottom {
      bottom: 0px
    }
  }

  // BUG Remove commented or ask task reporter
  //Adjust font sizes for printing.
  p {
    font-size: 12pt;
    page-break-inside: avoid;
  }
  h1 {
    font-size: 17pt !important;
    margin:.5em 0 !important;
    padding:0 !important;
    line-height: 1;
  }
  h2 {
    font-size: 15pt;
    margin:.5em 0 !important;
    padding:0 !important;
    line-height: 1;
  }
  h3 {
    font-size: 13pt;
  }
  sub {
    font-size: 10pt;
  }

  //hopefully help align first letters of links in footer (chrome bug)
  a::first-letter {
    margin: 0;
    padding: 0;
  }

  //Add print-only class to all columns. Some columns should have specific behaviour when printing
  $allColumns : '.col-xs', '.col-xs-1', '.col-xs-2', '.col-xs-3', '.col-xs-4', '.col-xs-5', '.col-xs-6', '.col-xs-7', '.col-xs-8', '.col-xs-9', '.col-xs-10', '.col-xs-11', '.col-xs-12', '.col-sm', '.col-sm-1', '.col-sm-2', '.col-sm-3', '.col-sm-4', '.col-sm-5', '.col-sm-6', '.col-sm-7', '.col-sm-8', '.col-sm-9', '.col-sm-10', '.col-sm-11', '.col-sm-12', '.col-md', '.col-md-1', '.col-md-2', '.col-md-3', '.col-md-4', '.col-md-5', '.col-md-6', '.col-md-7', '.col-md-8', '.col-md-9', '.col-md-10', '.col-md-11', '.col-md-12', '.col-lg', '.col-lg-1', '.col-lg-2', '.col-lg-3', '.col-lg-4', '.col-lg-5', '.col-lg-6', '.col-lg-7', '.col-lg-8', '.col-lg-9', '.col-lg-10', '.col-lg-11', '.col-lg-12', '.col-xl', '.col-xl-1', '.col-xl-2', '.col-xl-3', '.col-xl-4', '.col-xl-5', '.col-xl-6', '.col-xl-7', '.col-xl-8', '.col-xl-9', '.col-xl-10', '.col-xl-11', '.col-xl-12';

  //.component_section .component_section .component_section .row > *{
  //  page-break-inside: avoid;
  //}


  //the deepest column in a row shouldn't break
  .deepest-column{
    page-break-inside: avoid;
  }

  .no-image-column{
    page-break-inside: auto;
  }

  @media (min-width: 576px){
    .col-md-4 {
      float: left;
      width: 33.3333333333%;
    }
  }

  //if the column is the full width, it shouldn't float. Float would cause the content get truncated
  @each $column in $allColumns {
    #{$column}:not(.not-full-on-print) {
      width: 100%;
      float: none;
    }
  }

  //only for Chrome. Chrome was creating a lot of empty pages at the end of the page
  @media (-webkit-min-device-pixel-ratio:0){
      .col-sm-4:not(.not-full-on-print) {
        width: 550px;
      }
  }

    //Don't hide image on print once we can't use image background css property
  .compat-object-fit .img-fluid{
    opacity: 1 !important;
  }

  .footer_main > .container {
    padding: 0;
  }

  // don't show list of links
  .list-links{
    display: none;
  }

  // don't show video players
  .video > .component_section{
    display: none !important;
  }

  // removed big gap on testimonials
  .testimonials-slider.slick-dotted{
    margin: 0;
  }

  // hide images from testimonials
  .testimonials-slider-slide-image_square{
    display: none;
  }

  // testimonials full width
  .testimonials-slider-slide{
    width: 100% !important;
  }

  // remove gap in testimonials
  .testimonials-wrapper{
    display: none;
  }

  // remove pictures
  //.compat-object-fit{
  //  display: none;
  //}


  //overriding text colors
  p, h1, h2, h3, sub{
    color: $default-text-color;
  }

  // overriding title text color
  .yellow_heading{
    color: $default-text-color;
    position: relative;
    top: 15px;
  }

  // overriding subtitle text color
  .header_image_component > * {
    color: $default-text-color;
  }

  // Remove all CTA buttons
  div.cta-button {
    display: none;
  }

  // Remove scroll to top button
  a.scroll-top {
    display: none;
  }

  .section{
    //margin-top: 15px;
  }

  //Prevent any component sections from being cut off by a page break (unless there is no option)
  section div div.section {
    // position: relative;
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: avoid; /* 'auto,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: auto; /* or 'auto' */

    .component_section_item, .component_section {
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: auto;
    }
  }

  .footer_copyright{
    display: none;
  }

  //don't break the footer
  .footer_contacts{
    page-break-inside: avoid;
  }

  //Footer simplified
  .footer_contacts > div > p:nth-child(2) {
    display: none;
  }

  //decreased gap between footer image and writing
  .footer_logo_wrap{
    margin-bottom: 10px !important;
  }

  .footer_contacts{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    p {
      padding: 0;
      margin: 0;
    }
  }

  .accordion-card {
    .collapse {
      display: block;
    }
  }

  .testimonials-slider-slide__quotation{
    color: $default-text-color;
  }

  .course_information_list{
    .new_list{
      color: #d9534f;
    }

    .online_list{
      color: #5bc0de;
    }
  }

  //Override gray color to black
  .hr_alignment_content > div {
    color: $default-text-color !important;
    margin-bottom: 10px;
  }

  //hide slider
  #subSectionSlider_forcefullwidth{
    display: none !important;
  }

  //hide sticky nav
  #sticky_nav{
    display: none !important;
  }

  .blockquote, blockquote{
    font-size: 2rem !important;
  }

  .tab-content {
    > a {
      //Font bold on tab title
      font-weight: bold;

      //Hide the expand button
      &:after{
        content:none !important;
      }
    }

    // Tabs should be all opened as per accordion
    .collapse {
      display: block !important;
    }
  }

  // For open day
  h1 {
    font-size: 15pt !important;
  }

  h2 {
    font-size: 13pt !important;
  }

  h3 {
    font-size: 11pt !important;
  }

  p {
    font-size: 9pt;
    page-break-inside: avoid;
    margin: 0px 0px 5px 0px;
  }

  .user-details__item {
    font-size: 13pt !important;
  }
}
