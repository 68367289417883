/*
To orveride height specified in asset share.
 */
html, body {
    height: auto !important;
    font: $display-weight-normal $font-size-base $font_family !important;
    color: $primary_color;
    position: relative;
    min-width: $min_width;
    line-height: $line-height-base !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font_family-titles;
    line-height: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

h1 {
    font-size: $font-size-fln-h1;
    line-height: 2.625rem;
}

h2 {
    font-size: $font-size-fln-h2;
    line-height: 2rem;
    a {
        color: $black;
        text-decoration: none;
    }
}

h3 {
    font-size: $font-size-fln-h3;
    line-height: 1.75rem;
    a {
        color: $black;
        text-decoration: none;
    }
}

h4 {
    font-size: $font-size-fln-h4;
}

h5 {
    font-size: $font-size-fln-h5;
}

h6 {
    font-size: $font-size-fln-h6;
}

.featured_content-display1,
.featured_content-media {
    h4 {
        a {
            color: $primary_color;
        }
    }
    .introductory_text {
        font-size: 1.1rem;
    }
}

input[type=text]::-ms-clear { display: none; }

.featured_content-display1 {
    line-height: 2rem;
}
.clear {
    clear: both;
}
.text-left {
    text-align: left
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.pull-none {
    float: none;
}

.pos_relative {
    position: relative;
}
iframe {
    display: block;
}

a {
    color: $link_default;
    text-decoration: underline;
    &:focus {
        text-decoration: underline;
        color: $dark-yellow;
    }
    &:hover {
        text-decoration: underline;
        color: $blue;
    }
}
figcaption {
    color: $gray-search;
    font-size: $font-size-xs;
}

hr {
    border: none;
    border-top: 1px solid $black;
    max-width: 100%;
    &.hr_dark {
        border-top: 1px solid $gray-dark-flinders;
    }
    &.hr_light {
        border-top: 1px solid $white;
    }
}
.pos_rel {
    position: relative;
}

.blockquote {
    border: none;
    font-size: 2.25rem;
    line-height: 2.625rem;
    position: relative;
    padding: 30px 50px;
    text-align: center;
    font-style: italic;
}
.blockquote_material_icon_after {
    content: "\E244";
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    position: absolute;
    right: 0;
    top: 30px;
}
blockquote {
    @extend .blockquote;
    color:$gray-search;
    &:after {
       @extend .blockquote_material_icon_after;
       color:$yellow;
    }
}
.component_section {
    padding-top: $btn-padding-x + $btn-padding-x + $btn-padding-x;
    padding-bottom: $btn-padding-x + $btn-padding-x;
}
.component_section_d1 {
    padding-top: 20px;
    padding-bottom: 20px;
}
//General Padding Rules - this may be a good place to use a mixin, but that would make the code less readable.

// No Gutter class - when applied to row will remove the column gutters.
.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}


.padding {
	//None padding class e.g. "padding_none"
	&_none {
		padding: 0px;
	}
    //Top padding classes e.g. "padding_top_100"
    &_top {
        &_none {
            padding-top: 0;
        }
        &_25 {
            padding-top: 25px;
        }
        &_50 {
            padding-top: 50px;
        }
        &_75 {
            padding-top: 75px;
        }
        &_100 {
            padding-top: 100px;
        }
        &_150 {
            padding-top: 150px;
        }
        &_200 {
            padding-top: 200px;
        }
        &_300 {
            padding-top: 300px;
        }
        &_d1 {
            padding-top: 1.875rem;
        }
    }
    //Bottom padding classes e.g. "padding_bottom_100"
    &_bottom {
        &_none, &_none.wrap_featured_content-media {
            padding-bottom: 0;
        }
        &_25 {
            padding-bottom: 25px;
        }
        &_50 {
            padding-bottom: 50px;
        }
        &_75 {
            padding-bottom: 75px;
        }
        &_100 {
            padding-bottom: 100px;
        }
        &_150 {
            padding-bottom: 150px;
        }
        &_200 {
            padding-bottom: 200px;
        }
        &_300 {
            padding-bottom: 300px;
        }
        &_d1 {
          padding-bottom: 2rem;
      }
    }
    //Left padding classes e.g. "padding_left_100"
    &_left {
        &_none, &_none.wrap_featured_content-media {
            padding-left: 0;
            &--md_down {
                @include media-breakpoint-down(xs) {
                    padding-left: 0;
                }
            }
        }
        &_none_on_xs {
        	@include media-breakpoint-down(xs) {
				padding-left: 0px;
			}
        }
        &_10 {
            padding-left: 10px;
        }
        &_25 {
            padding-left: 25px;
        }
        &_50 {
            padding-left: $default_column_padding;
            @include media-breakpoint-up(md) {
                padding-left: 50px;
            }
        }
        &_75 {
            padding-left: $default_column_padding;
            @include media-breakpoint-up(md) {
                padding-left: 75px;
            }
        }
        &_100 {
            padding-left: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-left: 100px;
            }
        }
        &_150 {
            padding-left: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-left: 150px;
            }
        }
        &_200 {
            padding-left: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-left: 200px;
            }
        }
    }
    //Right padding classes e.g. "padding_right_100"
    &_right {
        &_none, &_none.wrap_featured_content-media {
            padding-right: 0;
        }
        &_10 {
            padding-right: 10px;
        }
        &_25 {
            padding-right: 25px;
        }
        &_50 {
            padding-right: $default_column_padding;
            @include media-breakpoint-up(md) {
                padding-right: 50px;
            }
        }
        &_75 {
            padding-right: $default_column_padding;
            @include media-breakpoint-up(md) {
                padding-right: 75px;
            }
        }
        &_100 {
            padding-right: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-right: 100px;
            }
        }
        &_150 {
            padding-right: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-right: 150px;
            }
        }
        &_200 {
            padding-right: $default_column_padding;
            @include media-breakpoint-up(lg) {
                padding-right: 200px;
            }
        }
    }
}

@include generate-margin-helper-classes($margin_size_options);

.component_section_picture {
    overflow: hidden;
    max-height: 226px;
    margin: 0 0 1rem;
}
figure {
    > div {
        margin-bottom: 10px;
    }
}
/*heading in slider section*/
.yellow_heading {
    color: $yellow;
}
.mb1 {
    margin-bottom: 1rem;
}
.container_indent {
    padding-top: $btn-padding-x + $btn-padding-x;
    padding-bottom: $btn-padding-y + $btn-padding-y;
}
section {
    width: 100%;
    overflow: hidden;
}
.section_gray_gap {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.pointer {
    cursor: pointer;
}
.default_indents {
    padding: 0;
    margin: 0;
}
.before_after_default {
    position: absolute;
    content: '';
}

figcaption {
    color: $gray-search;
    font-size: $font-size-xs;
}
.pos_rel {
    position: relative;
}
.hr_alignment_content {
    @extend .pos_rel;
    font-size: 3.5rem;
    line-height: 4rem;
    font-weight: $dt-font-weight;

    text-align: center;
    z-index:2;
    width: 80%;
    margin: 0 auto;
    padding: 0 10%;
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        top: 50%;
        background: $black;
        height: 1px;
        left: 0;
        z-index:-1;
    }
    > div {
        background: white;
        display: inline-block;
        word-break: break-word;
        padding: 0 2%;
        max-width: 100%;
    }
}
.hr_alignment_opening {
    @extend .text-center;
    font-size: 2rem;
    line-height: 2.875rem;
    font-weight: $dt-font-weight;
    margin-bottom: 0;
}
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11  {
    &.col-sm-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-md-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-lg-12 {
        .hr_alignment_content {
            width: 100%;
        }
    }
}
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11  {
    &.col-sm-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-md-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-lg-12 {
        .hr_alignment_content {
            width: 100%;
        }
    }
}
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11  {
    &.col-sm-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-md-12 {
       .hr_alignment_content {
            width: 100%;
        }
    }
    &.col-lg-12 {
        .hr_alignment_content {
            width: 100%;
        }
    }
}
.col-sm-12,
.col-md-12,
.col-lg-12 {
    .hr_alignment_content {
        width: 80%;
    }
}

.collapse.in {
    display: block;
}

.collapse {
    display: none;
}

@include media-breakpoint-down(sm) {
    .hr_alignment_opening {
        font-size: 1rem;
        line-height: 2rem;
    }
    .hr_alignment_content {
        font-size: 1.5rem;
        line-height: 2.1rem;
    }
}

@include media-breakpoint-only(md) {
    .hr_alignment_opening {
        font-size: 1.4rem;
        line-height: 2.875rem;
    }
    .hr_alignment_content {
        font-size: 2.4rem;
        line-height: 3rem;
    }
}

@include media-breakpoint-between(xs, sm) {
    .add_bottom_padding {
        padding-bottom: 60px;
    }
}

.inline_block {
	display: inline-block;
}

//General flex container and alignment options for aligning content both horizontally and vertically with flex.
.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display:flex;
}
.flex-justify-left {
    justify-content: flex-start;
}
.flex-justify-center {
    justify-content: center;
}
.flex-justify-right {
    justify-content: flex-end;
}
.flex-vertical-align-top {
    align-self: top;
}
.flex-vertical-align-middle {
    align-self: center;
}
.flex-vertical-align-bottom {
    align-self: bottom;
}
//End general flex and alignment options

svg tspan.error{
	display:none;
}
.author {
	svg tspan.error{
	    display: initial;
		fill: red;
	    font-weight: bold;
	    font-size: 64px;
	}
}
//Used for ellipsis stuff, if it was not obvious
.ellip {
    display: block;
    height: 100%;
}

.ellip-line {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    max-width: 100%;
}

.ellip,
.ellip-line {
    position: relative;
    overflow: hidden;
}

