//eventon - reference to the eventon wordpress plugin that is installed on events.flinders.edu.au (flindersevents.wpengine.com)


//******* General Layout styles ********* //   
.component_section_item {
    position: relative;
    .component_section_news_wrap {
        padding: 1rem;
        .news_date {
            margin-bottom: 1rem;
            position: relative;
            &:after {
                position: absolute;
                content: '';
                top:0;
                left: 0;
                width: 34px;
                height: 3px;
                background: $yellow;
            }
            .news_date_month {
                font-size: 1.8rem;
                font-weight: $semibold;
                line-height: 2.5rem;
                display: block;
                
                i {
                    font-style: normal;
                    text-transform: uppercase;
                    font-size: $font-size-xs;
                    display: block;
                    line-height: 0.7rem;
                    font-weight: bold;
                    padding-left:2px;
                }
            }
        }
        h3 {
            overflow: hidden;
        }
        .news_date {
            line-height: 1rem;
            color: $black;
        }
        .classic_white_button_navy_border__small {
            white-space: nowrap;
        }
    }
}

.hideMoreButton {
    display:none;
}



//******* Overlay Layout ********* //   

.component_section_item {
    .news-overlay-layout {
        .flex-container {
            flex-direction: column;
            justify-content:space-between;
        } 
        .component_section_news_wrap {
            border: 1px solid $gray-light-flinders;
        }
        .news_overlay {
            border: none;
        }
    }
    .news_overlay  {
            background: linear-gradient(rgba(0,0,0,0.05), rgba(0,0,0,0.8) 100%);
         .news_v2_date, .news_date, .eventon_place, h3, .news_show_excerpt {
                 color:$white;
         }
        .news_title{
            display: flex;
            align-items:flex-end;
            flex-grow:2;
        }
        .news_details {
            align-self:flex-end;
        }
    }
    .news_overlay_excerpts_line {
         margin-bottom: 1rem;
    }
}   

//******* END Overlay Layout ********* //    


//******* Standard Layout ********* //

.news-standard-layout {
  border: 1px solid $gray-light-flinders;
    .news_image{
        display:none;
    }
    .flex-container {
        height:300px;
        flex-direction: column;
        justify-content: space-between;
        .component_section_news_inner_wrap{
            display:flex;
            flex-grow:2;
            flex-direction: column;
            justify-content:space-around;
            .news_details {
                align-self:flex-end;
            }
        }
    }
    
}

.showFeature{
    .news-standard-layout {
        .news_image{
            display:block;
            width:100%;
            height:230px;
        }
    }
}


.container_fullwidth {
     .news-standard-layout {
        .news_image{
            height:400px;
        }
    }
}


.no-gutter {
    .news-overlay-layout{
        margin-bottom:0px;
    }
}

//******* END Standard Layout ********* //    



//******* Media breakpoints ********* // 

@include media-breakpoint-up(lg) {

    .news-standard-layout {
        .news_image{
            height:200px;
        }
    }

    .news-overlay-layout {
        .news_image {
            height:300px;
        }
    }

    .container_fullwidth {
        .news-standard-layout {
             .news_image{
                height:230px;
            }
        }
        .news-overlay-layout {
            .news_image{
                height:300px;
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .news-standard-layout {
        .news_image{
            height:240px;
        }
    }
    
    .container_fullwidth {
         .news-standard-layout {
             .news_image{
                height:300px;
            }
        }
        .news-overlay-layout {
            .news_image{
                height:300px;
            }
        }
    }

    .news-overlay-layout {
        .news_image {
            height:300px;
        }
    }

}

@include media-breakpoint-down(md) {

    .news-standard-layout {
        .news_image{
            height:300px;
        }
    }


    .container_fullwidth {
         .news-standard-layout {
             .news_image{
                height:330px;
            }
        }
        .news-overlay-layout {
            .news_image{
                height:300px;
            }
        }
    }

    .news-overlay-layout {
        margin-bottom:1rem;
        .news_image {
            height:300px;
        }
    }  

    .news-standard-layout  {
        margin-bottom:1rem;
    } 



}

@include media-breakpoint-down(sm) {

    .news-standard-layout {
        .news_image{
            height:300px;
        }
    }

    .container_fullwidth {
        .news-standard-layout {
             .news_image{
                height:300px;
            }
        }
        .news-overlay-layout {
            .news_image{
                height:300px;
            }
        }
    } 

    .news-overlay-layout {
        .news_image {
            height:300px;
        }
    }

}









