@mixin linear-gradient($from, $to, $direction:'horizontal') {

	@if $direction == 'horizontal' {
		background: $to;
		background: -moz-linear-gradient(top, $from 0%, $to 100%);
		background: -webkit-gradient(linear, left top, right top, color-stop(0%,$from), color-stop(100%,$to));
		background: -webkit-linear-gradient(right, $from 0%,$to 100%);
		background: -o-linear-gradient(right, $from 0%,$to 100%);
		background: linear-gradient(to right, $from 0%,$to 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#{$from}, endColorstr=#{$to});
	} @else {
		background: $to;
		background: -moz-linear-gradient(top, $from 0%, $to 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
		background: -webkit-linear-gradient(top, $from 0%,$to 100%);
		background: -o-linear-gradient(top, $from 0%,$to 100%);
		background: linear-gradient(to bottom, $from 0%,$to 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
	}
	
}