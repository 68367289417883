/* Staff portal css */
.small_header {
	padding-top: 2rem !important;
	padding-bottom: 1rem !important;
	bottom: 0 !important;
}

.set_height {
	height: 100px !important;
	bottom: 0 !important;
}

.navy_blue_container {
	background: $light_blue;
}

.breadcrumb_blue nav {
	background: $light_blue;
}

.sub_site_header {
  .navigation-global {
    padding-top: calc(#{$header_height} + #{$header_site_height});
    @include media-breakpoint-between(xs, md) {
      padding-top: $header_height;
    }
  }

	.head_inner_second_line {
		background: $light_blue;
	}

	.global_header {
		background: $light_blue;
		//position: relative;
	}

	.sectionfixed.global_header {
		position: fixed;
	}
	.sectionfixed & {
		.header_global_search_form {
			top: calc(#{$header_height} + #{$header_site_height})
		}
	}

	.header_active {
		background: $light_blue !important;

		.sectionfixed & {
			//background: $flinders-gold !important;
			.flinders_logo_black {
				//display: inline-block;
			}
			.flinders_logo_white {
				display: inline-block;
			}
			.header_quicklink {
				height: $header_height;
				//color: black;
				&:hover {
					//color: $black;
				}
			}
			.header_search_top {
				//display: inline-block;
				//height: $header_height;
				.material-icons {
					//color: black;
				}
				>a {
					height: $header_height;
					display: inline-block;
				}
				&.active {
					&:after {
						//background-color: $black;
					}
				}

			}
			.open_row {
				.header_quicklink, .nav_top_link {
					&:before {
						//background-color: $black;
					}
				}
			}
			.header_quicklink_section .open_row.mini_mega_menu>.dropdown-menu {
				top: $header_height;
			}
			.top_main_navigation.navbar-nav .nav-item>a {
				color: $white;
			}

		}
	}
	.open_row .header_quicklink:before {

	}

	.top_main_navigation_wrap {
		@include media-breakpoint-down(md) {
			background: $light_blue !important;
		}
	}

	.logo_line.header_active.search_transorm_dark_bg {
		background: $light_blue !important;
        &.yellow_header {
            background: $flinders-gold !important;
        }
		.sectionfixed & {
			//background: $flinders-gold !important;
		}
		//&:after {
		//	background: none;
		//}
	}

	.header_image_component {
		overflow: visible;
	}

	.logo_line {
		@include media-breakpoint-down(md) {
		background: $light_blue !important;
		}
	}

	.yellow_header {
		width: 100%;
		position: fixed;
	}

	.header_site {
		background-color: $flinders-gold;
	}

	.header_sitelink {
		color: black;
	}

	.header_sitelink_section_active {
		background: $light_blue;
		a span {
			color: $white;
		}
	}
	.header_site {
		position: relative !important;
	}
	.global_header {
		top: 0 !important;
	}

}
