//TODO - remove - possibly still used in header search...
.search_page_body,
.header_global_search_form {
    table {
        display: table;
        margin: 0;
        thead {
            th {
                vertical-align: bottom;
                border: none;
                font-weight: normal;
            }
        }
        tbody {
            tr {
                &:nth-of-type(odd) {
                    td, th {

                        background: none;
                        border: none;
                        padding: 0;
                        white-space: inherit;
                    }
                    &:hover {
                        td, th {
                            background: none;
                            border: none;
                            padding: 0;
                            white-space: inherit;
                        }
                    }
                }
                &:nth-of-type(even) {
                    td, th {
                        background: none;
                        border: none;
                        padding: 0;
                        white-space: inherit;
                    }
                    &:hover {
                        td, th {
                            background: none;
                            border: none;
                            padding: 0;
                            white-space: inherit;
                        }
                    }
                }
                &:first-child {
                    th {
                        background: none;
                        border: none;
                        padding: 0;
                        white-space: inherit;

                    }
                    &:hover {
                        th {
                            background: none;
                        }
                    }

                }
            }

        }
    }
}

$search-border-colour: $gray-light-flinders-display3;
$search-tabs-spacing: 4px;

.basic-search {
  &__heading {
    font-size: 0.7rem;
    font-weight: 600;
    font-family: $font_family-titles;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  &__form {
    position: relative;
    box-shadow: none;
    border: 1px solid $search-border-colour;
    width: 100%;
    font-size: 20px;
    outline: none;
    letter-spacing: 0.6px;
    margin-top: auto;
    margin-bottom: 1rem;
    margin-left: auto;
    line-height: 20px;
    text-rendering: optimizeLegibility;
    border-radius: 0;
    -webkit-appearance: none; //for legacy ios, apparently...
    &:hover {
      box-shadow: none;
    }
  }
  &__input {
    width: 100%;
    border: none;
    padding: 14px 42px 14px 0.6rem;
    line-height: 20px;
    text-rendering: optimizeLegibility;
  }
  &__submit {
    height: 20px;
    width: 20px;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    bottom: 0;
    padding:  0;
    text-align: center;
    margin: 14px;
    cursor: pointer;
    .material-icons {
      line-height: 20px;
      color: $search-icon-grey;
    }
  }
}

.sajari-search {
    &__heading {
        font-size: 0.7rem;
        font-weight: 600;
        font-family: $font_family-titles;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    /*Input styles*/
    .sj-input {
        background-color: white;
        &__input {
            color: $black;
            padding: 0.4rem 0.6rem;
            box-shadow: none;
            border: 1px solid $search-border-colour;

            width: 100%;
            font-size: 20px;
            outline: none;
            letter-spacing: .6px;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            line-height: normal;
            text-rendering: optimizeLegibility;
            border-radius: 0;
            &:hover {
                box-shadow: none;
            }
            &.sj-input__input--focused {

            }
        }
        /*Button styles*/
        &__button {
        }
        /*Typeahead mode selector*/
        &__typeahead {

        }
        /*Suggestions dropdown selectors*/
        &__suggestions {
            color: $black;
            &__item {

            }
        }
    }
    //Pipeline container?
    .sj-pipeline-response {
        padding-left: 0;
        padding-right: 0;
    }
    //Tabs styles
    .sj-tabs {
        border-bottom: none;
        overflow: hidden;
        background-color: $gray-light-flinders;
        >div {
            margin-bottom: -$search-tabs-spacing;
            white-space: normal;
            @include media-breakpoint-down(md) {
                margin-bottom: 1.5rem;
            }
            @include make-container();
            @include make-container-max-widths;
            &:before {
                content: "Filter by: ";
                color: $black;
                margin-right: 1.5rem;
                padding-top: 0.45rem;
                padding-bottom: 0.5rem;
                @include media-breakpoint-down(md) {
                    display: block;
                }
            }
        }

        &__tab {
            background-color: $gray-flinders;
            transition: background-color 0.3s;
            color: $black;
            margin-right: $search-tabs-spacing;
            margin-bottom: $search-tabs-spacing;
            border-bottom: none;
            &:hover {
                background-color: darken($gray-flinders, 10%);
            }
            &--selected {
                background-color: $flinders-gold;
                border-bottom: none;
                &:hover {
                    background-color: darken($flinders-gold, 5%);
                }
            }
        }

    }

    //summary styles
    .sj-summary {
        width: 100%;
        margin-top: 5px;
        @include make-container();
        @include make-container-max-widths;
        &__results-text {}
        &__search-term {}
        &__query-time {}
        &__query-override {}
    }

    /*Results styles*/
    .sj-results__result {
        @include make-container();
        @include make-container-max-widths;
        &__title {}
        &__description {}
        &__link {}
        &__image {}
    }
    /*Paginator styles*/
    .sj-paginator {
        @include make-container();
        @include make-container-max-widths;
        &__page-button {
            //Next or prev buttons
            &:focus {
                outline-width: 1px;
            }
        }
        &__page-number {
            &.sj-paginator__page-number--current {
                color: $white;
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

//QUICKSEARCH (AKA DROPDOWN SEARCH)
$quicksearch-result-selected-bg: #ededed;
$dropdown_list_padding: 4px;
$select-2-input-field-height: 26px;
$select-2-input-height: 40px;

.quicksearch {
    position: relative;
    //Styles outside the generated styles of select2
    &__select {}
    &__label {
        cursor: pointer;
        font-size: 0.7rem;
        font-weight: 600;
        font-family: $font_family-titles;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
    &__button {
        height: $select-2-input-height;
        width: $select-2-input-height;
        position: absolute;
        right: 0;
        bottom: 0;
        padding:  0;
        text-align: center;
        .material-icons {
            line-height: $select-2-input-height;
            color: $search-icon-grey;
        }
    }
}

//Bold marked items
.select2-results__option .select2-rendered__match, .select2-results__group .select2-rendered__match {
    font-weight: bold;
    background-color: transparent; //Override the <mark> tag background-colour
}

.select2-container{
  font-family: $font_family-titles;
  //Remove the focus outline, because it's super ugly!
  *:focus{
    outline:0;
  }
}

.select2.select2-container.select2-container--flinders {
    height: $select-2-input-height;
}



.select2-container--flinders{
    &.select2-container--open {
        .select2-dropdown--below {
          border-top: none;
        }
        .select2-dropdown--above {
          border-bottom: none;
        }
        .select2-dropdown--below, .select2-dropdown--above {
            background: $white;
            max-height: 500px;
            z-index: $z-index-quicksearch-popup;
            overflow: auto;
            border-radius: 0;
            border-color: $flinders-gold;

            &.select2-dropdown__height {
                &--150 {
                    max-height: 150px;
                }
                &--200 {
                    max-height: 200px;
                }
                &--250 {
                    max-height: 250px;
                }
                &--300 {
                    max-height: 300px;
                }
                &--350 {
                    max-height: 350px;
                }
                &--400 {
                    max-height: 400px;
                }
                &--450 {
                    max-height: 450px;
                }
                &--500 {
                    max-height: 500px;
                }
            }
        }
    }
    .select2-results {
        //Group Headings
        &__group {
            font: 100 1.525rem Open Sans,sans-serif;
            line-height: 1.525rem;
            padding-bottom: $dropdown_list_padding;
            padding-left: $dropdown_list_padding;
        }

        &__option {
            &--highlighted[aria-selected]{
                background: $quicksearch-result-selected-bg !important;
                transition: background-color 0.3s;
                color: $black;
            }
            &[aria-selected=true]{
                background: $quicksearch-result-selected-bg;
                transition: background-color 0.3s;
                color: $black;
            }
            &[role="group"] {
                padding-bottom: 10px;
            }
            &[role="treeitem"] {
                color: $charcoal_black-display1;
                font: 400 14px Open Sans, sans-serif;
                line-height: 20px; //Note(JD): firefox and chrome have different "normal" line heights, so we need to be explicit sometimes
            }
        }
        &__options {
            //Option*s* is the group of the above "option" items
            &--nested {
            }
        }
        //Message displayed when no results found.
        &__message {
            margin-top: $dropdown_list_padding;
        }
    }
    .select2-search {
        &--inline {
            width: 100%;
        }
        &__field {
            width: 100% !important;
            height: $select-2-input-field-height;
        }
    }
}

.select2-container--flinders{
    .quicksearch {
        &__midyear-icon {
            vertical-align: bottom;
        }
    }
    .select2-selection--multiple{
        border: 1px solid $flinders-gold;
        background-color: white;
        .select2-selection__clear {
            display: none;
        }

        .select2-selection__choice__remove:hover{
            color: $gray-flinders;
            cursor: pointer;
        }
        .select2-selection__rendered {
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 0 8px;
            width: 100%;

        }
        .select2-selection__choice{
            cursor: default;
            float: left;
            margin-right: 5px;
            margin-top: 5px;
        }
        .select2-selection__choice__remove{
            color: $gray-flinders;
            margin-right: 6px;
            margin-left: 6px;
            float: left;
        }
    }
}


