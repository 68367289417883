.header_search_top {
    cursor: pointer;
    vertical-align: top;
    .material-icons {
        font-size: 1.9rem;
        color: $white;
        line-height: $header_height;
    }
}

.button_burger {
    display: none;
}
.head_inner_horisontal {
    & > [class^="col-"],
    & > [class*="col-"],
    .navbar,
    .dropdown {
        position: static;
    }
    .navbar {
        padding: 0
    }
}

.navbar-static-top {
    display: inline-block;
}

.link_default {
    color: $black;
    text-decoration: none;
}

.link_default_white {
    color: $white;
    text-decoration: none;
}


.transparent_layer_after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
    //For section image background overlay styles.
    //Produces e.g. "layer_colour_navy_40"
    &.layer_colour {
        @each $colour-name, $color in $transparency_colour_options {
            &_#{$colour-name} {
                @each $transparency-name, $transparency-value in $transparency_amount_options {
                    &_#{$transparency-name} {
                        background-color: transparentize($color, $transparency-value);
                    }
                }
            }
        }
    }
}
.secton_background_details {
    overflow: hidden;
    background-repeat:no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: $black;
}
//Background position is x then y i.e. left and right then top and bottom
//NOTE: Top and bottom position class rules are duplicated as the notation "background-position-y" is non-standard (although widely supported).
.secton_background{
    @extend .secton_background_details;
    background-position: center center;
    &__left_position {
        @extend .secton_background_details;
        background-position: left center;
        &.top_position {
            background-position: left top;
        }
        &.bottom_position {
            background-position: left bottom;
        }
        &.transparent_layer {
            position: relative;
            z-index: 1;
            &:after {
                @extend .transparent_layer_after;
            }
        }
    }
    &__right_position {
        @extend .secton_background_details;
        background-position: right center;
        &.top_position {
            background-position: right top;
        }
        &.bottom_position {
            background-position: right bottom;
        }
        &.transparent_layer {
            position: relative;
            z-index: 1;
            &:after {
                @extend .transparent_layer_after;
            }
        }
    }
    &.top_position {
        background-position: center top;
    }
    &.bottom_position {
        background-position: center bottom;
    }
    &.transparent_layer {
        position: relative;
        z-index: 1;
        &:after {
            @extend .transparent_layer_after;
        }
    }
}
.secton_color__white {
    color: $white;
    .cta-button{ a , a:focus{ color: $black; } }
}


.nav_top_link {
    @extend .link_default_white;
    font-size: $font-size-sm;
    &:visited {
        color: $white;
    }
    &:hover {
        @extend .link_default_white;
    }
    &:active {
        color: $white;
    }
    &:focus {
        text-decoration: none;
    }
}

.header_logo_place {
    position: absolute;
    top: 10px;

    a {
        width: 100%;
        height: 100%;
        display: inline-block;
    }
}

.logo_line {
    height: $header_height;
    width: 100%;
}

.header_quicklink {
    @extend .nav_top_link;
    font-size: 0.8125rem;
    font-weight: $display-weight-normal;
    display: inline-block;
    &:visited {
        color: $white;
    }
    .material-icons {
        font-size: 1.8rem;
        vertical-align: middle;
        line-height: $header_height;
    }
}



.internal_links {
    a {
        @extend .nav_top_link;
        font-size: $font-size-xs;
    }
}

.header_burger {
    background: none;
    border: none;
    vertical-align: middle;
    margin-left: 6%;
    cursor: pointer;
    .icon-bar {
        width: 20px;
        height: 3px;
        background: $white;
        display: block;
        margin: 3px 0;
    }
}

.global_header {
    position: fixed;
    left: 0;
    top: $header_site_height;
    width: 100%;
    z-index: 100;
}

/*
.logo_line,
.sub_global_header {
    background: rgba(0,0,0,0.5);
}*/
.yellow_header {
    /*background: $yellow;*/
}

.flinders_logo_black,
.yellow_header .flinders_logo_white {
    display: none;
}

.yellow_header .flinders_logo_black {
    display: inline-block;
}

.header_active {
    background: $blue !important;
    @include media-breakpoint-up(lg) {
        .header_style_transparent & {
            background: transparent !important;
        }
    }

    .flinders_logo_white {
        display: inline-block;
    }

    .flinders_logo_black {
        display: none;
    }
}
/**************************search header**************************/
.header_global_search_form {
    width: 100%;
    background: $gray-light-flinders-display6;
    position: absolute;
    left: 0;
    top: $header_height;
    height: 64px;
    display: none;
    z-index:3;
    input[type="text"] {
        background: $white;
        color: $blue;
        border: none;
        font-size: $font-size-h5;
        line-height: 1.24;
        padding-left: 0;
        margin: 15px 15px 15px 15px;
        height: 34px;
        @include placeholder {
            opacity: 1 !important;
            color: $gray-search;

        }
        &:focus {
            outline-width: 0;
            outline: none;
            border: none;
        }
    }
}
header {
    .global_header {
        .gsc-search-box {
            padding: 0;
            position: relative;
            width: 100%;
            td {
                padding: 0;
                display: inline;
                input[type="text"] {
                    width: 100%;
                    margin: 0;
                    padding: 0 0 0 80px;
                    height: 64px;
                    background: $gray-light-flinders-display6 !important;

                }
                .gsc-input-focus {
                    height: 65px;
                }
                input[type="button"].gsc-search-button {
                    border: none;
                    position: absolute;
                    left: 1px;
                    float: left;
                    top: 0;
                    border-right: 1px solid $blue;
                    text-index:-999px;
                    border-radius: 0;
                    height: 64px;
                    width: 64px;
                    min-width: 25px;
                    background: $gray-light-flinders-display6 url(../resources/images/search-icon.png) no-repeat center center;
                    text-indent: -9999px;
                    z-index: 110;
                    padding: 0;


                }
                .gsc-clear-button {
                    display: none;
                }
            }
            .gsc-input {
                padding-right: 0;
            }
        }
    }
}



.global_header {
    .container {
        position: relative;
    }
}
.header_global_search_close {
    cursor: pointer;
}
.header_global_search_insert {
    padding-left: 0;
    position: relative;
    .material-icons {
        color: $blue;
        position: relative;
        right: 0;
        top: 18px;
        font-size: 1.7rem;
    }
}
.top_main_navigation {
    .nav_top_link {
        line-height: $header_height;
        display: inline-block;
        font-weight: $display-weight-normal;
        &.display_none {
            display: none;
        }
    }
}
.header_right {
    padding-top: 19px;
}
.dropdown_menu__list {
    list-style-type: none;
    @extend .default_indents;
}
.quicklink_section_mv {
    .dropdown-backdrop {
        display: none;
    }
}
.dropmenu_section {
    .dropdown_menu__list {
        li {
            margin-bottom: 0.5rem;
            a {
                @extend .link_default;
                font-weight: $display-weight-normal;
                font-size: $font-size-fln-h5;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
.dropdown_buttons_line {
    margin-right: -10px;
    > div.row {
        padding: 1rem 0;
        background-color: $yellow;
    }
}
.header_quicklink_section {
    list-style-type: none;
    margin: 0 30px 0 0;
    padding: 0;
    > li {
        margin: 0;
    }
}
.dropdown_buttons_line {
    .black_transparent_button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        &:before {
            top: 30%;
        }
        &:hover {
            &:before {
                color: $yellow;
            }
        }
    }
}

.header_search_top.active {
    position: relative;
    &:after {
        position: absolute;
        content:'';
        width: 120%;
        left: -10%;
        bottom: 0;
        height: 6px;
        background: $yellow;
    }
    i {
        color: $yellow;
    }
}
.sectionfixed {
    header {
        .head_inner_second_line {
            .header_search_top.active {
                &:after {
                    background: $black;
                }
            }
        }

        .navigation-section {
            padding-top: 64px;
        }
    }
}
.li_dropdown_section {
    position: absolute;
    content:'';
    top: 100%;
    left: 0;
    height: 200px;
    width: 100%;
}
.top_main_navigation_wrap {
    .dropmenu_section_blue {
        position: relative;
        &:after {
            @extend .li_dropdown_section;
            background: $blue;
        }
        .head_list_heading::before {
            background: $white;
        }
    }
    .dropmenu_section_yellow {
        position: relative;
        &:after {
            @extend .li_dropdown_section;
            background: $yellow;
        }
    }
    .gray_light_container-d1 {
        position: relative;
        &:after {
            @extend .li_dropdown_section;
            background: $gray-light-flinders-display1;
        }
    }
}
.main_page_body,
.body_sub_page {
    .gstl_50.gssb_c {
        position: fixed !important;
        top: $header_height + 64px !important;
    }
}

.body_sub_page.sectionfixed {
    .gstl_50.gssb_c {
        position: fixed !important;
        top: 64px + 64px !important;
    }
}
.logo_line.header_active.search_transorm_dark_bg {
    position: relative;
    &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: $header_height;
        background: rgba(0,0,0,0.5);
        z-index:11;
    }
    .header_search_top {
        z-index:13;
    }
}
.head_inner_second_line {
    .logo_line.header_active.search_transorm_dark_bg {
        &:after {
            height: 64px;
        }
    }
}


@include media-breakpoint-up(lg) {
    .header_style {
        &_transparent {
            &.main_header_container {
                height: 434px; //300 + (70+64)
            }
            > div:first-child {
                //top navigation menus, it's a reference component so who knows it's structure?
                position: relative;
                z-index: 2;
            }
            > .navigation-section {
                //the second line of navigation menus, which is what gets fixed.
                position: relative;
                z-index: 1;
                .sectionfixed &{
                    z-index: 111;
                }
            }
            .normal_header_container {
                position: relative;
                top: -134px; //70+64
            }
            //Second menu line
            .header_image_component,.header_image_component.transparent_layer, .component_section.transparent_layer, .header_section_component {
                z-index: 0;
                height: 434px; //300 + (70+64)
                &>.position_bottom {
                    bottom: 6rem;
                }
                .author & {
                    margin-top: 0;
                }
            }
            //Breadcrumb
            .breadcrumb {
                margin-top: -60px; //Height of breadcrumb
                background: transparent;
                .author & {
                    margin-top: 0;
                }
            }
        }
    }
    /******************header -> dropdown menu *******************/
    .yellow_header {
        .top_main_navigation.navbar-nav {
            .nav-item {
                > a {
                    color: $black;
                }
            }
        }
        .header_quicklink {
            color: $black;
        }
        .header_sitelink {
            color: $black;
            span {
                color: $black;
            }
        }
        .header_search_top {
            i {
                color: $black;
            }
        }
        svg.save-course{
            .Heart{fill:$black;}
            .Oval{fill:$black;}
        }

        .domIntIcons
        {
            .globe{fill:$black};
            .aus-map{fill:$black;}
        }

    }

    .top_main_navigation.navbar-nav {
        display: inline-block;
        margin: 0 auto 0 130px;
        vertical-align: top;
        .nav-item {
            margin-left: 0;
            > a {
                padding: 0 1rem;
                font-weight: 600;
            }
        }
    }
    .header_burger {
        i {
            display: none;
        }
    }
    .author.main_page_body {
        .global_header {
            background: $blue;
            position: static;
        }
    }
    .author.body_sub_page {
      .global_header {
        background: $blue;
        position: static;
      }
    }
    .main_page_body  {
        .logo_line {
            &.yellow_header {
                &.header_active {
                    background: $yellow !important;
                    .top_main_navigation.navbar-nav{
                        .nav-item {
                            > a {
                                color: $black;
                                &:before {
                                    background: $black;
                                }
                            }
                        }
                    }
                    .header_quicklink {
                        color: $black;
                        &:hover {
                            color: $black;
                        }
                        &:before {
                            background: $black;
                        }
                    }
                    .header_sitelink {
                        color: $black;
                        span {
                            color: $black;
                        }
                        &:hover {
                            color: $black;
                        }
                        &:before {
                            background: $black;
                        }
                    }
                    .header_search_top {
                        &.active {
                            &:after {
                                background: $black;
                            }
                            i {
                                color: $white;
                            }
                        }
                        i {
                            color: $black;
                        }
                    }
                    .flinders_logo_white {
                        display: none;
                    }
                    .flinders_logo_black {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .open {
        .dropdown-menu {
            display: none;
        }
    }
    .open_row {
        > a {
            color: $yellow;
        }
        .dropdown-menu {
            display: block;
            border: none;
            margin: 0;
            @include border-radius(0);
            top: $header_height;
            width: 100%;
            padding-top: 0;
            padding-bottom: 0;
        }

    }
    .top_main_navigation  {
        > li {
            >  .dropdown-menu {
                overflow: hidden;
            }
        }
    }
    .open_row {
        .dropmenu_section_wrapper {
            position: relative;
            //height: 435px;
        }
        .dropmenu_section {
            background: $white;
            height: calc(100% - 5.3125rem);

            .dropdown_menu__list {
                position: relative;
                background: $white;
                top: 0;
                padding: 0;
                margin: 0;
                li {
                    > a {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
            &.dropmenu_section_white {
                background: $white;
                .dropdown_menu__list {
                    background: $white;
                    li a {
                        color: $charcoal_black-display2;
                    }
                }
            }
            &.dropmenu_section_yellow {
                background: $yellow;
                .dropdown_menu__list {
                    background: $yellow;
                    li a {
                        color: $blue;
                    }
                }
            }
            &.dropmenu_section_blue {
                background: $blue;
                .dropdown_menu__list {
                    background: $blue;
                    li a {
                        color: $white;
                    }
                }
            }
            &.gray_light_container-d1 {
                background: $gray-light-flinders-display1;
                .dropdown_menu__list {
                    background: $gray-light-flinders-display1;
                }
            }
        }
    }
    .dropmenu_section {
        .dropdown_menu__list {
            li.head_list_heading {
                margin-bottom: 1rem;
            }
        }
    }
    .head_list_heading {
        position: relative;
        margin: 0;
        a,
        span {
            height: 95px;
            overflow: hidden;
            position: relative;
            color: $charcoal_black-display2;
            font-weight: $semibold;
            font-size: $font-size-fln-h4;
            font-family: $font_family;
            //height: (($font-size-lg * $line-height-lg) + ($global-line-height * 3));
            padding: 1.2rem 1rem 0;
            display: block;
            text-decoration: none;
            z-index:1;
            cursor: default;
            &:after {
                width: 100%;
                background: none;
                content:'';
                height: 2rem;
                left: 0;
                bottom: -0.3rem;
                z-index:1;
                position: absolute;
            }
            &:before {
                position: absolute;
                content: '';
                background: $charcoal_black-display2;
                width: 100%;
                left: 0;
                height: 1px;
                bottom: 1rem;
                z-index:2;

            }
        }
    }
    .dropmenu_section_white {
        .head_list_heading {
            a,
            span {
                color: $charcoal_black-display2;
                &:before {
                    background: $charcoal_black-display2;
                }
            }
        }
    }
    .dropmenu_section_yellow {
        .head_list_heading {
            a,
            span {
                color: $blue;
                &:before {
                    background: $blue;
                }
            }
        }
    }
    .dropmenu_section_blue {
        .head_list_heading {
            a,
            span {
                color: $white;
                &:before {
                    background: $white;
                }
            }
        }
    }
    .subpage_global_header {
        background: $blue;
        .header_style_transparent & {
            background: transparent;
        }
        .global_header {
            position: static;
        }
        .header_site {
            position: relative;
        }
    }
    .second_level_menu {
        position: absolute;
        left: 0;
        top: 96px;
        width: 100%;
        height: 60px;
        background: $blue;
    }
    .open_row {
        .nav_top_link,
        .header_quicklink {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                left: 0;
                background: $yellow;
                height: 6px;
                z-index: 1111;
                bottom: 0;
            }
        }
    }
    .global_header {
        .top_main_navigation.navbar-nav{
            .nav-item.open_row {
                > a {
                    &:before {
                        width: inherit;
                        left: 1rem;
                        right: 1rem;

                    }
                }
            }
        }
    }
    .yellow_header {
        .open_row {
            .nav_top_link,
            .header_quicklink {
                &:hover {
                    color: $black;
                }
            }
        }
    }
    .yellow_header.header_active {
        .open_row {
            .nav_top_link,
            .header_quicklink {
                &:hover {
                    color: $white;
                }
            }
        }
    }

    /**********subheader**************/
    .head_inner_second_line {
        .subheader_right_search {
            display: none;
        }
    }
    .head_inner_second_line {
        height: 64px;
        .header_style_transparent & {
            background: transparent;
        }
        .logo_line {
            height: 64px;
            .container {
                position: relative;
                .top_main_navigation.navbar-nav {
                    margin-left: 0;
                    .nav-item {
                        padding-right: 1rem;
                        > a {
                            padding: 0;
                            line-height: 64px;
                            font-weight: normal;

                        }
                        .material-icons {
                            color: #667680;
                            .header_style_transparent & {
                                color: $white;
                                .sectionfixed & {
                                    color: #667680;
                                }
                            }
                            margin-left: 0;
                            margin-right: 0;
                            vertical-align: middle;
                            font-size: 1rem;
                        }
                        &:first-child {
                            > a {
                                font-weight: $semibold;
                            }
                        }
                    }
                    .mini_mega_menu {
                        .dropdown_menu__list {
                            li {
                                margin-left: 0;
                                margin-right: 0;
                                a {
                                    padding: 10px 15px;
                                }
                            }

                        }

                    }
                    //open_row start
                    .open_row {
                        .material-icons, > a {
                            color: $yellow;
                            .sectionfixed & {
                                color: $black;
                            }
                            .header_style_transparent & {
                                color: $yellow;
                                .sectionfixed & {
                                    color: $black;
                                }
                            }
                        }
                        > .dropdown-menu {
                            top: 64px;
                        }
                        .sectionfixed & {
                            .nav_top_link,
                            .header_quicklink {
                                &:before {
                                    background: $black;
                                }
                            }
                        }
                    } //open_row end
                }
            }
        }
    }
    .sectionfixed {
        .head_inner_second_line {
            .search_transorm_dark_bg {
                .container {
                    .head_inner_horisontal {
                        .subheader_right_search {
                            .header_search_top {
                                i {
                                    color: $white;

                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .head_list_heading_empty {
        padding-top: (($font-size-lg * $line-height-lg) + ($global-line-height * 3)) + 1rem;

    }
    /******************header -> subpage ***********************/

    .head_inner_second_line {
        top: -50px;
    }
    .sectionfixed {
        .header_global_search_form {
            position: fixed;
            top: 64px;
        }
        .head_inner_second_line {
            position: fixed;
            width: 100%;
            top: 0;

            -webkit-animation: fadein $fadein-transition-time; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: fadein $fadein-transition-time; /* Firefox < 16 */
            -ms-animation: fadein $fadein-transition-time; /* Internet Explorer */
            -o-animation: fadein $fadein-transition-time; /* Opera < 12.1 */
            animation: fadein $fadein-transition-time;

            background: $yellow;
            .header_active {
                background: $yellow !important;
                .header_style_transparent & {
                    background: transparent !important;
                }
                .subheader_right_search {
                    display: block;
                }
                .top_main_navigation.navbar-nav {
                    li  {
                        a {
                            color: $black;
                        }

                    }

                    .open_row {
                        > a {
                            color: $black;
                        }
                        i {
                            color: $black;
                        }
                        .dropmenu_section_blue {
                            .head_list_heading::before {
                                background: $white;
                            }
                            a {
                                color: $white;
                            }
                        }
                    }
                }
                .open_row {
                    .header_quicklink {
                        color: $black;
                    }
                }
                .header_search_top {
                    .material-icons {
                        color: $black;
                    }
                }
                .header_quicklink {
                    color: $black;
                }
            }

            .subheader_right_search {
                display: block;
            }
        }
        .sub_global_header {
            margin-bottom: 60px;
        }
    }
    .sectionfixed {
        .header_quicklink {
            i {
                line-height: 64px;
                color: $black;
            }
        }
        .header_search_top {
            .material-icons {
                line-height: 64px;
            }
        }
    }
    .logo_line.sublogoline {
        background: $blue !important;
        .header_style_transparent & {
            background: transparent !important;
        }
    }
    .sectionfixed {
        .nav_top_link {
            color: $black;
        }
        .header_active {
            .nav_top_link, .header_search_top .material-icons {
                color: $white;
            }
        }
        .open_row {
            .nav_top_link {
                color: $yellow;
            }
        }
    }

    .sectionfixed {
        .subheader_right_search {
            display: block;
        }
    }
    .sectionfixed {
        .header_search_top {
            .material-icons {
                color: $black;
            }
        }
    }
    .subpage_global_header {
        .top_main_navigation {
            > li {
                > a.active {
                    color: $gray-search;
                    cursor:default;
                    &:hover {
                        color: $gray-search;
                    }
                }
            }
            .open_row {
                > a.active {
                    &:before {
                        display: none;
                    }
                    &:hover {
                        color: $gray-search;
                    }
                }
                a.active + div.dropdown-menu {
                    display: none;
                }
            }
        }
    }
    .header_active {
        .top_main_navigation.navbar-nav {
            .nav-item {
                > a {
                    color: $white;
                }
                &.open_row > a {
                    color: $yellow;
                }
                .active {
                    color: $gray-search !important;
                }
            }
            .active {
                > a {
                    color: $gray-search;
                    cursor:default;
                }
            }
        }
        .header_quicklink {
            color: $white;
        }
        .header_search_top {
            i {
                color: $white;
            }
        }

    }

    .sectionfixed {
        .logo_line.header_active.search_transorm_dark_bg {
            .header_search_top {
                color: $yellow;
            }
        }
    }

    .dropdown-submenu {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 90px;
        width: 100%;
        height: 79px;
        .nav-item {
            > a {
                color: $white;
                font-size: $font-size-base;
            }
        }
    }
    .header_active + .container {
        .dropdown-submenu {
            .nav-item {
                > a {
                    color: $black;

                }
            }
        }
    }

    .quicklink_subsection {
        position: absolute;
        right: 0;
        top: 90px;
        z-index: 11112;
        padding-top: $btn-padding-x-lg;
    }
    .bottom_band_menu_mv {
        display: none;
    }
    .open_row.mini_mega_menu {
        top: 0;
        position: relative;

        .dropdown-menu {
            min-width: 280px;
            width: auto;
            left: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .open_row.mini_mega_menu {
        .dropdown_menu__list {
            margin-bottom: 0;
            li {
                margin: 0 -15px;
                a {
                    padding: 15px;
                    display: block;
                    &:hover {
                        background: $yellow;
                        text-decoration: none;
                        color: $black;
                    }
                }
            }
        }
    }
    .header_quicklink_section {
        .open_row.mini_mega_menu {
            .dropdown-menu {
                left: inherit;
                right: 0;
                top: $header_height;
            }
        }
    }
    .sectionfixed {
        .header_quicklink_section {
            .open_row.mini_mega_menu {
                > .dropdown-menu {
                    top: 64px;
                }
            }
        }
    }
    .sub_global_header {
        .header_quicklink_section {
            .open_row.mini_mega_menu {
                .dropdown-menu {
                    top: $sub_header_top;
                }
            }
        }
    }
    .sub_global_header {
        .dropdown-menu {
            top: $sub_header_top;
        }
    }

    .top_main_navigation {
        .quicklink_section_mv {
            display: none;
        }
    }
    .link_overview {
        display: none;
    }
    .top_main_navigation {
        .dropdown-menu .dropmenu_section_wrapper {
            > .col-lg-6 {
                ul {
                    @include content-columns(2);
                }
            }
        }
    }
    .top_main_navigation {
        .dropdown-menu .dropmenu_section_wrapper {
            > .col-lg-9 {
                ul {
                    @include content-columns(3);
                }
            }
        }
    }
    .top_main_navigation {
        .dropdown-menu .dropmenu_section_wrapper {
            > .col-lg-12 {
                ul {
                    @include content-columns(4);
                }
            }
        }
    }

    .sub_global_header {
        .header_global_search_form {
            top: $header_height;
        }
    }
    .head_inner_second_line {
        z-index: 111;
        background: $blue;
    }
    .yellow_heading_section_mv {
        display: none;
    }
}

@include media-breakpoint-between(xs, md) {

    .navigation-global {
        padding-top: $header_height;
    }
    .flinders_logo_white {
        display: inline-block;
        margin-top: -1px;
    }

    .global_header {
        top: 0 !important;
        .logo_line {
            height: $header_height;
        }
    }
    .header_search_top {
        .material-icons {
            line-height: $header_height;
        }
    }
    .logo_line,
    .sub_global_header {
        background: $blue !important;
    }

    .header_quicklink {
        i {
            line-height: $header_height;
        }
        span {
            display: none;
        }
    }
    .header_burger {
        .material-icons {
            display: none;
        }
    }
    .header_quicklink_section {
        margin: 0;
    }
    .header_burger {
        margin-left: 2%;
        &[aria-expanded = "true"] {
            span {
                display:none;
                &:first-child  + span{
                    display:none;
                }
            }
            i {
                width: 20px;
                display: block;
                color: $yellow;
            }
        }
    }
    .link_mobile_version {
        line-height: inherit;
        display: block;
        text-align: left;
        position: relative;
        color: $white;
        font-weight: $semibold;
        padding: 1rem 0.5rem;
    }
    .top_main_navigation_wrap {
        position: fixed;
        overflow-y: auto;
        left: 0;
        padding-top: $header_height;
        padding-bottom: 0;
        top: 0;
        background: $blue;
        width: 100%;
        height: 100%;
        z-index: -1;
        .top_main_navigation {
            display: block;
            z-index:10;
            .nav-item {
                border-bottom: 1px solid $gray;
                overflow: hidden;
                .nav_top_link {
                    @extend .link_mobile_version;
                }
            }
        }
    }
    .head_inner_second_line {
        border: 1px solid yellow;
        .top_main_navigation_wrap {
            height: calc(100% - 73px);
            margin-top: $header_height;
            padding-top: 0;
            padding-bottom: 73px;
            overflow-y: auto;

            .top_main_navigation {
                height: calc(100%);
                overflow-y: auto;
                display: block;
                padding-top:0;
            }
        }
    }
    .header_search_top.active {
        position: relative;
        &:after {
            width: 100%;
            left: 0;
        }
    }
    .yellow_header {
        .flinders_logo_black {
            display: none;
        }
        .flinders_logo_white {
            display: inline-block;
        }
    }
    .head_inner_horisontal {
        .open {
            .header_quicklink {
                &:after {
                    display: none;
                }

            }
        }
    }
    .quicklink_section_mv {
        a {
            &:focus {
                color: $white;
                outline: none;
            }
        }
    }
    .head_inner_horisontal {
        .open.quicklink_section_mv {
            > a {
                position: relative;
                &:after {
                    display: block;
                    position: absolute;
                    left: 0;
                    background: $yellow;
                    height: 6px;
                    width: 100%;
                    content: '';
                    z-index:111;
                    bottom: 0;
                }
                &:focus {
                    color: $white;
                    outline: none;
                }
                i {
                    color: $yellow;
                }

            }
            .dropdown-menu {
                /*@extend .top_main_navigation_wrap;*/

                position: fixed;
                overflow-y: auto;
                left: 0;
                padding-top: $header_height;
                padding-bottom: 0;
                top: 0;
                background: $blue;
                width: 100%;
                height: 100%;
                z-index: -1;
                height: auto;
                margin-top: 0;
                border: none;
                .dropmenu_section {
                    padding: 0;
                    ul {
                        li {
                            background: inherit;
                            a {
                                @extend .links_mobile_view;
                            }
                        }
                    }
                }
            }
        }
    }
    .open {
        .dropmenu_section_wrapper  {
            .dropdown-menu {
                background: none;
                border: none;
            }
        }
    }
    .navbar-toggleable-md {
        .navbar-nav {
            margin: 0;
            margin-bottom: 0;
        }
    }
    .top_main_navigation_wrap {
        .top_main_navigation {
            .nav-item.open {
                .nav_top_link {
                    color: $yellow;
                }
            }
        }
    }
    .top_main_navigation {
        li {
            .dropdown-menu {
                background: $white;
            }
        }
    }
    .head_inner_second_line {
        position: absolute;;
        width: 100%;
        z-index: 111;
        top: -78px;
    }
    .head_inner_second_line {
        .header_logo_place {
            display: block;
        }
        .top_main_navigation {
            > li {
                .material-icons {
                    display: none;
                }
            }
        }
    }
    .header_search_top,
    .header_burger {
        padding-left: 0;
        margin-left: 0;
    }
    .header_burger {
        padding-left: 0.3rem;
    }
    .plus_icon {
        position: absolute;
        content: "";
        color: $white;
        top: 20px;
        right: 20px;
        z-index: -1;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .minus_icon {
        content: "";
    }

    .top_main_navigation {
        .nav_top_link[aria-expanded = "false"] {
            &:after {
                @extend .plus_icon;
                color: $yellow;
            }
        }
    }
    .top_main_navigation {
        .nav_top_link[aria-expanded = "true"] {
            &:after {

                position: absolute;
                content: "";
                color: $yellow;
                top: 20px;
                right: 20px;
                z-index: -1;
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
    /*.open {
        .nav_top_link {
            &:after {
                @extend .minus_icon;
                color: $yellow;
            }
        }
    }
    .open {
        .nav_top_link {
            &:after {
                @extend .minus_icon;
                color: $yellow;
            }
        }
    }*/
    .black_toplevel {

        .sub_global_navigation.d-inline-block {
            display: none !important;
        }
    }
    .top_main_navigation {
        .dropdown-menu {
            position: relative;
            width: 100%;
            background: none;
        }
    }
    .top_main_navigation {
        .dropdown-menu {
            padding: 0;
            margin: 0;
            .dropmenu_section_wrapper {
                & > [class^="col-"],
                & > [class*="col-"] {
                    min-height: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .dropdown_buttons_line {

        margin-right: 0;
        > div.row:not(.bookmark_row) {
            padding: 0;
            background: none;
        }
    }
    .links_mobile_view {
        border: none;
        border-bottom: 1px solid $gray-light-flinders-display5;
        font: normal $font-size-sm $font_family;
        line-height: $global-line-height;
        color: $primary_color;
        display: block;
        background: none;
        padding: 1rem 0.5rem;
    }
    .dropdown_buttons_line {
        .black_transparent_button:not(.bookmark_row) {
            @extend .links_mobile_view;
            &:before {
                display: none;
            }
            &:hover {
                color: $primary_color;
                &:after {
                    transform: none;
                    transition: none;
                }
            }
        }
    }
    .top_main_navigation {
        .header_quicklink {
            i {
                display: none;
            }
        }
    }
    .dropdown-menu {
        .dropmenu_section {

            .head_list_heading.open {
                a {
                    color: $black;
                    &:after {
                        color: $black;
                    }
                }
            }
        }
    }
    .dropmenu_section {
        overflow: hidden;
        background: $white;
        .head_list_heading.open {
            a {
                color: $black;
            }
        }
        .dropdown_menu__list {
            margin-top: 0;
            margin-bottom: 0;
            li {
                padding-left: 0;
                margin-bottom: 0;
                a,
                span {
                    @extend .links_mobile_view;
                    padding-left: 1.3rem;


                }

            }
        }
    }
    .header_list_link_mv {
        font-size: $font-size-base;
        padding: 0.8rem 1.5rem;
        display: block;
        &:hover {
            text-decoration: none;
        }
    }
    .header_list_mv {
        background: $white;
        border-bottom: 1px solid $gray-light-flinders-display5;
        margin-bottom: 0;
        a {
            color: $primary_color;
        }
    }
    .dropmenu_section {
        .link_overview, .head_list_heading {
            @extend .header_list_mv;
            display: block;
            a,span {
                position: relative;
                @extend .links_mobile_view;
                &:hover {
                    text-decoration: none;
                }
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
    .head_list_heading {
        a, span {
            cursor: pointer;
            &:after {
                @extend .plus_icon;
                z-index: 1;
                color: $blue;
                top: 15px;
            }
        }
    }
    .dropdown-menu {
        .head_list_heading.open {
            a {
                color: $yellow;
                &:after {
                    color: $yellow;
                    @extend .minus_icon;
                }
            }
        }
    }
    .head_list_heading.open + ul.dropdown_menu__list {
        border: none;
    }

    .dropdown-menu {
        @include border-radius(0);
    }
    .top_main_navigation {
        .open.dropdown {
            > a {
                color: $yellow;
            }
        }
    }
    .header_global_search_form {
        top: $header_height;
    }

    .header_global_search_insert {
        .material-icons {
            left: 10px;
        }
    }
    .subpage_global_header  {
        .global_header {
            z-index: $z-index-mobile-fixed-header-nav;
            top: 0 !important;
        }
    }
    .yellow_heading_section_mv {
        position: fixed;
        left: 0;
        top: $header_height;
        width: 100%;
        text-align: center;
        line-height: 40px;
        background: $yellow;
        height: $yellow_heading_section_mv_height;
        border-top: 1px solid $black;
        font-weight: $dt-font-weight;
        z-index:11;
    }
    .head_inner_second_line {
        .bottom_band_menu_mv {
            display: none;
            position: fixed;
            bottom: 0;
            left: 0;
            background: $blue;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
            text-align: center;
            height: $bottom_yellow_line_height;
            z-index:11;
            opacity:1;
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    display: inline-block;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    .nav_top_link {
                        display: block;
                        font-size: $font-size-sm;
                        color: $white;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                            color: $yellow;
                        }
                    }
                }
                .active {
                    a {
                        color: $yellow;
                        font-weight: normal;
                    }
                }
            }
        }
    }
    .head_inner_second_line {
        .head_inner_horisontal {
            .navbar-collapse[aria-expanded="true"]  {
                .bottom_band_menu_mv {
                    display: block;
                }
            }
        }
    }
}

.hiddenOverflow{
    overflow: hidden;
    z-index: $z-index-mobile-menu; // changed to fix the menu collapse in FF on mobile view.
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* for links to other sites above the main navigation */

.header_sitelink_section {
    float: left;
    list-style: none;
    margin-bottom: 0px;
    /*margin-left:15px;*/
    padding: 0px 15px 0px 15px ;
    line-height: $header_site_height;
}
.header_sitelink {
    font-size: 0.8125rem;
    font-weight: $display-weight-normal;
    display: inline-block;
    color: $white;
    text-decoration: none;
    line-height: $header_site_height;
    &:visited {
        color: $white;
        text-decoration: none;
    }
    &:hover {
        color: $white;
        text-decoration: none;
    }
}
a.header_sitelink span {
    vertical-align: top;
    text-decoration: none;
}

.header_site {
    height: $header_site_height;
    width: 100%;
    position: absolute;
    z-index: $z-index-new-header-site; /* Because its not showing the header on search page, page with slider. */
}

@include media-breakpoint-up(md) {
    ul.ulSiteLink {
        display: none !important;
    }
}

@include media-breakpoint-down(md){
    ul.ulSiteLink {
        display: block !important;
    }
}

ul.ulSiteLink li {
    &:focus, &.active{
        line-height: 0px;
        .tick{
            display:block;
            float:right;
            position:relative;
            height: 38px;
            width: 60px;
            top: -23px;
            margin-bottom: -18px;
            fill:$black;
            @include media-breakpoint-up(md){
                left: 4px;
            }
        }
        background-color: $dusty-yellow;
        a{
            color:$black !important;
            padding-top: 25px !important;
            padding-bottom: 5px !important;
        }
    }
}

.tick {
    display: none;
}

.sectionfixed {
    .sub_site_header {
        .nav_top_link {
            color: $white;
        }
       .header_quicklink {
            color: $white;
            i {
                color: $white;
                line-height: 70px;
            }
        }
        .header_search_top {
            .material-icons {
                color: $white;
                line-height: 70px;
            }
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%
}

//Applied if the main_header_container has a sticky class applied (used for manually built headers using the reference component).
.main_header_container:has(.section_sticky) {
  position:sticky;
  top:0px;
  z-index:999;
}
