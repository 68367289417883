//This mixin can be "@include dynamic-even-columns()" to evenly distribute columns in a container. 
//Explanation of this css can be found here: https://codepen.io/BaronJames/pen/XEJYVW

//default is 12 columns but accepts an argument of any positive int
@mixin dynamic-even-columns($max-columns: 12) {
	@for $i from 1 through $max-columns {
		@if $i == 1 {
			&:first-child:nth-last-child(#{$i}) {
				width: 100%;
			}
		}
		@else {
			&:first-child:nth-last-child(#{$i}),
			&:first-child:nth-last-child(#{$i}) ~ & {
				width: 100%/$i;
			}
		}
	}
}