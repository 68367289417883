footer {
    font-weight: $display-weight-normal;
}

.footer_main {
    background: $yellow;
    .row {
        & > [class^="col-"],
        & > [class*="col-"] {
            padding-top: 50px;
            padding-bottom: 40px;
        }
    }
    h3 {
        margin-top: 1.8rem;
        &:first-child {
            height: 49px;
            margin: 0 0 1.4rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            overflow: hidden;
        }
        &:not(:first-child) {
            margin-top: 0;
            font-size: 1.1rem;
        }
    }
}

.footer_nav {
    padding-bottom: 30px;
    padding-top: 10px;
    li {
        margin-bottom: 2px;
    }
    a {
        color: $black;
        text-decoration: none;
        font-size: $font-size-sm;
        &:hover {
            text-decoration: underline;
        }
        &:focus {
            color: $link_default;
            text-decoration: underline;
        }
    }
}

.footer_contacts {
    .footer_logo_wrap {
        margin-bottom: 2.2rem;
    }
    p {
        margin-bottom: 30px;
        font-size: 0.875rem;
    }
}

.footer_socials {
    a {
        margin-right: 5%;
        text-decoration: none;
        &:last-child {
            margin-right: 0;
        }
    }
}

.footer_copyright {
    background: $blue;
}

.footer_support_logos {
    padding-top: 40px;
    padding-bottom: 25px;
    text-align: left;
    & > * {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5%;
        &:last-child {
            margin-right: 0;
        }
    }
}

.footer_copyright_line {
    border-top: 1px solid $yellow;
    color: $white;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: $font-size-sm;
    color: $white;
    a {
        color: $white;
    }

}

.footer_copyright_line {
    a {
        text-decoration: none;
        &:hover {
            color: $white;
            text-decoration: underline;
        }
        &:focus {
            color: $link_default;
            text-decoration: underline;
        }
    }
}

.footer_slogan {
    padding-top: 30px;
}




@include media-breakpoint-between(sm, md) {
    
    .footer_socials {
        img {
            width: 20%;
        }
        a {
            margin-right: 3%;
        }
    }
    .footer_socials {
        .footer_social__facebook {
            img {
                width: 10%;
            }
        }
    }
    
}


@include media-breakpoint-between(xs, sm) {
    
    .footer_support_logos {
        & > * {
           margin-right: 3%;
           img {
                width: auto;
            }
        }
        
    }
    .footer_slogan {
        img {
            max-width: 100%;
        }
    }
    .footer_socials a {
        margin-left: 0;
        margin-right: 2%;
    }
}

@include media-breakpoint-between(xs, md) {
    .footer_slogan {
        padding-top: 0;
        padding-bottom: 30px;
        text-align: left;
    }
    .footer_logo_wrap {
        img {
            max-width: 100%;
        }
    }
    .footer_support_logos {
        & > * {
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .footer_main {
        .row {
            & > [class^="col-"],
            & > [class*="col-"] {
                padding-top: 10px;
                padding-bottom: 0;
            }
        }
    }
    .footer_contacts {
        p {
            margin-bottom: 20px;
        }
    }
    .footer_main {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

@include media-breakpoint-down(xs) {
    .footer_contacts {
        .footer_logo_wrap {
            margin-bottom: 20px;
        }
    }
    .footer_support_logos  {
        a {
            float: left;
            margin: 0 -6% 0 0;
            width: 36%;
            img {
                width: 70%;
            }
            & + a {
               margin: 0 2% 0 0;
                width: 23%;
                img {
                    width: 90%;
                }
            }
            &:last-child() {
                margin: 0;
                width: 40%;
                img {
                    width: 100%;
                }
            }
        }
        
    } 
    .footer_socials a {
        margin-left: 0;
        margin-right: 3%;
    }
    
}

.footer-last-updated {
    color: #dad9d9;
    font-size: 0.7em;
       
}