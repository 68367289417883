.asset-search-result-tags {
  min-height: 20px;
  text-transform: none;
  font-size: 0.8em;
}

.asset-search-result-col {
  background-color: lightgrey ;
  margin-bottom: 0.3em;
  margin-right: 0.3em;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.asset-search-result-detail {
  padding: 0.1em;
}

.ui.primary.button {
  background-color: $flinders-gold !important;
  color: #000000 !important;
}

.ui.styled.accordion .active.title .dropdown.fa {
  color: $flinders-gold !important;
}

.ui.icon.button.plain {
  &:hover {
    color: $flinders-gold !important;
  }
  height: 20px;
  text-transform: none;
}

.ui.input input {
  &:focus {
    border: 1px solid $flinders-gold !important;
  }
}

/* For asset search result */
.cmp-search-results {

  .content a {
    color: $black;
  }

  td a {
    color: $black;
  }

  .ui.table tbody tr td.image:not(.ui) img {
    width: 7em;
    max-height: 7em;
    object-fit: cover;
    object-position: center;
    border-radius: .28571429rem;
  }

  .ui.table td.header a {
    text-decoration: none;
  }

  .ui.card h3.header:not(.ui) a {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    float: left;
    padding-bottom: 0.25em;
    overflow-wrap: break-word;
    line-height: 1em;
  }

  .ui.form {
    position: relative;
    max-width: 100%;
  }

  .ui.grid {
    margin: -1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    padding: 0px;
  }

  .ui[class*="one column"].grid > .row > .column, .ui[class*="one column"].grid > .column:not(.row) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ui.cards {
    display: flex;
    margin: -0.875em -0.5em;
    flex-wrap: wrap;
  }

  .ui.cards > .card {
    float: none;
    display: flex;
    margin: 0.875em auto;
  }

  .ui.cards > .card, .ui.card {
    width: 255px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    min-height: 0px;
    max-width: 100%;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    padding: 0px;
    border: 0.75em solid rgb(249, 249, 249);
    border-radius: 0.285714rem;
    transition: box-shadow 0.1s ease 0s, transform 0.1s ease 0s;
  }

  .ui.cards > .card > .content, .ui.card > .content {
    padding: 1rem;
    flex-grow: 1;
  }

  .ui.cards > .card > .image > img, .ui.card > .image > img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: inherit;
  }

  .ui.card .image img {
    object-fit: cover;
    object-position: center center;
    max-height: 15em;
  }

  .ui.card h3.header:not(.ui) {
    margin: 1em 0px 2em !important;
    margin-bottom: 2em;
    font-weight: normal !important;
    font-size: 1em;
    float: left;
    width: 100%;
  }

  .ui.fluid.buttons, .ui.fluid.button {
    width: 100%;
  }

}

/* For asset layout toggle */

.cmp-search-layout-toggle {
  margin-top: 15px;
  margin-bottom: 15px;

  .ui.icon.button.plain {
    background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
    text-transform: none;
  }

  .ui.icon.buttons .button, .ui.icon.button {
    padding: 0.833em;
  }

  i.big.fa {
    font-size: 2em;
    line-height: 2px;
    vertical-align: top;
  }
}

/* For asset filter toggle */

.cmp-search-filter-toggle {
  margin-top: 15px;
  margin-bottom: 15px;

  .ui.grid {
    margin: -1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    padding: 0px;
  }

  .ui.grid > .row > [class*="eight wide"].column, .ui.grid > .column.row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column, .ui.column.grid > [class*="eight wide"].column {
    width: 50%;
  }

  .ui.grid > .column:not(.row) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .ui.grid > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ui.fluid.buttons, .ui.fluid.button {
    width: 100%;
  }

  .ui.labeled.icon.buttons .button, .ui.labeled.icon.button {
    padding-right: 1.5em !important;
    padding-left: 3.5em !important;
    position: relative;
  }

  .ui.labeled.icon.buttons > .button > .fa, .ui.labeled.icon.button > .fa {
    position: absolute;
    height: 100%;
    line-height: 1;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    text-align: center;
    margin: 0px;
    width: 2.666em;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: transparent -1px 0px 0px 0px inset;
  }

  .ui.labeled.icon.buttons > .button > .fa, .ui.labeled.icon.button > .fa {
    left: 0px;
    top: 0px;
  }

  .ui.labeled.icon.buttons > .button > .fa::before, .ui.labeled.icon.button > .fa::before, .ui.labeled.icon.buttons > .button > .fa::after, .ui.labeled.icon.button > .fa::after {
    display: block;
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    top: 50%;
  }
}

/* For search bar */

.cmp-search-search-bar {
  margin-top: 15px;
  margin-bottom: 15px;

  .ui.fluid.input {
    display: flex;
  }

  .ui.input {
    position: relative;
    font-weight: normal;
    font-style: normal;
    display: inline-flex;
    color: rgb(80, 80, 80);
  }

  .ui[class*="left icon"].input > input {
    padding-left: 2.67143em !important;
    padding-right: 1em !important;
  }

  .ui.icon.input > i.fa {
    cursor: default;
    position: absolute;
    line-height: 1;
    text-align: center;
    top: 15px;
    right: 0px;
    margin: 0px;
    height: 100%;
    width: 2.67143em;
    opacity: 0.5;
    border-radius: 0px 0.285714rem 0.285714rem 0px;
    transition: opacity 0.3s ease 0s;
  }

  .ui.input input {
    outline: currentcolor none 0px;
    text-align: left;
    line-height: 1.21429em;
    font-family: inherit;
    padding: 0.678571em 1em;
    padding-right: 1em;
    padding-left: 1em;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    margin: 0px;
    max-width: 100%;
    flex: 1 0 auto;
    box-shadow: none;
    transition: box-shadow 0.1s ease 0s, border-color 0.1s ease 0s;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-right-color: rgba(34, 36, 38, 0.15);
    color: rgb(80, 80, 80);
    //border-radius: 0.285714rem;
  }

  .ui[class*="left icon"].input > i.fa {
    right: auto;
    left: 1px;
    border-radius: 0.285714rem 0px 0px 0.285714rem;
  }

  .ui.action.input > .button, .ui.action.input > .buttons > .button {
    margin: 0px;
    padding-top: 0.785714em;
    padding-bottom: 0.785714em;
  }
}
/* For asset statistics */

.cmp-search-statistics {
  margin-top: 15px;
  margin-bottom: 15px;


  .ui.statistics {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .ui.three.statistics .statistic {
    min-width: 33.3333%;
    margin: 0px 0px 2em;
  }

  .ui.statistics > .statistic {
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: column;
    margin: 0px 1.5em 2em;
  }

  .ui.mini.statistics .statistic > .value, .ui.mini.statistic > .value {
    font-size: 1.5rem !important;
  }

  .ui.statistics .statistic > .value, .ui.statistic > .value {
    font-family: inherit;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1em;
    text-transform: uppercase;
    text-align: center;
  }

  .ui.statistics .statistic > .label, .ui.statistic > .label {
    font-family: inherit;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
}
/* for asset sort */

.cmp-search-sort {
  margin-top: 15px;
  margin-bottom: 15px;


  .ui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: currentcolor none 0px;
    text-align: left;
    transition: box-shadow 0.1s ease 0s, width 0.1s ease 0s;
  }

  .ui.dropdown .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: currentcolor none 0px;
    top: 100%;
    margin: 0px;
    padding: 0px;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    font-size: 1em;
    text-shadow: none;
    text-align: left;
    box-shadow: rgba(34, 36, 38, 0.15) 0px 2px 3px 0px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.285714rem;
    transition: opacity 0.1s ease 0s;
    z-index: 11;
    will-change: transform, opacity;
  }

  .transition {
    animation-iteration-count: 1;
    animation-duration: 300ms;
    animation-timing-function: ease;
    animation-fill-mode: both;
  }

  .ui.dropdown .menu > * {
    white-space: nowrap;
  }

  .ui.dropdown .menu > .item {
    position: relative;
    cursor: pointer;
    display: block;
    border: 0px none;
    border-top-width: 0px;
    height: auto;
    text-align: left;
    line-height: 1em;
    color: rgb(80, 80, 80);
    padding: 0.785714rem 1.14286rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: normal;
    box-shadow: none;
  }

  .hidden.transition {
    display: none;
    visibility: hidden;
  }

  .visible.transition {
    display: block !important;
    visibility: visible !important;
  }

  .ui.dropdown > .text {
    display: inline-block;
    transition: none 0s ease 0s;
  }

  .ui.dropdown > .dropdown.icon {
    position: relative;
    width: auto;
    font-size: 0.857143em;
    margin: 0px 0px 0px 1em;
  }

  .ui.dropdown .menu {
    left: 0px;
  }

  .ui.dropdown .menu > .item:first-child {
    border-top-width: 0px;
  }

  .ui.dropdown .menu .active.item {
    background: transparent none repeat scroll 0% 0%;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.95);
    box-shadow: none;
    z-index: 12;
  }

  .ui.dropdown.selected, .ui.dropdown .menu .selected.item {
    background: rgba(0, 0, 0, 0.03) none repeat scroll 0% 0%;
    color: rgba(0, 0, 0, 0.95);
  }

  .ui.dropdown .menu > .item:hover {
    background: $flinders-gold none repeat scroll 0% 0%;
    color: $black;
    z-index: 13;
  }
}
/* For asset tags */

.cmp-search-tags {
  margin-top: 15px;
  margin-bottom: 15px;


  .ui.styled.accordion .title.right .dropdown.fa,
  .ui.styled.accordion .accordion .title.right .dropdown.fa {
    float: right;
  }

  .ui.accordion .title ~ .content, .ui.accordion .accordion .title ~ .content {
    display: none;
  }

  .ui.accordion .active.content, .ui.accordion .accordion .active.content {
    display: block;
  }

  .ui.accordion .title, .ui.accordion .accordion .title {
    cursor: pointer;
  }

  .ui.styled.accordion .title, .ui.styled.accordion .accordion .title {
    margin: 0px 1em;
    padding: 0.75em 0px 0.5em;
    font-weight: normal;
    transition: background 0.1s ease 0s, color 0.1s ease 0s;
  }

  .ui.styled.accordion .content, .ui.styled.accordion .accordion .content {
    margin: 0px;
    padding: 0.5em 1em 1.5em;
  }

  .ui.styled.accordion .active.title {
    background: transparent none repeat scroll 0% 0%;
  }

  .ui.accordion .active.title .dropdown.fa, .ui.accordion .accordion .active.title .dropdown.fa {
    transform: rotate(90deg);
  }

  .ui.checkbox {
    display: inline-flex;
    align-items: baseline;
  }

  .ui.checkbox.checkbox input {
    flex: 2 0 auto;
  }

  .ui label {
    padding-left: 15px;
    line-height: 1em;
  }


}

/*--------------
     Slide
---------------*/

.transition.slide.in,
.transition[class*="slide down"].in {
  animation-name: slideInY;
  transform-origin: top center;
}
.transition[class*="slide up"].in {
  animation-name: slideInY;
  transform-origin: bottom center;
}
.transition[class*="slide left"].in {
  animation-name: slideInX;
  transform-origin: center right;
}
.transition[class*="slide right"].in {
  animation-name: slideInX;
  transform-origin: center left;
}

.transition.slide.out,
.transition[class*="slide down"].out {
  animation-name: slideOutY;
  transform-origin: top center;
}
.transition[class*="slide up"].out {
  animation-name: slideOutY;
  transform-origin: bottom center;
}
.transition[class*="slide left"].out {
  animation-name: slideOutX;
  transform-origin: center right;
}
.transition[class*="slide right"].out {
  animation-name: slideOutX;
  transform-origin: center left;
}

.cmp-search-layout-toggle, .cmp-search-tags, .cmp-search-sort, .cmp-search-search-bar, .cmp-search-filter-toggle, .cmp-search-results {
  .ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: currentcolor none 0px;
    border: 0px none;
    vertical-align: baseline;
    background: rgb(224, 225, 226) none repeat scroll 0% 0%;
    color: rgba(0, 0, 0, 0.6);
    font-family: inherit;
    margin: 0px 0.25em 0px 0px;
    padding: 0.833em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-weight: bold;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.285714rem;
    box-shadow: transparent 0px 0px 0px 1px inset,
    rgba(34, 36, 38, 0.15) 0px 0px 0px 0px inset;
    transition: opacity 0.1s ease 0s,
    background-color 0.1s ease 0s,
    color 0.1s ease 0s,
    box-shadow 0.1s ease 0s, background 0.1s ease 0s;
  }
}

.meta .property {
  font-size: .825em;
  font-weight: normal;
  color: $black;
}

