// CSS for conversation feed.

.conversation_component {
  padding-top: 0px;
  padding-bottom: 0px;


  .conversation_component_section_wrap {
    padding: 0px 15px 0px 15px;
  }

  .conversation_component_section_wrap .component_section_item {
    padding: 15px 0px 15px 0px;
    width: 100%;
    border-top: 1px solid #777;
    color: #777;

  }

  .conversation_data .conversation_link {
    margin-bottom: 10px;
    text-decoration: none;
    color: #777;
  }

  .conversation_date {
    font-weight: bold;
    color: #777;
  }

  .conversation_text_title {
    font-family: Roboto, sans-serif;
    font-size: 1.375rem;
    font-weight: 700;
    color: $blue;
  }

  @include media-breakpoint-down(xs) {
    .conversation_title .conversation_text_title {
      line-height: 2.5rem;
    }
  }

  .conversation_title {
    display: flex;
    vertical-align: middle;
  }

  .ajax-loader {
    visibility: hidden;
    position: absolute;
    width: 100%;
  }

  .conversation_component_read_more {
    padding-left: 15px;
    padding-right: 15px;
  }

  .conversation_button {
    @extend .classic_white_button_navy_border
  }

}

// font color depending on section color

.dark_blue_container, .blue_container, .black_container, .gray_darker_container, .gray_dark_container {

  .conversation_component {

    .conversation_button {
      @extend .classic_transparent_button_white_border
    }

    .conversation_component_section_wrap .component_section_item {
      border-top: 1px solid #fff;
    }

    .conversation_link, .conversation_date, .conversation_text_title {
      color: #fff;
    }
  }
}

.white_container, .gray_light_container, .domestic_grey_container, .international_grey_container, .white_color_container, .gold_container {

  .conversation_component {

    .conversation_button {
      @extend .classic_white_button_navy_border
    }

    .conversation_link, .conversation_date, .conversation_text_title {
      color: #000;
    }

    .conversation_component_section_wrap .component_section_item {
      border-top: 1px solid #000;
    }
  }
}
