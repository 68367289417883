
.courseBookmarkName {
  padding-top: 10px;
  padding-bottom: 10px;
  a {
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
}

@include media-breakpoint-down(md){
  	#enquireIcon {
    	padding-left: 0px;
  	}
  	#course-bookmarks{
    	.head_list_heading>a{
     	 &:after{
     	 content:"";
     	 }
    }
    .dropdown_buttons_line {
      margin-right: -10px;
      > div.row {
        padding: 1rem 0;
        background-color: $yellow;
      }
    }
    .dropdown_buttons_line {
      .black_transparent_button {
        background: none;
        border: 2px solid $black;
        color: $black !important;
        display: inline-block;
        &:hover {
          border: 2px solid $blue;
          color: $yellow !important;
          text-decoration: none;
          &:after {
            transform: translateY(-100%);
            transition: transform .3s;
          }
          &:before {
            color: $yellow !important;
          }
        }
        &:after {
          background: $blue !important;
        }
        &:before {
          color: $black !important;
        }
        &--no-icon {
          font-style: normal;
          padding-left: 16px;
          padding-right: 16px;
          text-align: center;
          text-transform: uppercase;
          &:before { display: none; }
        }
      }
    }
  }
  .saveCourseContent{
  	background-color: $white;
    line-height:1.5rem;
  }
  .scRowStyle{
    border:none;
  }
  .info_li{
    padding:0px;
  }
  svg.save-course{
    position:relative;
    top:-3px;
    left:15px;
    width:50px;
  }
}

@include media-breakpoint-up(lg) {
  #savecourseDropDown{
    border:none;
  }
  .saveCourseContent{
    line-height:2.25rem;
  }
  .scRowStyle{
    border:none;
  }
  svg.save-course{
    height:70px;
    position:relative;
    top:-2px;
  }
  .head_inner_second_line {
  	svg.save-course {
  		height:64px;
  	}
  }
}

// Start -- CSS for saved courses
.courseBookmarkTarget { 
	.courseBookmarkMenu {
		//Limit list to display only first 7 items.
		&:nth-of-type(1n+8) {
			display: none;
		}
	}
}

.deleteCourseBookmark {
	position: relative;
	overflow: hidden;
	z-index: 1;
	cursor: pointer;
	text-align: center;
	transition: color .3s;
	transition-timing-function: linear;
	&::after {
		border: none;
		background-color:#D9534F;
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: -2;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s,-webkit-transform .3s;
		transition-timing-function: ease-in;
	}
	&.confirm_delete {
		color: white;
		&:after {
			-webkit-transform: translateX(-100%);
			-ms-transform: translateX(-100%);
			transform: translateX(-100%);
			transition: -webkit-transform .3s;
			transition: transform .3s;
			transition: transform .3s,-webkit-transform .3s;
			transition-timing-function: ease-in;
		}
	}
}
	
.vertical-align-middle { 
    vertical-align: middle; 
}

.scRowStyle:nth-of-type(n) + .scRowStyle:nth-of-type(even){
  background: #f2f2f2;
}

.savecoursePopup {
	@include media-breakpoint-down(md) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.8);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	background: rgba(0,0,0,0);
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

.head_inner_second_line {
	.savecoursePopup {
		margin-top: -6px !important;
	}
}

.dropmenu_section_wrapper {
	@include media-breakpoint-down(md) {
		width: 100%;
		text-align: left;
		background-color: #fff;
		z-index: 1000;
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		width: 1140px;
		max-width: 100%;
		background-color: #fff;
		top: 100%;
		left: 0;
		text-align: left;
	}
}

a.header_quicklink {
	vertical-align: middle;
	span {
		vertical-align: middle;
	}
}
	
// End -- CSS for saved courses
